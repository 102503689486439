var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("basic-container", [
        _c("div", { staticStyle: { "padding-left": "2rem" } }, [
          _c("h1", [_vm._v("站点配置")]),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "1.5rem",
                "margin-bottom": "1.5rem",
              },
            },
            [_vm._v("Sass企业名称：" + _vm._s(this.$route.query.name))]
          ),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              height: "100vh",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-around",
              "margin-bottom": "15rem",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "100%",
                  "justify-content": "space-around",
                },
              },
              [
                _c("sassoption", {
                  attrs: {
                    title: "独立商户站（必选）",
                    id: _vm.id,
                    type: "1",
                    orgCode: _vm.orgCode,
                    dataform: _vm.type1,
                  },
                }),
                _c("sassoption", {
                  attrs: {
                    title: "独立运营站（必选）",
                    id: _vm.id,
                    type: "2",
                    orgCode: _vm.orgCode,
                    dataform: _vm.type2,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "100%",
                  "justify-content": "space-around",
                  "margin-top": "2rem",
                  "margin-bottom": "10rem",
                },
              },
              [
                _c("sassoption", {
                  attrs: {
                    title: "独立渠道站（必选）",
                    id: _vm.id,
                    type: "3",
                    orgCode: _vm.orgCode,
                    dataform: _vm.type3,
                  },
                }),
                _c("sassoption", {
                  attrs: {
                    title: "独立资方管理站（必选）",
                    id: _vm.id,
                    type: "4",
                    orgCode: _vm.orgCode,
                    dataform: _vm.type4,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }