import { render, staticRenderFns } from "./sassSetoption.vue?vue&type=template&id=eda2cf78"
import script from "./sassSetoption.vue?vue&type=script&lang=js"
export * from "./sassSetoption.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eda2cf78')) {
      api.createRecord('eda2cf78', component.options)
    } else {
      api.reload('eda2cf78', component.options)
    }
    module.hot.accept("./sassSetoption.vue?vue&type=template&id=eda2cf78", function () {
      api.rerender('eda2cf78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sass_management/sassSetoption.vue"
export default component.exports