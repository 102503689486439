var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "business", staticClass: "section" }, [
    _c("h2", { staticClass: "topall" }, [_vm._v("营业执照登记")]),
    _vm._m(0),
    _c(
      "div",
      {
        staticStyle: {
          width: "70vw",
          display: "flex",
          "justify-content": "space-around",
        },
      },
      [
        _c(
          "div",
          { staticStyle: { width: "40%", height: "100%" } },
          [
            _c("Uploadfile", {
              ref: "Upload",
              attrs: {
                className: "uploadFile",
                typeCode: "QYYYZZ",
                defaultImg: _vm.defaultImg,
                tips: "点击上传营业执照",
                height: "15rem",
                width: "15rem",
              },
              on: { uploadSuccess: _vm.uploadSuccess },
            }),
            _c(
              "p",
              {
                staticStyle: {
                  display: "inline-block",
                  "text-align": "center",
                  "margin-left": "10%",
                },
              },
              [_vm._v("\n        请上传营业执照原件或加盖公章的复印件\n      ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "60%",
              "background-color": "rgb(246, 249, 255)",
              padding: "1rem",
            },
          },
          [
            _c("formshow", {
              ref: "form1",
              attrs: {
                rulesList: _vm.rulesList,
                rules: _vm.rules,
                labelWidth: "140px",
              },
              on: { verifyFinish: _vm.verifyFinish },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "margin-left": "1.5rem", "margin-bottom": "1rem" } },
      [
        _c("div", [_vm._v("请上传营业执照照片")]),
        _c("p", { staticStyle: { color: "gray" } }, [
          _vm._v("仅支持JPG/JPREG/PNG格式，不超过2M"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }