<template>
  <div class="selection-box">
    <section class="acc-container">
      <el-divider class="title-divider" content-position="left">基本信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item fullWidth">
            <div class="item_label">客户名称：</div>
            <div>{{ form.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">联系方式：</div>
            <div>{{ form.mobile }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺数量：</div>
            <div>{{ form.storeCount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业角色：</div>
            <div>{{ cateTypeText[form.cateType] }}</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">产品号:</div>
            <div>1A-极速收款</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属渠道：</div>
            <div v-if=" form.channelId">{{ form.channelName }}</div>
            <div v-else>直客</div>
          </div> -->
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">极速收款</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">开通状态:</div>
            <div>{{ form.openProductTypeA1 ? '已开通' : '未开通' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业已用额度:</div>
            <div>{{ form.usedAmountA1|| '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业授信上限金额:</div>
            <div>{{ form.creditAmountA1|| '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业开通店铺数:</div>
            <div>{{ form.storeCountA1 }}家</div>
          </div>
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">极速付款</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">角色:</div>
            <div>{{ form.zctRoleType ? zctRoleTypeText[form.zctRoleType] : '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">开通状态:</div>
            <div>{{ form.openProductTypeA2 ? '已开通' : '未开通' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业已用额度:</div>
            <div>{{ form.usedAmountA2 || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业授信上限金额:</div>
            <div>{{ form.creditAmountA2 || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业开通店铺数:</div>
            <div>{{ form.storeCountA2 }}家</div>
          </div>
        </div>
      </div>
      <!-- <el-divider v-if="shopList.length" class="title-divider" content-position="left">店铺信息</el-divider>
      <div v-if="shopList.length" class="jg-acc">
        <div v-for="(u,index) in shopList" :key="index" class="card-box">
          <div class="card-item">
            <div class="item_label">店铺名:</div>
            <div>{{ u.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属平台:</div>
            <div v-if="u.livePlatform == 10">抖音</div>
            <div v-if="u.livePlatform == 11">快手</div>
            <div v-if="u.livePlatform == 12">拼多多</div>
            <div v-if="u.livePlatform == 13">腾讯视频号</div>
            <div v-if="u.livePlatform == 14">美团</div>
          </div>
          <div class="card-item">
            <div class="item_label">在贷融资:</div>
            <div>{{ u.inLoanCount }}笔</div>
          </div>
          <div class="card-item">
            <div class="item_label">平均回款周期:</div>
            <div v-if="u.avgTurnoverDays">{{ u.avgTurnoverDays }}天</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">折扣率:</div>
            <div v-if="u.financingRatio">{{ (Number(u.financingRatio) * 100).toFixed(2) }}%</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">剩余应还总额:</div>
            <div>{{ u.residueAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">状态:</div>
            <div v-if="u.overdue" style="color:red">逾期</div>
            <div v-if="!u.overdue" style="color:green">正常</div>
          </div>
          <div class="card-item">
            <el-button type="primary" @click="goShop(u)">查看详情</el-button>
          </div>
        </div>
      </div> -->
      <!-- <el-divider class="title-divider" content-position="left">监管账户信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">账户号:</div>
            <div v-if="accountFrom.accountNo">{{ accountFrom.accountNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属银行:</div>
            <div v-if="accountFrom.bankName">{{ accountFrom.bankName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户支行号:</div>
            <div v-if="accountFrom.bankBranchNo">{{ accountFrom.bankBranchNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户系统:</div>
            <div v-if="accountFrom.paymentChannel == 1">Ping++</div>
            <div v-else-if="accountFrom.paymentChannel == 2">富友支付</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户行地址:</div>
            <div v-if="accountFrom.bankBranchAddress">{{ accountFrom.bankBranchAddress }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户余额:</div>
            <div>{{ accountFrom.totalBalance }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">可用额度:</div>
            <div>{{ accountFrom.availableAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">冻结额度:</div>
            <div>{{ accountFrom.frozenAmount }}</div>
          </div>
        </div>
      </div> -->
      <el-divider class="title-divider" content-position="left">企业营业信息</el-divider>
      <div class="jg-acc">
        <div class="jg-acc-action">
          <div v-if="editInfo.info" style="display: flex;">
            <el-button
              type="primary"
              @click="onSave()"
            >
              保存
            </el-button>
            <el-button
              @click="onCancel()"
            >
              取消
            </el-button>
          </div>
          <i v-else class="el-icon-edit-outline" style="cursor: pointer;" @click="onEdit('info')" />
        </div>
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item fullWidth">
            <div class="item_label">企业名称:</div>
            <div>{{ form.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">社会信用代码:</div>
            <div>{{ form.creditCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">营业执照有效期:</div>
            <div v-if="editInfo.info">
              <el-date-picker
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </div>
            <div v-else>
              {{ form.establishmentDate }}至{{ form.validDate }}
            </div>
          </div>
          <div class="card-item">
            <div class="item_label">企业地址信息:</div>
            <div v-if="editInfo.info">
              <el-input
                v-model="info.address"
                placeholder="请填写企业地址"
                :disabled="editInfo.info"
              />
            </div>
            <div v-else>{{ form.address }}</div>
          </div>
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">企业法人信息</el-divider>
      <div class="jg-acc">
        <div class="jg-acc-action">
          <div v-if="editInfo.legal" style="display: flex;">
            <el-button
              type="primary"
              @click="onSave()"
            >
              保存
            </el-button>
            <el-button
              @click="onCancel()"
            >
              取消
            </el-button>
          </div>
          <i v-else class="el-icon-edit-outline" style="cursor: pointer;" @click="onEdit('legal')" />
        </div>
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">法人姓名:</div>
            <div>{{ form.legalName || '--' }}</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">法人性别:</div>
            <div v-if="form.legalGender == 1">男</div>
            <div v-if="form.legalGender == 2">女</div>
            <div v-if="!form.legalGender ">--</div>
          </div> -->
          <div class="card-item">
            <div class="item_label">法人身份证:</div>
            <div>{{ form.legalCard || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="editInfo.legal">
              <el-radio-group v-model="legalDurationLong">
                <el-radio :label="1">
                  <el-date-picker
                    v-model="legal.legalDuration"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-radio>
                <el-radio :label="0">长期</el-radio>
              </el-radio-group>
            </div>
            <div v-else>{{ form.legalDuration || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人联系邮箱:</div>
            <div v-if="editInfo.legal">
              <el-input v-model="legal.legalMail" placeholder="请输入法人联系邮箱" clearable />
            </div>
            <div v-else>{{ form.legalMail || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人手机号码:</div>
            <div v-if="editInfo.legal">
              <el-input v-model="legal.legalMobile" placeholder="请输入法人手机号码" clearable />
            </div>
            <div v-else>{{ form.legalMobile || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">民族:</div>
            <div v-if="editInfo.legal">
              <el-input v-model="legal.legalEthnic" placeholder="请输入法人民族" clearable />
            </div>
            <div v-else>{{ form.legalEthnic || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="editInfo.legal">
              <el-input v-model="legal.legalAddress" placeholder="请输入法人身份证住址" clearable />
            </div>
            <div v-else>{{ form.legalAddress || '' }}</div>
          </div>
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">授权人信息</el-divider>
      <div class="jg-acc">
        <div class="jg-acc-action">
          <div v-if="editInfo.auth" style="display: flex;">
            <el-button
              type="primary"
              @click="onSave()"
            >
              保存
            </el-button>
            <el-button
              @click="onCancel()"
            >
              取消
            </el-button>
          </div>
          <i v-else class="el-icon-edit-outline" style="cursor: pointer;" @click="onEdit('auth')" />
        </div>
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">授权人姓名:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authName" placeholder="请输入授权人姓名" clearable />
            </div>
            <div v-else>{{ form.authName || '--' }}</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">授权人性别:</div>
            <div v-if="form.authorizationGender == 1">男</div>
            <div v-if="form.authorizationGender == 2">女</div>
            <div v-if="!form.authorizationGender">--</div>
          </div> -->
          <div class="card-item">
            <div class="item_label">授权人身份证:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authCard" placeholder="请输入授权人身份证" clearable />
            </div>
            <div v-else>{{ form.authCard || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="editInfo.auth">
              <el-radio-group v-model="authDurationLong">
                <el-radio :label="1">
                  <el-date-picker
                    v-model="auth.authDuration"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-radio>
                <el-radio :label="0">长期</el-radio>
              </el-radio-group>
            </div>
            <div v-else>{{ form.authDuration || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人联系邮箱:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authMail" placeholder="请输入授权人联系邮箱" clearable />
            </div>
            <div v-else>{{ form.authMail || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人手机号码:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authMobile" placeholder="请输入授权人手机号码" clearable />
            </div>
            <div v-else>{{ form.authMobile || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">民族:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authEthnic" placeholder="请输入授权人民族" clearable />
            </div>
            <div v-else>{{ form.authEthnic || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="editInfo.auth">
              <el-input v-model="auth.authAddress" placeholder="请输入身份证住址" clearable />
            </div>
            <div v-else>{{ form.authAddress || '--' }}</div>
          </div>
        </div>
      </div>
      <!-- <el-divider class="title-divider" content-position="left">文件信息</el-divider>
      <div class="jg-acc">
        <div class="attachList">
          <div
            class="attachList_item"
            v-for="item in fileList"
            :key="item.id"
          >
            <p class="attachList_item_name">{{ item.fileName.split('.')[0] }}</p>
            <div class="attachList_item_img">
              <img
                :src="fileUrl[item.typeCode]"
                style="width: 100%;height: 180px;"
                alt=""
              >
            </div>
          </div>
        </div>
      </div> -->
      <!-- <imgdialog
        :code="enterpriseId"
        :file-arr="fileList"
        @onChange="onInit()"
      /> -->
      <div v-if="fileList.length > 0">
        <el-divider class="title-divider" content-position="left">文件信息</el-divider>
        <div class="flexBox">
          <div v-for="item in fileList" class="flexBox-item">
            <imgItem
              :code="enterpriseId"
              :file="item"
              @onChange="onInit()"
            />
          </div>
        </div>
      </div>
      <!-- <div v-if="esignAttachList.length > 0">
        <el-divider class="title-divider" content-position="left">协议文本</el-divider>
        <div class="jg-acc">
          <div class="attachList">
            <div
              class="attachList_item"
              v-for="item in esignAttachList"
              :key="item.id"
            >
              <p class="attachList_item_name">
                {{ item.filename }}
                <label>{{flowStatusToText[item.flowStatus]}}/{{item.signStart}}</label>
                <span>查看</span>
              </p>
            </div>
          </div>
        </div>
      </div> -->
      <filedialogs
        :code="enterpriseId"
        :file-arr="esignAttachList"
      />
      <div v-if="total > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="pageChange"
        />
      </div>
    </section>
  </div>
</template>
<script>
import imgItem from '@/views/wf/components/imgItem.vue';
import filedialogs from '@/views/operate/components/filedialogs.vue';
import { doImg } from '../../router/axios';
import dayjs from 'dayjs';
export default {
  name: 'ClientDetail',
  components: {
    imgItem,
    filedialogs
  },
  data() {
    return {
      editInfo: {
        info: false,
        legal: false,
        auth: false
      },
      residueAmountType: true,
      isvisible: false,
      page: {
        total: 1, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      form: {},
      list: [{}],
      listLoading: false,
      accountFrom: {},
      shopList: [],
      cateTypeText: {
        0: '普通商户',
        4: '渠道',
        5: '资金方'
      },
      zctRoleTypeText: {
        1: '供应商',
        2: '集采商',
        3: '经销商'
      },
      attachList: [],
      fileList: [],
      esignAttachList: [],
      flowStatusToText: {
        1: '签署中',
        2: '已完成',
        3: '已撤销',
        5: '已过期',
        7: '已拒签',
        9: '已下载'
      },
      fileUrl: {},
      enterpriseId: null,
      info: {
        address: '',
        establishmentDate: '',
        validDate: ''
      },
      legalDurationLong: null,
      legal: {
        id: null,
        legalDuration: '',
        legalDurationStart: '',
        legalDurationEnd: '',
        legalAddress: '',
        legalMail: '',
        legalMobile: '',
        legalEthnic: ''
      },
      authDurationLong: null,
      auth: {
        id: null,
        authName: '',
        authCard: '',
        authDuration: '',
        authDurationStart: '',
        authDurationEnd: '',
        authAddress: '',
        authMail: '',
        authMobile: '',
        authEthnic: ''
      },
      value: '',
      value1: '',
      value2: '',
      pageSize: 10,
      pageNum: 1,
      total: 0
    };
  },
  created() {
    const id = this.$route.query.id;
    console.log(id, 111111);
    this.enterpriseId = id;
    this.queryAttachList();
    this.getDetails();
    this.getFileList(id);
    this.getEsignAttachList(id);
  },
  mounted() {
    if (this.$route.query.id) {
      this.enterpriseId = this.$route.query.id;
      this.queryAttachList();
      this.getDetails();
      this.getFileList(this.$route.query.id);
      this.getEsignAttachList(this.$route.query.id);
    }
  },
  methods: {
    onInit() {
      const id = this.$route.query.id;
      window.location.reload();
    },
    goShop(row) {
      this.$router.push(`/operate/shopDetail?id=${row.enterpriseId}&shopid=${row.id}&storeCode=${row.storeCode}`);
    },
    editResidueAmoun() {
      if (!this.residueAmountType) {
        if (!this.form.creditAmount) return this.$message.error('请输入要修改的额度');
        this.doPost('bium-portal/controller/fin-ops/enterpriseProduct/modifyCreditAmount', {
          enterpriseId: this.form.enterpriseId,
          creditAmount: this.form.creditAmount
        }).then(res => {
          this.$message.success('修改成功');
          this.residueAmountType = true;
        });
      } else {
        this.residueAmountType = false;
        this.$forceUpdate();
      }
    },
    getRatePlanDetail() {
      return;
      // eslint-disable-next-line no-unreachable
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail', {
        storeId: this.form.id,
        accountType: 1
      }).then(res => {
        this.form.customerRate1 = (Number(res.data.result.custRateDetailDTOList[0].customerRate) * 100).toFixed(2);
        this.$forceUpdate();
      });
    },
    getselectAccount() {
      this.doPost('bium-portal/controller/fin-ops/account/selectAccount', {
        companyCode: this.form.enterpriseCode,
        accountType: 1
      }).then(res => {
        // console.log(res.data);
        if (res.data.result) {
          this.accountFrom = res.data.result[0];
        }
      });
    },
    getlistStoreClearByEnterprise() {
      this.doPost('bium-portal/controller/fin-ops/store/listStoreClearByEnterpriseId', {
        enterpriseId: this.form.id,
        pageSize: 100,
        pageNum: 1
      }).then(res => {
        this.shopList = res.data.result.records;
      });
    },
    getFileList(enterpriseId) {
      const self = this;
      self.doPost('/bium-portal/controller/fin-ops/attach/listPhoto', {
        enterpriseId: enterpriseId
      }).then(res => {
        self.fileList = res.data.result;
        // res.data.result.forEach(item=>{
        //   self.$set(self.fileUrl, item.typeCode, '');
        //   self.getFileInfo(item.typeCode);
        // })
      });
    },
    getEsignAttachList() {
      this.doPost('/bium-portal/controller/fin-ops/esign/queryEsignAttachList', {
        enterpriseId: this.enterpriseId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        console.log('esignAttachList', res.data.result.records);
        this.esignAttachList = res.data.result.records;
        this.total = res.data.result.total;
      });
    },
    pageChange(val) {
      this.pageNum = val;
      this.getEsignAttachList();
    },
    queryAttachList() {
      const self = this;
      this.doPost('bium-portal/controller/fin-ops/attach/list', {
        enterpriseId: this.$route.query.id
      }).then(res => {
        const result = res.data.result;
        self.attachList = result;
      });
    },
    getFileInfo(typeCode) {
      const self = this;
      const params = {
        enterpriseId: self.$route.query.id,
        typeCode: typeCode
      };
      doImg('/bium-portal/controller/fin-ops/attach/viewByCode', params).then(({
        data
      }) => {
        if (data) {
          self.$set(self.fileUrl, typeCode, window.URL.createObjectURL(data));
          console.log('fileUrl', self.fileUrl);
        }
      });
    },
    getFile(id) {
      doImg('/bium-portal/controller/fin-ops/attach/viewById', { id: id }).then(({
        data
      }) => {
        if (data) {
          return window.URL.createObjectURL(data);
        }
      });
    },
    getDetails() {
      this.doPost('bium-portal/controller/fin-ops/enterprise/getEnterpriseDetailById', {
        id: this.$route.query.id
      }).then(res => {
        Object.assign(this.form, res.data.result);
        // this.form = res.data.result;
        this.getselectAccount();
        this.getlistStoreClearByEnterprise();
        this.getRatePlanDetail();
        const data = res.data.result;
        if (this.form.dailyRepaymentRatio) {
          this.form.dailyRepaymentRatio = this.form.dailyRepaymentRatio.match(/(\S*)%/)[1];
          this.form.dailyRepaymentRatio = `${(Number(this.form.dailyRepaymentRatio) * 100).toFixed(2)}%`;
        } else {
          this.form.dailyRepaymentRatio = '0.00%';
        }
        this.info = {
          address: data.address,
          establishmentDate: data.establishmentDate,
          validDate: data.validDate
        };
        this.value = [data.establishmentDate, data.validDate];
        this.legal = {
          id: data.id,
          legalDuration: data.legalDuration.split(','),
          legalDurationStart: data.legalDuration.split(',')[0],
          legalDurationEnd: data.legalDuration.split(',')[1],
          legalAddress: data.legalAddress,
          legalMail: data.legalMail,
          legalMobile: data.legalMobile,
          legalEthnic: data.legalEthnic
        };
        if (data.legalDuration.split(',')[1] == '长期') {
          this.legalDurationLong = 0;
        } else {
          this.legalDurationLong = 1;
        }

        this.auth = {
          id: data.id,
          authName: data.authName,
          authCard: data.authCard,
          authDuration: data.authDuration.split(','),
          authDurationStart: data.authDuration.split(',')[0],
          authDurationEnd: data.authDuration.split(',')[1],
          authAddress: data.authAddress,
          authMail: data.authMail,
          authMobile: data.authMobile,
          authEthnic: data.authEthnic
        };
        if (data.legalDuration.split(',')[1] == '长期') {
          this.authDurationLong = 0;
        } else {
          this.authDurationLong = 1;
        }
      });
    },
    toConfigStandardPlan() {
      const comp = () => import('../expense/components-plan/plan-view.vue');
      this.$modalDialog(comp);
    },
    viewImg() {
      this.isvisible = true;
    },
    onSave() {
      const self = this;
      if (self.editInfo.auth) {
        const params = {
          ...self.auth
        };
        if (params.authDuration) {
          params.authDurationStart = dayjs(params.authDuration[0]).format('YYYY-MM-DD');
          params.authDurationEnd = dayjs(params.authDuration[1]).format('YYYY-MM-DD');
        }
        if (this.authDurationLong == 0) {
          params.authDurationEnd = '长期';
        }
        params.id = self.$route.query.id;
        delete params.authDuration;
        self.doPost('bium-portal/controller/fin-ops/enterprise/updateAuthorization', params)
          .then(res => {
            if (res.data.errCode == 1000) {
              self.$message.success('修改成功');
              self.onCancel();
              self.onInit();
            } else {
              self.$message.error(res.data.errMsg);
            }
          });
      }
      if (self.editInfo.info) {
        const params = {
          ...self.info
        };
        if (self.value.length > 0) {
          params.establishmentDate = dayjs(self.value[0]).format('YYYY-MM-DD');
          params.validDate = dayjs(self.value[1]).format('YYYY-MM-DD');
        }
        params.id = self.$route.query.id;
        self.doPost('bium-portal/controller/fin-ops/enterprise/updateBusinessLicense', params)
          .then(res => {
            if (res.data.errCode == 1000) {
              self.$message.success('修改成功');
              self.onCancel();
              self.onInit();
            } else {
              self.$message.error(res.data.errMsg);
            }
          });
      }
      if (self.editInfo.legal) {
        const params = {
          ...self.legal
        };
        if (params.legalDuration) {
          params.legalDurationStart = dayjs(params.legalDuration[0]).format('YYYY-MM-DD');
          params.legalDurationEnd = dayjs(params.legalDuration[1]).format('YYYY-MM-DD');
        }
        if (this.legalDurationLong == 0) {
          params.legalDurationEnd = '长期';
        }
        delete params.legalDuration;
        params.id = self.$route.query.id;
        self.doPost('bium-portal/controller/fin-ops/enterprise/updateLegalInfo', params)
          .then(res => {
            if (res.data.errCode == 1000) {
              self.$message.success('修改成功');
              self.onCancel();
              self.onInit();
            } else {
              self.$message.error(res.data.errMsg);
            }
          });
      }
    },
    onCancel() {
      this.editInfo = {
        info: false,
        legal: false,
        auth: false
      };
    },
    onEdit(key) {
      const self = this;
      self.$nextTick(function() {
        self.$set(self.editInfo, key, true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.jg-acc {
  position: relative;
}
.jg-acc-action {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}
::v-deep .el-button {
  margin-bottom: 10px;
}

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/ .el-loading-mask {
    top: -8px;
  }
}

/deep/ .inner-box {
  margin: 20px;
  width: auto;
}

/deep/ .el-button--small .iconfont {
  font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  font-weight: normal;
  font-size: 12px;
}

/deep/ .account-list .account-list-item {
  /*min-width: 120px;*/
  height: 75px;
  text-align: center;
  padding-top: 32px;
}

/deep/ .svg-waves {
  display: none;
}

/deep/ .account-list .account-list-item.actived {
  background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
  background-color: #66cc66;
}

/deep/ .account-list .account-list-item .warn {
  background-color: #ffcc33;
}
.card-box {
}
.card-item {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}
.item_label {
  height: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 14px;
  margin-bottom: 8px;
}
.fullWidth {
  width: 100%;
}
.attachList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.attachList_item {
  width: 31%;
  margin-bottom: 10px;
  position: relative;
  height: 205px;
}
.attachList_item_name {
  height: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 14px;
  margin-bottom: 8px;
  position: relative;
}
.attachList_item_name span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #00a2ff;
}
.attachList_item:nth-child(2n) {
  // margin-left: 2%;
}
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
.flexBox-item {
  width: 32% !important;
  padding: 0 !important;
  margin-bottom: 15px;
  margin: 0 0 15px 0 !important;
  position: relative;
}
.flexBox-item:nth-child(3n-1) {
  margin: 0 2% 15px 2% !important;
}
</style>
