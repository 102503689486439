<template>
  <div class="selection-box">
    <section class="acc-container">
      <el-divider content-position="left" class="title-divider">基本信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">客户名称：</div>
            <div>{{ form.enterpriseName }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">产品号:</div>
            <div>2A-极速付款</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业编号：</div>
            <div>{{ form.enterpriseCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">联系方式：</div>
            <div>{{ form.mobile }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属渠道：</div>
            <div v-if=" form.channelId">{{ form.channelName }}</div>
            <div v-else>直客</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业状态：</div>
            <div v-if="form.enterpriseStatus == 1">认证中</div>
            <div v-if="form.enterpriseStatus == 2">认证成功</div>
            <div v-if="form.enterpriseStatus == 3">认证失败</div>
            <div v-if="form.enterpriseStatus == 4">待审核</div>
            <div v-if="form.enterpriseStatus == 5">审核通过</div>
            <div v-if="form.enterpriseStatus == 6">审核拒绝</div>
          </div>
          <div class="card-item">
            <div class="item_label">所剩额度：</div>
            <div>{{ form.residueAmount }}</div>
          </div>
          <div class="card-item">
            <el-input
              type="number"
              v-model="form.creditAmount"
              style="width: 184px"
              :disabled="residueAmountType"
            ></el-input>
            <el-button
              type="primary"
              style="margin: 1px 0 0 8px"
              @click="editResidueAmoun"
            >
              <span v-if="!residueAmountType">确认保存</span>
              <span v-if="residueAmountType">修改授信总额度</span>
            </el-button>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider" v-if='shopList.length'>店铺信息</el-divider>
      <div class="jg-acc" v-if='shopList.length'>
        <div class="card-box" v-for="(u,index) in shopList" :key="index">
          <div class="card-item">
            <div class="item_label">店铺名称:</div>
            <div>{{ u.name  }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺所属平台:</div>
            <div class="item_label">店铺所属平台:</div>
            <div v-if="u.livePlatform == 10">抖音</div>
            <div v-if="u.livePlatform == 11">快手</div>
            <div v-if="u.livePlatform == 12">拼多多</div>
            <div v-if="u.livePlatform == 13">腾讯视频号</div>
            <div v-if="u.livePlatform == 14">美团</div>
            <div v-if="u.livePlatform == 15">抖音小时达</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺ID:</div>
            <div>{{ u.storeCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺账户ID:</div>
            <div >{{ u.storekeeperAcctId  }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺账号状态:</div>
            <div v-if="u.storeStatus == 'controlled'">已管控</div>
            <div v-if="u.storeStatus == 'un_controlled'">未管控</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺账户状态:</div>
            <div v-if="u.virtualAcctStatus == 'controlled'">已管控</div>
            <div v-if="u.virtualAcctStatus == 'un_controlled'">未管控</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺管控手机号:</div>
            <div>{{ u.bindingMobile }}</div>
          </div>
          <div class="card-item">
            <el-button type="primary"
                        @click="openPath('/creditReport/index', { id:u.id })">
                        查看风控报告
                    </el-button>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">监管账户信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">账户号:</div>
            <div v-if="accountFrom.accountNo">{{ accountFrom.accountNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属银行:</div>
            <div v-if="accountFrom.bankName">{{ accountFrom.bankName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户支行号:</div>
            <div v-if="accountFrom.bankBranchNo">{{ accountFrom.bankBranchNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户系统:</div>
            <div v-if="accountFrom.paymentChannel == 1">Ping++</div>
            <div v-else-if="accountFrom.paymentChannel == 2">富友支付</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户行地址:</div>
            <div v-if="accountFrom.bankBranchAddress">{{ accountFrom.bankBranchAddress }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户余额:</div>
            <div>{{ accountFrom.totalBalance }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">可用额度:</div>
            <div>{{ accountFrom.availableAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">冻结额度:</div>
            <div >{{ accountFrom.frozenAmount }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">费率信息</el-divider>
      <div class="jg-acc" v-if="form.productType == 1">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">资金方:</div>
            <div>{{ form.fundingName }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">每日还款比例:</div>
            <div>{{ form.dailyRepaymentRatio }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">对客费率（年化）:</div>
            <div>{{ form.customerRate1 }}%</div>
          </div>
          <div class="card-item">
            <div class="item_label">用款周期:</div>
            <div>{{ form.paymentDays }}天</div>
          </div>
          <div class="card-item">
            <div class="item_label">渠道归属:</div>
            <div v-if="form.channelId">{{ form.channelName }}</div>
            <div v-if="form.channelId">
              {{
                form.channelLevel == 1
                  ? "一级渠道"
                  : form.channelLevel == 2
                  ? "二级渠道"
                  : "三级渠道"
              }}
            </div>
            <div v-else>直客</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">企业营业信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">企业名称:</div>
            <div>{{ form.enterpriseName }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">社会信用代码:</div>
            <div>{{ form.creditCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业地址信息:</div>
            <div>{{ form.address }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">营业执照有效期:</div>
            <div>{{ form.establishmentDate }}-{{ form.validDate }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">法人信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">法人姓名:</div>
            <div v-if="form.legalName">{{ form.legalName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人性别:</div>
            <div v-if="form.legalGender == 1">男</div>
            <div v-if="form.legalGender == 2">女</div>
            <div v-if="!form.legalGender">--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人身份证号:</div>
            <div v-if="form.legalCard">{{ form.legalCard }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="form.legalDuration">{{ form.legalDuration }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人邮箱:</div>
            <div v-if="form.legalMail">{{ form.legalMail }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人电话:</div>
            <div v-if="form.legalMobile">{{ form.legalMobile }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人姓名:</div>
            <div v-if="form.legalName">{{ form.legalName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="form.legalAddress">{{ form.legalAddress }}</div>
            <div v-else>--</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">授权人信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">授权人姓名:</div>
            <div v-if="form.authorizationName">{{ form.authorizationName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人性别:</div>
            <div v-if="form.authorizationGender == 1">男</div>
            <div v-if="form.authorizationGender == 2">女</div>
            <div v-if="!form.authorizationGender ">--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人身份证号:</div>
            <div v-if="form.authorizationCard">{{ form.authorizationCard }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="form.authorizationDuration">{{ form.authorizationDuration }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人邮箱:</div>
            <div v-if="form.authorizationMail">{{ form.authorizationMail }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人电话:</div>
            <div v-if="form.authorizationMobile">{{ form.authorizationMobile }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="form.authorizationAddress">{{ form.authorizationAddress }}</div>
            <div v-else>--</div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "clientDetail",
  components: {},
  data() {
    return {
      residueAmountType: true,
      isvisible: false,
      page: {
        total: 1, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      form: {},
      list: [{}],
      listLoading: false,
      accountFrom: {},
      shopList:[]
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {},

  methods: {
    editResidueAmoun() {
      if (!this.residueAmountType) {
        if (!this.form.creditAmount) return this.$message.error("请输入要修改的额度");
        this.doPost(
          "bium-portal/controller/fin-ops/enterpriseProduct/modifyCreditAmount",
          {
            enterpriseId: this.form.enterpriseId,
            creditAmount: this.form.creditAmount,
          }
        ).then((res) => {
          this.$message.success("修改成功");
          this.residueAmountType = true;
        });
      } else {
        this.residueAmountType = false;
        this.$forceUpdate();
      }
    },
    getlistStoreClearByEnterprise(){
      this.doPost('bium-portal/controller/fin-ops/store/listByEnterpriseId', {
        enterpriseId: this.form.enterpriseId
        }).then(res => {
         this.shopList =res.data.result
         console.log(this.shopList,1312312)
        })
    },
    getRatePlanDetail() {
      this.doPost("bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail", {
        storeId: this.form.storeId,
        accountType: 1,
      }).then((res) => {
        this.form.customerRate1 = (
          Number(res.data.result.custRateDetailDTOList[0].customerRate) * 100
        ).toFixed(2);
        this.$forceUpdate();
      });
    },
    getselectAccount() {
      this.doPost("bium-portal/controller/fin-ops/account/selectAccount", {
        companyCode: this.form.enterpriseCode,
        accountType: 1,
      }).then((res) => {
        if (res.data.result) {
          this.accountFrom = res.data.result[0];
        }
      });
    },
    getDetails() {
      console.log(this.$route.query.id, 22222222);
      this.doPost("bium-portal/controller/fin-ops/enterprise/getEnterpriseDetailById", {
        id: this.$route.query.id,
      }).then((res) => {
        this.form = res.data.result;
        this.getlistStoreClearByEnterprise()
        this.getRatePlanDetail();
        this.getselectAccount();
        let data = res.data.result;
        if (this.form.dailyRepaymentRatio) {
          this.form.dailyRepaymentRatio = this.form.dailyRepaymentRatio.match(
            /(\S*)%/
          )[1];
          this.form.dailyRepaymentRatio = `${(
            Number(this.form.dailyRepaymentRatio) * 100
          ).toFixed(2)}%`;
        } else {
          this.form.dailyRepaymentRatio = "0.00%";
        }
      });
    },
    toConfigStandardPlan() {
      const comp = () => import("../expense/components-plan/plan-view.vue");
      this.$modalDialog(comp);
    },
    openPath(path, query) {
            const { href } = this.$router.resolve({
                path: path,
                query: query
            })
            window.open(href, '_blank');
        },
    viewImg() {
      this.isvisible = true;
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .el-button {
  margin-bottom: 10px;
}

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/.el-loading-mask {
    top: -8px;
  }
}

/deep/ .inner-box {
  margin: 20px;
  width: auto;
}

/deep/ .el-button--small .iconfont {
  font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  font-weight: normal;
  font-size: 12px;
}

/deep/ .account-list .account-list-item {
  /*min-width: 120px;*/
  height: 75px;
  text-align: center;
  padding-top: 32px;
}

/deep/ .svg-waves {
  display: none;
}

/deep/ .account-list .account-list-item.actived {
  background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
  background-color: #66cc66;
}

/deep/.account-list .account-list-item .warn {
  background-color: #ffcc33;
}
</style>
