var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload", class: _vm.className },
    [
      _c(
        "el-upload",
        {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            accept: ".jpeg,.png,.jpg,.bmp,.gif",
            action: "#",
            "show-file-list": false,
            "on-error": _vm.uploadError,
            "http-request": _vm.getAttachUpload,
            method: "post",
            "before-upload": _vm.beforeImageUpload,
            drag: "",
          },
        },
        [
          !_vm.loadImg
            ? _c("img", {
                staticClass: "def_img",
                style: { height: _vm.width },
                attrs: { src: _vm.defaultImg, alt: "" },
              })
            : _c(
                "div",
                { staticClass: "img_box", style: { height: _vm.height } },
                [
                  _c("img", {
                    staticClass: "load_img",
                    attrs: { src: _vm.loadImg, alt: "" },
                  }),
                ]
              ),
        ]
      ),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }