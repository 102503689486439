<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="isToFullScreen"
    :show-close="false"
    class="dialog-standard plan-edit"
    top="30px"
    visible
    width="1000px"
  >
    <section slot="title" class="dialog-header">
      <span class="dialog-title">配置融资方案组</span>
      <span class="right-icon">
        <i :class="[isToFullScreen ? 'el-icon-news' : 'el-icon-full-screen']" @click="setFullScreen" />
        <i class="el-icon-close" @click="handleCancel" />
      </span>
    </section>
    <section class="dialog-body">
      <el-form ref="formRef" :inline="true" :model="dataForm.model" :rules="dataForm.rules" label-width="150px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="店铺名称：">
              <span>{{ dataItem.storeName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计息方式" prop="billingMethod">
              <el-select
                v-if="dataItem.productType == 0"
                v-model="dataForm.model.billingMethod"
                placeholder="请选择"
                @change="(value) => changeChannel(dataForm.model.channelId, value)"
              >
                <el-option
                  v-for="funder in calculate"
                  :key="funder.value"
                  :label="funder.label"
                  :value="funder.value"
                />
              </el-select>
              <el-select
                v-if="dataItem.productType == 1"
                v-model="dataForm.model.billingMethod"
                placeholder="请选择"
                @change="(value) => changeChannel(dataForm.model.channelId, value)"
              >
                <el-option
                  v-for="funder in calculate.slice(1)"
                  :key="funder.value"
                  :label="funder.label"
                  :value="funder.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="dataItem.productType == 1" :span="12">
            <el-form-item label="用款周期：" prop="paymentDays">
              <el-input v-model="dataForm.model.paymentDays" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户资金方：" prop="funderId">
              <el-select v-model="dataForm.model.funderId" placeholder="请选择">
                <el-option
                  v-for="(funder, idx) in dicFunders"
                  :key="`funder-${idx}`"
                  :label="funder.name"
                  :value="funder.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业绑定渠道：">
              <el-select
                v-model="dataForm.model.channelId"
                :disabled="dataItem.productType == 1"
                placeholder="请选择"
                @change="changeChannel"
              >
                <el-option
                  v-for="(channel, idx) in dicChannels"
                  :key="`channel-${idx}`"
                  :label="channel.name"
                  :value="channel.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="12">-->
        <!--            <el-form-item label="首笔提款回溯天数：" prop="firstTraceDays" v-if="dataItem.productType == 0">-->
        <!--              <el-input placeholder="请输入" v-model="dataForm.model.firstTraceDays">-->
        <!--                <template slot="prepend">T -</template>-->
        <!--                <template slot="append">天</template>-->
        <!--              </el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--          <el-col :span="12">-->
        <!--            <el-form-item label="多笔提款回溯天数：" prop="multipleTraceDays" v-if="dataItem.productType == 0">-->
        <!--              <el-input placeholder="请输入" v-model="dataForm.model.multipleTraceDays">-->
        <!--                <template slot="prepend">T -</template>-->
        <!--                <template slot="append">天</template>-->
        <!--              </el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item v-if="dataItem.productType == 1" label="对客利率(年化)：" prop="customerRate">
              <el-input-number
                v-model="dataForm.model.customerRate"
                :min="0"
                :precision="4"
                :step="0.1"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="dataItem.productType == 0" class="table-list">
        <p class="tips">注：对客利率上一档价格小于等于下一档，且不能低于对应档的渠道费率</p>
        <avue-crud ref="crud" :data="dataList" :option="option" :table-loading="isLoading">
          <template #channelRate="{ row }">
            <el-input-number v-model="row.channelRate" :min="0" :precision="4" :step="0.1" />
          </template>
        </avue-crud>
      </div>
    </section>
    <section class="dialog-footer">
      <el-button v-loading="isSubmiting" icon="el-icon-circle-check" type="primary" @click="handleConfirm">
        保存
      </el-button>
      <el-button class="cancel" icon="el-icon-circle-close" @click="handleCancel">取消</el-button>
    </section>
  </el-dialog>
</template>

<script>
// done 费用管理-融资方案-配置融资方案组 -->添加单笔/年化计息方式
import { dealNull } from '@/filters/index';

export default {
  name: 'PlanEdit',
  props: {
    /** 列表当前操作行数据 */
    dataItem: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isToFullScreen: false,
      isLoading: true,
      isSubmiting: false, // 防重复提交
      // 表单配置
      dataForm: {
        model: {
          funderId: '',
          channelId: '',
          firstTraceDays: '15',
          multipleTraceDays: '1',
          customerRate: null,
          // 计息方式
          billingMethod: ''
        },
        rules: {
          funderId: [
            {
              required: true,
              message: '请选择',
              trigger: 'change'
            }
          ],
          channelId: [
            {
              required: true,
              message: '请选择',
              trigger: 'change'
            }
          ],
          billingMethod: [
            {
              required: true,
              message: '请选择',
              trigger: 'change'
            }
          ],
          firstTraceDays: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur'
            }
          ],
          customerRate: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur'
            }
          ],
          multipleTraceDays: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur'
            }
          ]
        }
      },
      calculate: [
        { label: '单笔', value: 'Charge_Per_transaction' },
        { label: '年化', value: 'Annualized' }
      ],
      dicFunders: [],
      dicChannels: [],
      // 列表配置
      dDayList: [
        {
          turnoverStartDay: '1',
          turnoverEndDay: '4',
          maxDay: '7'
        },
        {
          turnoverStartDay: '5',
          turnoverEndDay: '6',
          maxDay: '10'
        },
        {
          turnoverStartDay: '7',
          turnoverEndDay: '10',
          maxDay: '20'
        },
        {
          turnoverStartDay: '11',
          turnoverEndDay: '15',
          maxDay: '25'
        },
        {
          turnoverStartDay: '16',
          turnoverEndDay: '20',
          maxDay: '30'
        },
        {
          turnoverStartDay: '21',
          turnoverEndDay: '26',
          maxDay: '35'
        },
        {
          turnoverStartDay: '27',
          turnoverEndDay: '30',
          maxDay: '40'
        },
        {
          turnoverStartDay: '31',
          turnoverEndDay: '35',
          maxDay: '45'
        },
        {
          turnoverStartDay: '36',
          turnoverEndDay: '40',
          maxDay: '50'
        },
        {
          turnoverStartDay: '41',
          turnoverEndDay: '45',
          maxDay: '55'
        }
      ],
      // 列表配置
      option: {
        addBtn: false,
        refreshBtn: false,
        columnBtn: false,
        header: false,
        menu: false,
        border: false,
        stripe: true,
        align: 'left',
        searchShowBtn: false,
        column: [
          {
            label: '对客利率',
            prop: 'channelRate',
            width: 300
          },
          {
            label: '平均回款天数',
            prop: 'avePayDays',
            width: 300
          },
          {
            label: '最大用款天数',
            prop: 'maxDay',
            width: 200
          },
          {
            label: '渠道费率',
            prop: 'channelRatePer'
          }
        ]
      },
      dataList: []
    };
  },
  watch: {},
  created() {
    this.getDicFunderData(this.dataItem.productType);
    this.getDicChannelData();
  },
  mounted() {
    this.isLoading = true;
    this.getCustRates();
  },
  methods: {
    /** 获取资金方下拉选项数据 */
    getDicFunderData(productType) {
      const api = 'bium-portal/controller/fin-ops/fundingPartner/all';
      this.doPost(api, { productType }).then(({ data }) => {
        if (data) {
          this.dicFunders = data.result;
        }
      });
    },
    /** 获取绑定渠道下拉选项数据 */
    getDicChannelData() {
      const api = 'bium-portal/controller/fin-ops/fundingPartner/boxList';
      this.doPost(api).then(({ data }) => {
        if (data) {
          this.dicChannels = data.result;
          this.dicChannels.unshift({
            name: '直客',
            id: ''
          });
        }
      });
    },
    /** 获取渠道数据 */
    getCustRates() {
      const api = 'bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail';
      const { storeId } = this.dataItem;
      this.doPost(api, {
        storeId: storeId
      }).then(({ data }) => {
        if (data && data.result) {
          this.dataForm.model = {
            paymentDays: data.result.paymentDays || '',
            funderId: data.result.funderId || '',
            channelId: data.result.channelId || '',
            firstTraceDays: data.result.firstTraceDays || '',
            multipleTraceDays: data.result.multipleTraceDays || '',
            billingMethod: data.result.billingMethod || 'Annualized'
          };
          if (this.dataItem.productType == 1) {
            if (data.result.custRateDetailDTOList != null) {
              this.dataForm.model.customerRate = (
                Number(data.result.custRateDetailDTOList[0].customerRate) * 100
              ).toFixed(4);
            } else {
              this.dataForm.model.customerRate = 0.0;
            }
          } else {
            if (data.result.custRateDetailDTOList) {
              this.dataList = this.dealResData(data.result.custRateDetailDTOList);
            } else {
              this.getList(data.result.channelId);
            }
          }
        }
        if (this.dataForm.model.billingMethod == 'Annualized') {
          this.option.column[0].label = '对客利率（年化%）';
        } else if (this.dataForm.model.billingMethod == 'Charge_Per_transaction') {
          this.option.column[0].label = '对客利率（单笔%）';
        } else {
          this.option.column[0].label = '对客利率';
        }
        this.isLoading = false;
      });
    },
    getList(val) {
      const api = 'bium-portal/controller/fin-ops/ratePlan/queryChannelRate';
      this.doPost(api, {
        channelId: val
      }).then(({ data }) => {
        if (data && data.result.length) {
          const list = data.result;
          const list1 = [];
          const list2 = [];
          for (const i in list) {
            if (list[i].billingMethod == 'Annualized') {
              list2.push(list[i]);
            } else {
              list1.push(list[i]);
            }
          }

          if (this.dataForm.model.billingMethod == 'Annualized') {
            list2.forEach((item, index) => {
              const num = list2[index].channelRate;
              item.channelRatePer = Number(dealNull(num * 100, 0)).toFixed(2) + '%';
            });
            this.dataList = list2;
          } else {
            list1.forEach((item, index) => {
              const num = list1[index].channelRate;
              item.channelRatePer = Number(dealNull(num * 100, 0)).toFixed(2) + '%';
            });
            this.dataList = list1;
          }
          this.dataList = this.dealResData2(this.dataList);
        } else {
          this.dataList.forEach((item) => {
            item.channelRatePer = 0.0 + '%';
          });
        }
      });
    },
    /** 保存提交数据 */
    saveRateData(itemList) {
      this.isSubmiting = true;
      console.log(this.dataItem, 'this.dataItem');
      const api = 'bium-portal/controller/fin-ops/ratePlan/saveCustRate';
      const form = {};
      form.companyId = this.dataItem.enterpriseId;
      form.channelId = this.dataForm.model.channelId;
      form.funderId = this.dataForm.model.funderId;
      form.firstTraceDays = this.dataForm.model.firstTraceDays;
      form.multipleTraceDays = this.dataForm.model.multipleTraceDays;
      form.paymentDays = this.dataForm.model.paymentDays;
      form.billingMethod = this.dataForm.model.billingMethod;
      form.ratePlanId = this.dataItem.ratePlanId;
      if (this.dataItem.productType == 1) {
        form.rateDetailList = [{ order: 1, customerRate: (Number(this.dataForm.model.customerRate) / 100).toFixed(6) }];
      } else {
        form.rateDetailList = [];
        this.dataList.forEach((item, index) => {
          form.rateDetailList.push({
            order: index + 1,
            customerRate: (Number(item.channelRate) / 100).toFixed(6)
          });
        });
      }
      this.doPost(api, form)
        .then(({ data }) => {
          if (data) {
            const { enterpriseName } = this.dataItem;
            this.$notify.success(`【${enterpriseName}】融资方案组修改成功。`);
            this.$options.confirm && this.$options.confirm();
          } else {
            this.isSubmiting = false;
          }
        })
        .catch(() => {
          this.isSubmiting = false;
        });
    },
    changeOption(value) {
      if (value == 'Charge_Per_transaction') {
        this.option.column[0].label = '对客利率（单笔%）';
      } else {
        this.option.column[0].label = '对客利率（年化%）';
      }
    },
    /** 渠道变化后，调整列表渠道费率 */
    changeChannel(val, value) {
      value
        ? value == 'Charge_Per_transaction'
          ? (this.option.column[0].label = '对客利率（单笔%）')
          : (this.option.column[0].label = '对客利率（年化%）')
        : '';
      const api = 'bium-portal/controller/fin-ops/ratePlan/queryChannelRate';
      this.doPost(api, {
        channelId: val
      }).then(({ data }) => {
        if (data && data.result.length) {
          this.dataList.forEach((item, index) => {
            const num = data.result[index].channelRate;
            item.channelRatePer = Number(dealNull(num * 100, 0)).toFixed(2) + '%';
          });
          const list = data.result;
          const list1 = [];
          const list2 = [];
          for (const i in list) {
            if (list[i].billingMethod == 'Annualized') {
              list2.push(list[i]);
            } else {
              list1.push(list[i]);
            }
          }
          if (this.dataForm.model.billingMethod == 'Annualized') {
            this.dataList.forEach((item, index) => {
              const num = list2[index].channelRate;
              item.channelRatePer = Number(dealNull(num * 100, 0)).toFixed(2) + '%';
            });
          } else {
            this.dataList.forEach((item, index) => {
              const num = list1[index].channelRate;
              item.channelRatePer = Number(dealNull(num * 100, 0)).toFixed(2) + '%';
            });
          }
          console.log(this.dataList);
        } else {
          this.dataList.forEach((item) => {
            item.channelRatePer = 0.0 + '%';
          });
        }
      });
    },
    /** 确认 */
    handleConfirm() {
      if (!this.isSubmiting) {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            const { dataList } = this;

            if (this.dataItem.productType == 0) {
              // 先校验是否全部填写完
              const noValue = dataList.find((item) => !item.channelRate);
              if (noValue) {
                this.$notify.error('还有未填写完的费率，请检查后再提交。');
                return;
              }
              // 再校验“对客费率不能低于当前档的渠道费率”规则
              const isltChannel = dataList.find((item) => item.channelRate < item.channelRatePer);
              if (isltChannel) {
                this.$notify.error('请检查数据是否满足“对客利率不能低于对应档的渠道费率”的规则。');
                return;
              }
              // 最后校验“对客利率下一档价格大于等于上一档的”规则
              const maxIdx = dataList.length - 1; // 最大下标索引
              const compareValid = dataList.every((item, index, arr) => {
                if (index < maxIdx) {
                  const nextFundRate = arr[index + 1].channelRate; // 下一档的对客利率
                  return nextFundRate >= item.channelRate;
                }
                return true;
              });
              // 校验通过
              if (compareValid) {
                this.saveRateData(dataList);
              } else {
                this.$notify.error('请检查数据是否满足“对客利率上一档价格小于等于下一档”的规则。');
              }
            } else {
              this.saveRateData();
            }
          }
        });
      }
    },
    /** 关闭 */
    handleCancel() {
      this.$options.cancel && this.$options.cancel();
    },
    /** 全屏设置 */
    setFullScreen() {
      this.isToFullScreen = !this.isToFullScreen;
    },

    /** 预处理接口返回数据 */
    dealResData(resData) {
      if (resData && resData.length) {
        return resData.map((item, idx) => {
          return {
            channelRate: Number(item.customerRate * 100).toFixed(4) || 0,
            avePayDays: `${dealNull(this.dDayList[idx].turnoverStartDay)}~${dealNull(
              this.dDayList[idx].turnoverEndDay,
            )}天`,
            maxDay: this.dDayList[idx].maxDay,
            channelRatePer: Number(dealNull(item.channelRate * 100, 0)).toFixed(4) + '%'
          };
        });
      }
      return [];
    },
    /** 预处理接口返回数据 */
    dealResData2(resData) {
      if (resData && resData.length) {
        return resData.map((item, idx) => {
          return {
            channelRate: 0,
            avePayDays: `${dealNull(this.dDayList[idx].turnoverStartDay)}~${dealNull(
              this.dDayList[idx].turnoverEndDay,
            )}天`,
            maxDay: this.dDayList[idx].maxDay,
            channelRatePer: Number(dealNull(item.channelRate * 100, 0)).toFixed(4) + '%'
          };
        });
      }
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.plan-edit {
  .table-list {
    .tips {
      margin-bottom: 10px;
      padding: 2px 10px;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
      border-radius: 4px;
      color: #e6a23c;
    }

    /deep/ .el-input-number .el-input input {
      text-align: center;
    }
  }

  /deep/ .el-form {
    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 8px;
    }

    .el-input-group--prepend .el-input__inner {
      width: 80px;
      text-align: center;
    }
  }
}
</style>
