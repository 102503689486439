<template>
    <div>
       
        <div class="boxone">
            <h3>{{ title }}</h3>
            <div class="section">
                <label class="label">域名地址：</label>
                <div class="domain-buttons">
                   
                    <el-button type="primary" plain @click="changeyuming(false)"  :class="!isshowdomain ? 'active' : ''">二级域名</el-button>
                    <el-button type="primary" plain @click="changeyuming(true)" >自有域名</el-button>
                </div>
                <el-form :model="form" :rules="rules" ref="ruleForm1" class="demo-ruleForm" label-width="100px">
                    <el-form-item label="域名名称：" prop="domain">
                        <el-input v-model="form.domain" class="input" placeholder="请输入域名"></el-input>
                    </el-form-item>
                    <el-form-item label="自有域名：" prop="icpNum" v-if="isshowdomain">
                        <el-input v-model="form.icpNum" class="input" placeholder="请输入ICP备案号" ></el-input>
                    </el-form-item>
                    <el-form-item label="平台名称：" prop="platformName">
                        <el-input v-model="form.platformName" class="input" placeholder="请输入平台名称"></el-input>
                    </el-form-item>
                    <el-form-item label="首页logo：" prop="homeLogoId">
                       <upload typeCode="HOMELOGO" @getid="gethomelogo" :type="type" :logoid="1"></upload>
                    </el-form-item>
                    <el-form-item label="导航栏logo：" prop="naviLogoId">
                        <upload typeCode="NAVILOGO" @getid="getnavlogo" :type="type" :logoid="2"></upload>
                    </el-form-item>
              
              <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm1')">保存站点</el-button>
    <el-button @click="resetForm('ruleForm')">重置</el-button>
  </el-form-item>
                </el-form>
             
               
                
            </div>
        </div>


    </div>

</template>
<script>
import upload from '@/components/upload/Upload.vue'
import {getsaveSiteManage} from '@/api/sass/sassmanage'
import { doImg } from '../../../router/axios'
import {getImg} from '@/api/setimgs/getimg'
export default {
    components: {
        upload
    },
    props: {
        title: {
            default: '',
        },
        id: {
            default: 1,
        },
        type: {
            default: 1,
            type: Number,
        },
        orgCode: {
            default: '',
            type: String,
        },
        dataform:{
            default: {},
            type: Object,
        }
    },
    data() {
        return {
            shouyelogo:0,
            daohanglanlogo:0,
            isshowdomain: false,
                // form: {
                //     orgId: this.dataform.orgId,
                //     id: this.dataform.id,
                //     siteType: this.dataform.siteType,
                //     type: this.type,
                //     domain: this.dataform.domain,
                //     icpNum: this.dataform.icpNum,
                //     platformName: this.dataform.platformName,
                //     homeLogoId: this.dataform.homeLogoId,
                //     naviLogoId: this.dataform.naviLogoId
                // },
                form:this.dataform,
                rules:{
                    domain: [
                        { required: true, message: '请输入域名', trigger: 'blur' },
                        // { min: 0, max: 36, message: '域名不能超过18位', trigger: 'blur'}
                    ],
                    icpNum: [
                        { required: true, message: '请输入ICP备案号', trigger: 'blur' },
                    ],
                    platformName: [
                        { required: true, message: '请输入平台名称', trigger: 'blur' },
                    ],
                    homeLogoId: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                    naviLogoId: [
                        { required: true, message: '不能为空', trigger: 'blur' },
                    ],
                }

            
        }
    },
    methods: {
        gethomelogo(val){
            this.form.homeLogoId=val
        },
                getnavlogo(val){
        this.form.naviLogoId=val
                },
        changeyuming(val) {
            this.isshowdomain = val
        },
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form);
            
            getsaveSiteManage(this.form).then(res => {
                console.log(res);
                if(res.data.success){
                    this.$message({
                    message: '保存成功',
                    type: 'success'
                    });
                }else{
                    this.$message({
                    message: res.data.msg,
                    type: 'error'
                    })
                }

            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    mounted() {

    },
    created() {
       if(this.type==1){
        this.shouyelogo=this.form.homeLogoId
        this.naivelogo=this.form.naviLogoId
       }else if(this.type==2){
        this.shouyelogo=this.form.homeLogoId
        this.naivelogo=this.form.naviLogoId
       } else if(this.type==3){
        this.shouyelogo=this.form.homeLogoId
        this.naivelogo=this.form.naviLogoId
       }else{
        this.shouyelogo=this.form.homeLogoId
        this.naivelogo=this.form.naviLogoId
       }
    },
    watch: {
        dataform: {
            handler(newVal) {
                this.form = newVal
            },
            deep: true
        }
    },
    created() {


    },
   
}
</script>
<style>
.boxone {
    width: 36rem;
    height: 45rem;
    border: 0.1rem solid black;
    border-radius: 1rem;
    padding: 1rem;
}

.container {
    padding: 20px;
    background: #fff;
    width: 400px;
    margin: auto;
}

.title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.section {
    margin-bottom: 15px;
}

.label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.domain-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.input {
    width: 45%;
}

.logo-container {
    display: flex;
    gap: 10px;
}

.logo-box {
    width: 100px;
    height: 50px;
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.save-btn {
    width: 100%;
}
.active{
    background-color: rgb(67, 145, 250) !important;
    color: white !important;
}
</style>