<template>
    <div ref="business" class="section">

        <h2 class="topall">传经办人信息</h2>
        <div style="margin-left: 1.5rem; margin-bottom: 1rem;">
            <div>请上传经办人身份证照片</div>
            <p style="color: gray;">仅支持JPG/JPREG/PNG格式，不超过2M</p>
        </div>
        <div style="width: 70vw; display: flex; justify-content: space-around;">
            <div style="width: 40%; height: 100%;">
                <p class="subTitle mt12">头像面</p>
                <Uploadfile
        className="uploadIdCard"
        typeCode="SQRSFZZM"
        defaultImg="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idBackEnd.png"
        tips="点击上传营业执照"
        ref="upload1"
        height="144px"
        @uploadSuccess="uploadSuccess"
        index="0"
      />
                <p class="subTitle mt16">国徽面</p>
                <Uploadfile
        className="uploadIdCard"
        typeCode="SQRSFZFM"
        defaultImg="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idFront.png"
        tips="点击上传营业执照"
        ref="upload2"
        height="144px"
        @uploadSuccess="uploadSuccess"
        index="1"
      />
               
            </div>
            <div style="width: 60%; background-color: rgb(246, 249, 255); padding: 1rem;">
                
              <Stefrom 
               @verifyFinish="verifyFinish"
                :rulesList="rulesList"
                :rules="rules"
                labelWidth="140px"
                ref="form2" ></Stefrom>
            </div>
        </div>
    </div>
</template>

<script>
import Uploadfile from '../../../components/Uploadfile.vue';
import formshow from '../../../components/form/formshow.vue';
import {individualTelecom3Factors,attachViewById} from '../../../api/upload'
import Stefrom from '../../../components/form/stefrom.vue';
import { checkIsPhone, checkIsEmail } from '../../../utils/cmmon'
export default {
    components: { formshow, Uploadfile, Stefrom },
    props: {
        detail: {
            type: Object,
            default: () => ({}), // Vue 2 里 `default` 必须返回对象
        },
    },
    data() {
        return {
            uploadList: [],
            Upload: null,
            rules: {
  name: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
  num: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
  staEndTime: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
  nationality: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
  issue: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
  mobile: [
    {
      validator: checkIsPhone,
      required: true,
      message: '请正确输入手机号',
      trigger: 'blur',
    },
  ],
  mail: [
    {
      validator: checkIsEmail,
      required: true,
      message: '请正确输入邮箱',
      trigger: 'blur',
    },
  ],
},
            rulesList: [
            {
    itemType: 'input',
    prop: 'name',
    placeholder: '识别后自动填入可修改',
    label: '姓名',
  },
  {
    itemType: 'input',
    prop: 'num',
    placeholder: '识别后自动填入可修改',
    label: '身份证号',
  },
  {
    itemType: 'date',
    dateType: 'daterange',
    prop: 'staEndTime',
    placeholder: '识别后自动填入可修改',
    label: '身份证有效期',
    check: 'ischeck',
  },
  {
    itemType: 'input',
    prop: 'nationality',
    placeholder: '识别后自动填入可修改',
    label: '民族',
  },
  {
    itemType: 'input',
    prop: 'issue',
    placeholder: '识别后自动填入可修改',
    label: '发证机关',
  },
  {
    itemType: 'input',
    prop: 'mobile',
    placeholder: '请输入手机号码',
    label: '手机号码',
  },
  {
    itemType: 'input',
    prop: 'mail',
    placeholder: '请输入电子邮箱',
    label: '电子邮箱',
  },
                // { itemType: "button", buttonType: "primary", buttonTitle: "下一步" },
            ],
            // 假设 `filmStore` 是 Vuex 或 Pinia，需要确保正确引入
            upload1: null,
            upload2: null,
            upload3: null,
            uploadList: [],
            form: null,
            ruleForm: {}, // Vue 2 里 `reactive({})` 直接用普通对象
            result: {
                loading: false,
                data: {},
                formData: '',
            },
            datacon:{}

        };
    },

    methods: {
    
        submitform(type) {
            this.$refs.form2.verify(type);
        },
        nextStep() {
            this.$emit("nextStep");
        },
        verifyFinish(params) {
      if (this.uploadList.length !== 2 && params.type !== 2) {
        console.log(6)
        return this.$message.error('请完成附件上传')
      }

      this.result.formData = params
      if (this.result.formData.ischeck && this.result.formData.staEndTime) {
        this.result.formData.staEndTime[1] = '2999/12/31'
      }

      this.$emit('nextStep', {
        step3: true,
        authName: this.result.formData.name,
        authEthnic: this.result.formData.nationality,
        authGender: this.result.formData.sex === '男' ? 1 : 2,
        authCard: this.result.formData.num,
        authIssuing: this.result.formData.issue,
        authDuration: this.result.formData.staEndTime
          ? `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`
          : '',
        authDurationStart: this.result.formData.staEndTime ? this.result.formData.staEndTime[0] : '',
        authDurationEnd: this.result.formData.staEndTime
          ? `${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`
          : '',
        authAddress: this.result.formData.address,
        authMobile: this.result.formData.mobile,
        authMail: this.result.formData.mail,
        authorizedFrontAttachId: this.uploadList[0] || '',
        authorizedBackAttachId: this.uploadList[1] || '',
      })
    },

    uploadSuccess(file, index) {
        console.log(file);
        this.datacon=file
      if (file && file.ocrResult && file.ocrResult.endDate === '长期') {
        file.ocrResult.endDate = '2999-12-31'
        file.ocrResult.ischeck = true
      }

      this.$set(this.uploadList, index, file.id) // Vue 2 需要使用 $set 让数据响应式更新

      this.$refs.form2.ruleForm.data = { ...this.$refs.form2.ruleForm.data, ...file.ocrResult }
      this.$set(this.$refs.form2.ruleForm, 'data', {...this.$refs.form2.ruleForm.data,...file.ocrResult });
      this.$set( this.$refs.form2.ruleForm.data, 'staEndTime', [
        this.$refs.form2.ruleForm.data.startDate,
        this.$refs.form2.ruleForm.data.endDate
        ])
  
      if (this.form.ruleForm.data.startDate && this.form.ruleForm.data.endDate) {
        this.$set( this.$refs.form2.ruleForm.data, 'staEndTime', [
        this.$refs.form2.ruleForm.data.startDate,
        this.$refs.form2.ruleForm.data.endDate
        ])
      }
      console.log(this.$refs.form2.ruleForm.data);
      
    },
    getauthEnterpriseSave(flowId) {
      console.log('🚀 ~ getauthEnterpriseSave ~ flowId:', flowId)

      // 调用后端 API 进行保存
      // authEnterpriseSave({
      //   "legal": {
      //     "name": this.result.formData.name,
      //     "ethnic": this.result.formData.nationality,
      //     "gender": this.result.formData.sex === '男' ? 1 : 2,
      //     "card": this.result.formData.num,
      //     "issuing": this.result.formData.issue,
      //     "duration": `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] === '2999-12-31' ? '长期' : this.result.formData.staEndTime[1]}`,
      //     "address": this.result.formData.address,
      //     "mobile": this.result.formData.mobile,
      //     "mail": this.result.formData.mail,
      //   },
      //   "attachmentIdList": this.uploadList,
      //   "step": "legalInfo",
      //   flowId: flowId,
      //   id: localStorage.getItem('id')
      // }).then(res => {
      //   // 处理返回结果
      // })

      this.$emit('nextStep', {
        step3: true,
        authName: this.result.formData.name,
        authEthnic: this.result.formData.nationality,
        authGender: this.result.formData.sex === '男' ? 1 : 2,
        authCard: this.result.formData.num,
        authIssuing: this.result.formData.issue,
        authDuration: `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`,
        authDurationStart: this.result.formData.staEndTime[0],
        authDurationEnd: `${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`,
        authAddress: this.result.formData.address,
        authMobile: this.result.formData.mobile,
        authMail: this.result.formData.mail,
        authorizedFrontAttachId: this.uploadList[0] || '',
        authorizedBackAttachId: this.uploadList[1] || '',
      })
    },
        getFactors() {
            this.result.loading = true;
            individualTelecom3Factors({
                name: this.result.formData.name,
                idNo: this.result.formData.num,
                mobileNo: this.result.formData.mobile,
            })
                .then((res) => {
                    this.result.loading = false;

                    this.getauthEnterpriseSave(res.data.result.flowId);
                    console.log('🚀 ~ getFactors ~ res.data.result.flowId:', res.data.result.flowId);
                })
                .catch(() => {
                    this.result.loading = false;
                });
        },
        getauthEnterpriseSave(flowId) {
            console.log('🚀 ~ getauthEnterpriseSave ~ flowId:', flowId);
            // 这里需要添加实际的 API 请求逻辑
        },
        getbyEnterpriseId() {
      const data = this.detail || {}
      let authDuration = data.authDuration

      if (Object.keys(data).length > 0) {
        this.form.ruleForm.data = {
          name: data.authName,
          nationality: data.authEthnic,
          gender: data.authGender == 1 ? '男' : '女',
          num: data.authCard,
          issue: data.authIssuing,
          staEndTime: authDuration
            ? [
                data.authDuration.split(',')[0],
                data.authDuration.split(',')[1] === '长期' ? '2999/12/31' : data.authDuration.split(',')[1],
              ]
            : [],
          address: data.authAddress,
          mobile: data.authMobile,
          mail: data.authMail,
          ischeck: authDuration
            ? data.authDuration.split(',')[1] === '2999/12/31' || data.authDuration.split(',')[1] === '长期'
            : false
        }

        if (data.attachmentList && data.attachmentList.length > 4) {
          const img = data.attachmentList.filter(item => item.typeCode === 'SQRSFZZM')
          const img1 = data.attachmentList.filter(item => item.typeCode === 'SQRSFZFM')
          let list = [img[0], img1[0]]
          this.getattachViewById(list)
        }
      }

      // 获取企业 ID 并调用后端接口
      // let id = localStorage.getItem('id')
      // if (!id) return
      // byEnterpriseId({ id: id }).then((res) => {
      //   const data = (this.result.data = res.data.result)

      //   this.form.ruleForm.data = {
      //     name: data.authorization.name,
      //     nationality: data.authorization.ethnic,
      //     gender: data.authorization.gender == 1 ? '男' : '女',
      //     num: data.authorization.card,
      //     issue: data.authorization.issuing,
      //     staEndTime: [
      //       data.authorization.duration.split(',')[0],
      //       data.authorization.duration.split(',')[1] === '长期' ? '2999/12/31' : data.authorization.duration.split(',')[1]
      //     ],
      //     address: data.authorization.address,
      //     mobile: data.authorization.mobile,
      //     mail: data.authorization.mail,
      //     ischeck:
      //       data.authorization.duration.split(',')[1] === '2999/12/31' ||
      //       data.authorization.duration.split(',')[1] === '长期'
      //   }

      //   if (res.data.result.attachmentList.length > 4) {
      //     const img = data.attachmentList.filter(item => item.typeCode === 'SQRSFZZM')
      //     const img1 = data.attachmentList.filter(item => item.typeCode === 'SQRSFZFM')
      //     let list = [img[0], img1[0]]
      //     this.getattachViewById(list)
      //   }
      // })
    },
    async getattachViewById(list) {
    let newList = []

    for (let index = 0; index < list.length; index++) {
      newList.push(list[index].id)

      try {
        const res = await attachViewById({ id: list[index].id })
        if (res && res.data) {
          if (index === 0) {
            this.upload1.loadImg = window.URL.createObjectURL(res.data)
          } else if (index === 1) {
            this.upload2.loadImg = window.URL.createObjectURL(res.data)
          }
        }
      } catch (error) {
        console.error(`获取图片失败: ${error}`)
      }
    }

    this.uploadList = newList
  }
    },
    watch: {
  detail(val, oldVal) {
    this.getbyEnterpriseId();
  }
}

};
</script>

<style>
/* 右侧各个部分 */
.section {
    border-bottom: 1px solid #ddd;
    min-height: 300px;
    padding-bottom: 1rem;
}

.top {
    width: 90%;
    height: 5rem;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: rgb(247, 249, 255);
    margin: 0 auto;
    text-align: left;
    padding-left: 5rem;
    padding-top: 0.5rem;
}

.a {
    background: linear-gradient(to bottom, #ffffff, rgb(211, 225, 253));
}

.topall {
    border-left: 0.5rem solid rgb(73, 112, 227);
    padding-left: 1rem;
}

.uploaded-image {
    width: 90%;
    height: 13rem;
    object-fit: cover;
    border-radius: 1rem;
    margin-top: 1rem;
}

.uploadFile {
    width: 24rem;
    height: 24rem;
}
</style>