<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud ref="crud" :table-loading="isLoading" :search.sync="form" :page.sync="page" :option="option"
        :data="dataList" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange">
        <template slot="qrCode" slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.qrCode" :preview-src-list="[scope.row.qrCode]">
            <div slot="error" class="image-slot">
            </div>
          </el-image>
        </template>
        <template slot="applicant" slot-scope="scope">
          <div v-if="scope.row.operator == '连连云号'">
            <el-input :disabled="scope.row.disabled" style="width: 150px; margin-right: 10px" size="mini"
              v-model="scope.row.applicant" placeholder="请输入内容"></el-input>
            <el-button v-if="scope.row.disabled" size="mini" @click="btnChange(scope.row)" type="primary"
              icon="el-icon-edit" circle></el-button>
            <el-button v-if="!scope.row.disabled" size="mini" @click="btnSend(scope.row)" type="primary"
              icon="el-icon-check" circle></el-button>
            <el-button v-if="!scope.row.disabled" @click="btnClose(scope.row)" icon="el-icon-close" circle></el-button>
          </div>
          <div v-if="scope.row.operator == '米必通'"
            style="display: flex; justify-content: space-around;">
            <div style="width: 50%;">
             <div style="text-align: left;"> {{ scope.row.applicant }}</div>
            </div>
           <div style="width: 50%; display: flex; justify-content: space-around;">
            <div v-if="scope.row.status == '待审核' || scope.row.status == '待激活'" style="color: rgb(67, 145, 250); "
              @click="deleteApply(scope.row)">
              删除
            </div>
            <div style="color: rgb(67, 145, 250);" v-if="scope.row.status == '待激活'" @click="jihuoApply(scope.row)">
              <!-- 提示框 -->
              激活成功
            </div>
           </div>
          </div>
        </template>
        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button type="primary" :loading="downLoading" @click="toConfigStandardPlan(form)">点击获取连连云号</el-button>
          <el-button type="primary" :loading="downLoading" @click="openDialog">点击申请米必通云号</el-button>
        </template>
        <template slot="menuRight">
          <div style="margin-right: 10px;font-weight: bold;display: inline-block;">当前未使用号码：{{ dataList.length ?
            dataList[0].totalcount : 0 }}个</div>
        </template>
      </avue-crud>
    </basic-container>
    <!-- 弹出框 -->
    <div>
      <el-dialog :visible.sync="dialogVisible" title="申请开通米必通云号">
        <p class="info-text">提交后会进行签约，请查看短信完成签约</p>
        <div class="upload-container">
          <div class="upload-box">
            <el-upload class="upload-demo" action="" :http-request="uploadFileFront" :show-file-list="false"
              accept="image/*">
              <el-button size="small" type="primary">上传身份证正面</el-button>
            </el-upload>
            <img v-if="idCardFront" :src="idCardFront" class="uploaded-image" alt="身份证正面" />
          </div>
          <div class="upload-box">
            <el-upload class="upload-demo" action="" :http-request="uploadFileBack" :show-file-list="false"
              accept="image/*">
              <el-button size="small" type="primary">上传身份证反面</el-button>
            </el-upload>
            <img v-if="idCardBack" :src="idCardBack" class="uploaded-image" alt="身份证反面" />
          </div>
        </div>
        <el-form :model="form_dialog" :rules="rules" ref="formRef">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form_dialog.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idNumber">
            <el-input v-model="form_dialog.idNumber" placeholder="请输入身份证号码"></el-input>
          </el-form-item>
          <el-form-item label="身份证地址" prop="address">
            <el-input v-model="form_dialog.address" placeholder="请输入身份证地址"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form_dialog.phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" @click="submitForm">确认并签约</el-button> -->
        <template>
  <el-button type="primary" :loading="loading" @click="submitForm">
    {{ loading ? '签约中...' : '确认并签约' }}
  </el-button>
</template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js'
import { doExportPostExcel, downExcel } from '@/router/axios'
import { exportExcel } from '@/utils/util.js'
import Upload from '../../components/upload/Upload.vue';
import * as imageConversion from 'image-conversion'
export default {
  name: 'ProductFinancingPlan',
  props: {},
  components: {
    Upload
  },


  data() {
    return {
      loading: false,
      dialogVisible: false,
      form_dialog: {
        operatorType: 2,
        name: '',
        idNumber: '',
        address: '',
        phone: '',
        frontAttachId: '',
        backAttachId: '',
        fontimg:'',
        backimg:''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 125, message: '姓名不能超过 125 个字符', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { max: 18, message: '身份证号码不能超过 18 位', trigger: 'blur' },
          { pattern: /^[1-9]\d{14}(\d{2}[0-9Xx])?$/, message: '身份证号码格式不正确', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入身份证地址', trigger: 'blur' },
          { max: 255, message: '身份证地址不能超过 255 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { max: 11, message: '手机号不能超过 11 位', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ]
      },

      idCardFront: null,
      idCardBack: null,
      form: {},
      isLoading: true,
      searchItmes: {},
      dataList: [],
      downLoading: false,
      option: {
        // 搜索栏配置
        searchMenuSpan: 8,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'right',
        // 表格操作列配置
        menu: false,
        menuWidth: 150,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '门店名称',
            prop: 'storeName',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan: 6,
          },
          {
            label: '手机号',
            prop: 'mobile',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan: 6,
          },

          {
            label: '号码',
            prop: 'mobile',
            align: 'left',
          },
          {
            label: '创建时间',
            prop: 'created',
            align: 'left',
          },
          {
            label: '审核状态',
            prop: 'status',
            align: 'left',
          },
          {
            label: '号码服务商',
            prop: 'operator',
            align: 'left',
          },
          {
            label: '二维码',
            prop: 'qrCode',
            align: 'left',
          },
          {
            label: '绑定店铺id/所属平台',
            prop: 'storePlat',
            align: 'left',
          },
          {
            label: '号码申请人',
            prop: 'applicant',
            align: 'left',
            width: 260,
          },
        ],
      },
      page: PAGE,
      downLoad: {},
      result: {
        defImg: []  // 假设 defImg 是一个数组
      },
      form: {
        isLong: false,
        step1_name: '',
        step1_creditCode: '',
        step1_validDate: [],
        step1_address: '',
        step1_legal_name: '',
        registeredCapital: '',
        step2_name: '',
        step2_nationality: '',
        step2_sex: 0, // 1为男，2为女
        step2_num: '',
        step2_address: '',
        step2_issue: '',
        step2_duration: '',
        step2_date: [],
        step3_sex: 0, // 1为男，2为女
        step3_address: '',
        step3_name: '',
        step3_num: '',
        step3_nationality: '',
        step3_duration: '',
        step3_issue: '',
        step3_date: [],
        isDate: false,
        isDate2: false,
      }
    }
  },
  watch: {},
  mounted() { },
  methods: {

    // 将 Base64 转换为 File
    dataURLtoFile(dataUrl, fileName) {
      const res = fetch(dataUrl);
      const blob = res.blob();
      return new File([blob], fileName, { type: blob.type });
    },

    //     // 上传文件
    //     uploadFileFront(param) {
    //   console.log(param);
    //   const file = param.file;
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);

    //   reader.onload = async () => {
    //     this.idCardFront = reader.result;
    //     // console.log("Base64 数据:", reader.result);

    //     // Base64 转 File 逻辑
    //     const res = await fetch(reader.result);
    //     const blob = await res.blob();
    //     const newFile = new File([blob], file.name, { type: blob.type });

    //     console.log("转换后的 File 对象：", newFile);

    //     // 解析身份证信息并回填
    //     await this.parseIdCard(newFile, 'front');
    //   };
    // },
    cImage(file) {
      return new Promise((resolve, reject) => {
        // 压缩图片到 400KB（可自定义）
        imageConversion.compressAccurately(file, 400)
          .then((res) => {
            const compressedFile = new File([res], file.name, { type: file.type });
            resolve(compressedFile);
          })
          .catch((error) => {
            console.error("图片压缩失败:", error);
            reject(error);
          });
      });
    },
    async uploadFileBack(param) {
      const file1 = param.file;
      const reader = new FileReader();
      reader.readAsDataURL(file1);

      reader.onload = async () => {
        this.idCardBack = reader.result;
        // console.log("Base64 数据:", reader.result);

        // Base64 转 File 逻辑
        const res = await fetch(reader.result);
        const blob = await res.blob();
        const newFile = new File([blob], file.name, { type: blob.type });

        console.log("转换后的 File 对象：", newFile);

        // 解析身份证信息并回填
        
      };
      console.log(param.file);
      let files = ''
      try {
        files = await this.cImage(param.file)
        console.log('files: ', files)
      } catch (error) {
        files = param.file
      }
      let file = new FormData()
      file.append('file', files)
      file.append('typeCode', 'VSFZFM')
      console.log(file, 22222222);

      // 直接调用解析方法
      this.parseIdCard(file, 'back');
    },


    async uploadFileFront(param) {
      const file1 = param.file;
      const reader = new FileReader();
      reader.readAsDataURL(file1);

      reader.onload = async () => {
        this.idCardFront = reader.result;
        // console.log("Base64 数据:", reader.result);

        // Base64 转 File 逻辑
        const res = await fetch(reader.result);
        const blob = await res.blob();
        const newFile = new File([blob], file.name, { type: blob.type });

        console.log("转换后的 File 对象：", newFile);

        // 解析身份证信息并回填
        
      };
      console.log(param.file);
      let files = ''
      try {
        files = await this.cImage(param.file)
        console.log('files: ', files)
      } catch (error) {
        files = param.file
      }
      let file = new FormData()
      file.append('file', files)
      file.append('typeCode', 'VSFZZM')
      console.log(file, 22222222);

      // 直接调用解析方法
      this.parseIdCard(file, 'front');
    },

    parseIdCard(imageData, side) {
      
      console.log(imageData, side);
      let api = '/bium-portal/controller/fin-ops/attach/upload';
      this.doPost(api, imageData).then(res => {
        console.log(111111111, res.data);
        let a = res.data.result.ocrResult
        //  form_dialog: {
        // name: '',
        //       idNumber: '',
        //       address: '',
        //       phone: ''
        //     },
        if (side == 'front') {
          // 返回的类型是blob，需要转换成file
          // const api='/bium-portal/controller/fin-ops/attach/viewById'
          // this.doPost(api, {
          //   id: res.data.result.id
          // }).then(res => {
            
              
          // })
         
          this.form_dialog.frontAttachId = res.data.result.id
          this.form_dialog.name = a.name
          this.form_dialog.idNumber = a.num
          this.form_dialog.address = a.address
        } else {
          this.form_dialog.backAttachId = res.data.result.id
        }



      }).catch(err => {
        console.log(err);
      })
      // const formData = new FormData();
      // formData.append('file', imageData);
      // formData.append('side', side);
      // const response = await fetch('http://localhost:8080/parseIdCard', {
      //   method: 'POST',
      //   body: formData,
      //

      // const data = await response.json();

      // if (side === 'front') {
      //   this.form.name = data.name;
      //   this.form.idNumber = data.idNumber;
      //   this.form.address = data.address;
      // }

      // console.error('身份证解析失败', error);

    },
    submitForm() {
      this.loading = true; // 按钮进入加载状态
      this.$refs.formRef.validate(valid => {
        console.log(valid);
        
        if (valid) {

          // console.log("提交信息", this.form_dialog, this.idCardFront, this.idCardBack);
          if (this.form_dialog.frontAttachId == '') {
            this.$message.error('请上传身份证正面')
            return
          } else if (this.form_dialog.backAttachId == '') {
            this.$message.error('请上传身份证反面')
            return
          }

          let api = '/bium-portal/controller/fin-ops/virtual_mobile/mbt/mobileApply/save'
          const params = {
            operatorType: this.form_dialog.operatorType,
            applyName: this.form_dialog.name,
            applyCard: this.form_dialog.idNumber,
            applyAddress: this.form_dialog.address,
            contactMobile: this.form_dialog.phone,
            frontAttachId: this.form_dialog.frontAttachId,
            backAttachId: this.form_dialog.backAttachId
          }
         
          this.doPost(api, params).then(res => {
           console.log(res);
           

            if (res.data.success) {
              console.log(222222222);
              
              this.loading = false
              this.dialogVisible = false
              this.$message.success('提交成功')
              
              this.form_dialog = {
                  operatorType: 2,
                  name: '',
                  idNumber: '',
                  address: '',
                  phone: '',
                  frontAttachId: '',
                  backAttachId: '',
                  backimg: '',
                  frontimg: ''
                }
                this.idCardFront = null
                this.idCardBack = null

            } else {
              console.log(11111111);
              
              this.loading = false
              this.$error(res.data.errMsg)
              this.form_dialog = {
                operatorType: 2,
                name: '',
                idNumber: '',
                address: '',
                phone: '',
                frontAttachId: '',
                backAttachId: '',
                backimg: '',
                frontimg: ''
          }
          this.idCardFront = null
          this.idCardBack = null
            }

            this.page.currentPage = 1
            this.searchItmes = {}
            this.getList(this.page, this.searchItmes)
          }).catch(err => {
            console.log(err);
            this.loading = false

          })

        }else{
          
          console.log("表单验证失败");
          this.loading = false
          
          // this.idCardFront = null
          // this.idCardBack = null
          return false;
        }
       
      });
    },
    

    // // 上传成功后的处理逻辑
    // uploadSuccess(data, code, index) {
    //   // 更新 defImg 数组中的数据
    //   this.result.defImg[index] = { ...this.result.defImg[index], ...data };

    //   switch (code) {
    //     case 'QYYYZZ':
    //       this.form.isLong = data.ocrResult.validDate == '长期';
    //       this.form.step1_name = data.ocrResult.companyName;
    //       this.form.step1_creditCode = data.ocrResult.companyCode;
    //       this.form.step1_validDate = [
    //         data.ocrResult.establishDate,
    //         data.ocrResult.validDate == '长期' ? '2999-12-31' : data.ocrResult.validDate,
    //       ];
    //       this.form.step1_address = data.ocrResult.address;
    //       this.form.step1_legal_name = data.ocrResult.person;
    //       this.form.registeredCapital = data.ocrResult.capital;
    //       break;

    //     // 法人身份证人像面
    //     case 'FRSFZZM':
    //       this.form.step2_name = data.ocrResult.name;
    //       this.form.step2_nationality = data.ocrResult.nationality;
    //       this.form.step2_sex = data.ocrResult.sex == '男' ? 1 : 2;
    //       this.form.step2_num = data.ocrResult.num;
    //       this.form.step2_address = data.ocrResult.address;
    //       break;

    //     // 法人身份证国徽面
    //     case 'FRSFZFM':
    //       this.form.isDate = data.ocrResult.endDate == '长期';
    //       this.form.step2_issue = data.ocrResult.issue;
    //       this.form.step2_duration = `${data.ocrResult.startDate},${data.ocrResult.endDate}`;
    //       this.form.step2_date = [
    //         data.ocrResult.startDate,
    //         data.ocrResult.endDate == '长期' ? '2999-12-31' : data.ocrResult.endDate,
    //       ];
    //       break;

    //     // 授权人身份证人像面
    //     case 'SQRSFZZM':
    //       this.form.step3_sex = data.ocrResult.sex == '男' ? 1 : 2;
    //       this.form.step3_address = data.ocrResult.address;
    //       this.form.step3_name = data.ocrResult.name;
    //       this.form.step3_num = data.ocrResult.num;
    //       this.form.step3_nationality = data.ocrResult.nationality;
    //       break;

    //     // 授权人身份证国徽面
    //     case 'SQRSFZFM':
    //       this.form.isDate2 = data.ocrResult.endDate == '长期';
    //       this.form.step3_duration = `${data.ocrResult.startDate},${data.ocrResult.endDate}`;
    //       this.form.step3_issue = data.ocrResult.issue;
    //       this.form.step3_date = [
    //         data.ocrResult.startDate,
    //         data.ocrResult.endDate == '长期' ? '2999-12-31' : data.ocrResult.endDate,
    //       ];
    //       break;

    //     default:
    //       break;
    //   }
    // },
    openDialog() {
      this.dialogVisible = true;
    },
    btnChange(e) {
      this.dataList[e.$index].title = e.applicant
      this.dataList[e.$index].disabled = false
    },
    btnClose(e) {
      this.dataList[e.$index].applicant = e.title
      this.dataList[e.$index].disabled = true
    },
    btnSend(e) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/saveApplicant'
      this.doPost(api, {
        isBound: e.isBound,
        mobile: e.mobile,
        applicant: e.applicant
      }).then(({ data }) => {
        if (data.success) {
          this.handleRefreshChange()
        } else {
          this.dataList[e.$index].applicant = e.title
          this.dataList[e.$index].disabled = true
        }
      }).catch(() => {
        this.dataList[e.$index].applicant = e.title
        this.dataList[e.$index].disabled = true
      })

    },
    getNow() {
      const now = new Date()
      return this.formatDate(now)
    },
    getLastMonth() {
      const now = new Date()
      const lastMonth = new Date(now.setMonth(now.getMonth() - 1))
      return this.formatDate(lastMonth)
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    },
    /** 获取列表数据 */
    getList(page, params) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/page'
      params = params
        ? params
        : {
          ...this.searchItmes,
        }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize

      this.isLoading = true
      this.downLoad = params
      this.doPost(api, params).then(({ data }) => {
        console.log(data, 'data');

        if (data.result.records) {
          const list = data.result.records
          list.forEach((item) => {
            item.disabled = true
            item.status =
              item.status == 0
                ? '待激活'
                : item.status == 1
                  ? '正常使用'
                  : item.status == 2
                    ? '欠费停机'
                    : item.status == 3
                      ? '销户离网'
                      : item.status == 5
                        ? '待审核'
                        : ''
            item.operator =
              item.operator == 1 ? '连连云号' : '米必通'
          })
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },

    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1
        ; (this.searchItmes = param ? param : {}), this.getList(this.page, param)
      done()
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 导出 */
    toConfigStandardPlan(params) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/create'
      this.doGet(api).then(({ data }) => {
        if (data.success) {
          this.handleRefreshChange()
        }
      })
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key]
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key]
        }
      }
      return obj
    },
    deleteApply(row) {
      console.log(row.storeCloudId);
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.storeCloudId) {
          let api = 'bium-portal/controller/fin-ops/virtual_mobile/delete/'
          this.doGet(api + row.storeCloudId).then(res => {
            console.log(res);
            if (res.status == 200) {
              this.$message.success('删除成功')
              this.page.currentPage = 1
              this.searchItmes = {}
              this.getList(this.page, this.searchItmes)
            }
          })
        }
      }).catch(() => {
        this.$message.info('已取消')
      })
      // if (row.storeCloudId) {
      //   let api = 'bium-portal/controller/fin-ops/virtual_mobile/delete/'
      //   this.doGet(api + row.storeCloudId).then(res => {
      //     console.log(res);
      //     if (res.status == 200) {
      //       this.$message.success('删除成功')
      //       this.page.currentPage = 1
      //       this.searchItmes = {}
      //       this.getList(this.page, this.searchItmes)
      //     }

      //   })
      // }

    },
    jihuoApply(row) {
      // 提示框
      this.$confirm('确定要激活吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let api = 'bium-portal/controller/fin-ops/virtual_mobile/active/'
        this.doGet(api + row.storeCloudId).then(res => {
          console.log(res);
          if (res.status == 200) {
            this.$message.success('激活成功')
            this.page.currentPage = 1
            this.searchItmes = {}
            this.getList(this.page, this.searchItmes)
          }

        })
      }).catch(() => {
        this.$message.info('已取消')
      })
    }


  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
  .el-form-item__label {
    width: fit-content !important;
  }

  .el-form-item__content {
    margin-left: 100px !important;
  }
}

.upload-demo {
  margin-bottom: 10px;
}

.info-text {
  color: #409EFF;
  margin-bottom: 10px;
}

.upload-demo {
  margin-bottom: 10px;
}

.info-text {
  color: #409EFF;
  margin-bottom: 10px;
}

.upload-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.upload-box {
  text-align: center;
  width: 48%;
  // height:200px;
}

.uploaded-image {
  // margin-top: 10px;
  max-width: 50%;
  height: 100px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
