<template>
    <div>
        <basic-container>
          <div style="padding-left: 2rem;">  <h1>站点配置</h1>
            <h2 style="margin-top: 1.5rem; margin-bottom: 1.5rem;">Sass企业名称：{{ this.$route.query.name }}</h2></div>
            <div
                style="width: 100%; height: 100vh; display: flex; flex-direction: column; justify-content: space-around; margin-bottom: 15rem;">
                <div style="display: flex; width: 100%; justify-content: space-around;">
                   
                   <sassoption title="独立商户站（必选）" :id="id" type=1 :orgCode="orgCode" :dataform="type1" ></sassoption>
                    <sassoption title="独立运营站（必选）" :id="id" type=2 :orgCode="orgCode" :dataform="type2"></sassoption>
                </div>
                <div style="display: flex;width: 100%; justify-content: space-around; margin-top: 2rem; margin-bottom: 10rem;">
                    <sassoption title="独立渠道站（必选）" :id="id" type=3 :orgCode="orgCode" :dataform="type3"></sassoption> 
                    <sassoption title="独立资方管理站（必选）" :id="id" type=4 :orgCode="orgCode" :dataform="type4"></sassoption>
                </div>
            </div>
        </basic-container>
    </div>
</template>
<script>
import sassoption from './components/sassoption.vue'
import {getviewSiteManage} from '@/api/sass/sassmanage'
export default {
    

    components: {
        sassoption
    },

   
    
    data() {
        return {
            name: this.$route.query.name,
            id: this.$route.query.id,
            orgCode: this.$route.query.orgCode,
            type1:{
                orgId: this.id,
                    id: 0,
                    siteType: 1, //域名
                    type: 1,
                    domain: '',
                    icpNum: "",
                    platformName: "",
                    homeLogoId: 0,
                    naviLogoId: 0
            },
            type2:{
                orgId: this.id,
                    id: 0,
                    siteType: 1, //域名
                    type: 1,
                    domain: '',
                    icpNum: "",
                    platformName: "",
                    homeLogoId: 0,
                    naviLogoId: 0
            },
            type3:{
                orgId: this.id,
                    id: 0,
                    siteType: 1, //域名
                    type: 1,
                    domain: '',
                    icpNum: "",
                    platformName: "",
                    homeLogoId: 0,
                    naviLogoId: 0
            },
            type4:{
                orgId: this.id,
                    id: 0,
                    siteType: 1, //域名
                    type: 1,
                    domain: '',
                    icpNum: "",
                    platformName: "",
                    homeLogoId: 0,
                    naviLogoId: 0
            }
        }
    },
    created() {
        this.getviewSiteManage()
    },
    methods: {
        getviewSiteManage() {
            getviewSiteManage(this.id).then(res => {
                console.log(res.data.result);
                const data = res.data.result
                data.forEach(item => {
                    if(item.type==1){
                console.log(item);
                
                        this.type1.orgId= this.id,
                        this.type1.id= item.id,
                        this.type1.siteType= item.config.siteType, //域名
                        this.type1.type=item.type,
                        this.type1.domain= item.config.domain,
                        this.type1.icpNum= item.config.icpNum,
                        this.type1.platformName= item.config.platformName,
                        this.type1.homeLogoId= item.config.homeLogoId,
                        this.type1.naviLogoId= item.config.naviLogoId
           
                       
                        // console.log(this.type1);
                        localStorage.setItem('type1homeLogoId', item.config.homeLogoId)
                        localStorage.setItem('type1naviLogoId', item.config.naviLogoId)
                        
                    }else if(item.type==2){
                            this.type2.orgId= this.id,
                            this.type2.id= item.id,
                            this.type2.siteType= item.config.siteType, //域名
                            this.type2.type=item.type,
                            this.type2.domain= item.config.domain,
                            this.type2.icpNum= item.config.icpNum,
                            this.type2.platformName= item.config.platformName,
                            this.type2.homeLogoId= item.config.homeLogoId,
                        this.type2.naviLogoId= item.config.naviLogoId
                        localStorage.setItem('type2homeLogoId', item.config.homeLogoId)
                        localStorage.setItem('type2naviLogoId', item.config.naviLogoId)
                            }
                        else if(item.type==3){
                            this.type3.orgId= this.id,
                            this.type3.id= item.id,
                            this.type3.siteType= item.config.siteType, //域名
                            this.type3.type=item.type,
                            this.type3.domain= item.config.domain,
                            this.type3.icpNum= item.config.icpNum,
                            this.type3.platformName= item.config.platformName,
                            this.type3.homeLogoId= item.config.homeLogoId,
                        this.type3.naviLogoId= item.config.naviLogoId
                        localStorage.setItem('type3homeLogoId', item.config.homeLogoId)
                        localStorage.setItem('type3naviLogoId', item.config.naviLogoId)
                        }
                        else if(item.type==4){
                            this.type4.orgId= this.id,
                            this.type4.id= item.id,
                            this.type4.siteType= item.config.siteType, //域名
                            this.type4.type=item.type,
                            this.type4.domain= item.config.domain,
                            this.type4.icpNum= item.config.icpNum,
                            this.type4.platformName= item.config.platformName,
                            this.type4.homeLogoId= item.config.homeLogoId,
                        this.type4.naviLogoId= item.config.naviLogoId
                        localStorage.setItem('type4homeLogoId', item.config.homeLogoId)
                        localStorage.setItem('type4naviLogoId', item.config.naviLogoId)

                    }else{

                    }
                })
                
        })
    }
    }

}

</script>
<style scoped>
.boxone {
    width: 44%;
    height: 33rem;
    border: 0.1rem solid black;
    border-radius: 1rem;
    padding: 1rem;
}
</style>