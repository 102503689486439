var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Role_management" }, [
    _c("button", { on: { click: _vm.goaddasaauser } }, [
      _vm._v("\naddsass\n        "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }