<template>
  <div>
    <basic-container>
      <div style="width: 100%; height: auto;">
        <el-tabs :tab-position="tabPosition" @tab-click="changecompany">
          <template v-for="i in sasscompany">
            <el-tab-pane :label="i.orgName" :key="i.orgCode">
              <el-tabs :tab-position="tabPosition">
                <el-tab-pane label="角色">
                  <div>
                    <el-button type="primary" @click="openaddrole(1)">创建角色</el-button>
                    <el-dialog :title="addroletype==1?'创建角色':'编辑角色'" :visible.sync="dialogFormVisible">
                      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="角色名称" prop="name">
                          <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="角色描述" prop="desc">
                          <el-input v-model="form.desc"></el-input>
                        </el-form-item>

                      </el-form>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="closeaddrole">取 消</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                      </div>
                    </el-dialog>
                    <div>
                      <el-table :data="rolelist" style="width: 100%" stripe>
                        <el-table-column v-for="i in rolelistrules" :key="i.prop" :prop="i.prop" :label="i.label"
                          :width="i.width">


                          <!-- <template v-if="i.slotName === 'setting'" v-slot="scope">
                            <div style="display: flex; justify-content: space-between;">
                              <span style="color: rgb(67, 145, 250);">编辑</span>
                              <span style="color: rgb(67, 145, 250);">配置权限</span>
                              <span style="color: red;" @click="deleterole(scope.row)">删除</span>
                            </div>
                          </template> -->

                          <div slot-scope="scope" v-if="i.slotName == 'setting'">
                            <div style="display: flex; justify-content: space-between;width: 50%;">
                              <span style="color: rgb(67, 145, 250);" @click="editrole(scope.row)">编辑</span>
                              <span style="color: rgb(67, 145, 250);"
                                @click="changedialogVisible2(scope.row)">配置权限</span>
                              <span style="color: red;" @click="deleterole(scope.row)">删除</span>
                            </div>

                          </div>

                          <div slot-scope="scope" v-else-if="i.slotName == 'setting1'">
                            <div class="flex gap-2">
                              <el-button type="text" style="color: #ff672b"
                                @click="deleterole(scope.row)">删除</el-button>
                            </div>
                          </div>
                          <div slot-scope="scope" v-else>
                            <div class="flex gap-2">{{ scope.row[i.prop] }}</div>
                          </div>


                        </el-table-column>


                      </el-table>
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="current" :page-sizes="[10, 20, 30, 40]" :page-size="size"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                      </el-pagination>
                      <!--下权限管理  -->
                      <Settrole :dialogVisible="dialogVisible2" :orgCode="companyvaluecode[0].orgCode" :roleId="roleid"
                        @dialogVisible2false="dialogVisible2false" :data="datarole"  ref="childRef" :roletree="roletree" ></Settrole>
                    </div>
                  </div>

                </el-tab-pane>
                <el-tab-pane label="用户">
                  <div>
                    <el-input v-model="searchuserphone" placeholder="请输入用户手机号" style="width: 30%; margin-right: 1rem;"></el-input>
                    <el-button type="primary" @click="searchuser">搜索</el-button>
                    <el-button type="primary" @click="adduserdia(1)">新增用户</el-button>
                    <el-dialog :title="addusertype == 1 ? '新增用户' : '编辑用户'" :visible.sync="dialogFormVisible1">

                      <el-form :model="saveover" :rules="saveoverrules" ref="saveover" label-width="150px"
                        class="demo-ruleForm">
                        <el-form-item label="用户名字：" prop="name">
                          <el-input v-model="saveover.name" placeholder="请输入用户名字"></el-input>
                        </el-form-item>
                      <el-form-item label="用户类型：" prop="type" >
                          <el-select v-model="saveover.type" placeholder="请选择用户类型" style="width: 100%;">
                            <el-option
                              v-for="item in addusertypelist"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                         
                        </el-form-item>
                        <el-form-item label="所属平台：" prop="orgCode">
                         
                          <el-select v-model="saveover.orgCode" placeholder="请选择所属平台" style="width: 100%;">
                            <el-option
                              v-for="item in getavailablePlatformlist"
                              :key="item.value"
                              :label="item.name"
                              :value="item.code">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="登录手机号：" prop="mobile">
                          <el-input v-model="saveover.mobile" placeholder="请输入登录手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="登录密码：" prop="password">
                          <el-input v-model="saveover.password" placeholder="请输入登录密码"></el-input>
                        </el-form-item>
                        <el-form-item label="用户角色(可多选)：" prop="roleIds">
                        
                          <el-select v-model="saveover.roleIds" multiple placeholder="请选择用户角色" style="width: 100%;">
                            <el-option
                              v-for="item in getuserRoleSelectlist"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        
                      </el-form>

                      <div slot="footer" class="dialog-footer">
                          <el-button @click="closeaddroleuser">取 消</el-button>
                          <el-button type="primary" @click="adduser('saveover')">确 定</el-button>
                        </div>
                    </el-dialog>

                    <div>
                      <el-table :data="userlist" style="width: 100%" stripe>
                        <el-table-column v-for="i in userlistrules" :key="i.prop" :prop="i.prop" :label="i.label" :width="i.width">
                          <template v-if="i.slotName === 'setting'" v-slot="scope">
                            <div style="display: flex; justify-content: space-between;">
                              <span style="color: rgb(67, 145, 250);" @click="changediauser(scope.row, 2)">用户管理</span>
                              <span style="color: red;" @click="deleterolerule(scope.row)">删除</span>
                            </div>
                          </template>
                          <template v-slot="scope" v-else-if="i.slotName === 'roleList'" >
                            <span v-for="i in scope.row.roleList" :key="i"
                              style="background-color: rgb(67, 145, 250); color: white; padding: 2px 5px; border-radius: 3px; margin-right: 5px">{{
                              i.name }}</span>
                          </template>
                          <template v-slot="scope" v-else-if="i.slotName === 'aa'">

                          </template>

                        </el-table-column>


                      </el-table>
                      <el-pagination @size-change="handleSizeChangeuser" @current-change="handleCurrentChangeuser"
                        :current-page="userlistpages.current" :page-sizes="[10, 20, 30, 40]"
                        :page-size="userlistpages.size" layout="total, sizes, prev, pager, next, jumper"
                        :total="userlistpages.total">
                      </el-pagination>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>


            </el-tab-pane>
          </template>

        </el-tabs>
      </div>

    </basic-container>

  </div>
</template>
<script>
import { getcompanytree, getpermissonadd, getrolelist, getroleadd, getmenuPermission, getuserList, getdeleteUser,
  getaddUser ,getavailablePlatform,getuserRoleSelect,
  getDeleteRole,
  getviewUserDetail,getaddPermission,getSaveUser,getconfigOrgPermission,getPermissionData,getRolePermission,getEditUserRole} from '@/api/sass/sassmanage';
import Settrole from './components/settrole.vue';
export default {
  components: {
    Settrole
  },



  data() {
    return {
      searchuserphone:'',
      datarole: [],
      roletree:[],
      roleid: 0,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogVisible2: false,
      formLabelWidth: '120px',
      getavailablePlatformlist:[],
      getuserRoleSelectlist:[],
      addusertypelist: [{
        value: 10000,
        label: '运营站'
      },
      {
        value: 20000,
        label: '渠道站'
      }, {
        value: 30000,
        label: '商户站'
      }, {
        value: 40000,
        label: '资方站'
      }],
      form: {
        id:'',
        name: '',
        desc: ''
      },
      form1: {

      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' },
        ]
      },
      total: 0,
      size: 10,
      current: 1,
      pages: 1,
      tabPosition: 'top',
      companyvalue: "杭州乐书数字科技有限公司",
      companyvaluecode: [
        {
          orgCode: "914480330000000127",
          orgName: "简米四企业一百二十七"
        }
      ],
      sasscompany: [

      ],

      rolelist: [],
      rolelistrules: [
        { label: '角色名称', prop: 'name', width: 200, },
        { label: '角色id', prop: 'id', width: 200 },
        { label: '角色描述', prop: 'description', width: 200 },
        { label: '创建时间', prop: 'created', width: 200 },
        {
          label: '操作',
          slotName: 'setting',
        },



      ],
      userlistrules: [
        { label: '用户登录手机号', prop: 'mobile',width: 200, },
        { label: '用户名称', prop: 'userName',width: 200, },
        { label: '用户id', prop: 'userId',width: 200, },
        { label: '用户平台', prop: 'orgName',width: 200, },
        { label: '创建时间', prop: 'created', width: 200,},
        { label: '用户角色', prop: 'roleList', slotName: 'roleList' ,width: 600,},
        { label: '操作', prop: '', slotName: 'setting', width: 200,},
      ],
      userlist: [],
      userlistpages: {
        current: 1,
        size: 10,
        total: 0,
        pages: 0,
      },
      // 用户
      saveover: {
        id: 0,
        name: "",
        mobile: '',
        password: '',
        type: '',
        orgCode: "",
        roleIds: []
      },
      saveoverrules: {
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入用户手机号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择用户类型', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '请选择用户平台', trigger: 'blur' },
        ],
        roleIds: [
          { required: true, message: '请选择用户角色', trigger: 'blur' },
        ],

      },
      addusertype: 1,// 1新增 2修改
      addroletype:1,// 1新增 2修改
   
    }
  },
  created() {
    this.getgetcompanytreelist()
    this.getrolelistlist()
    this.getuserlist()
this.getgetavailablePlatformlist()
this.getgetuserRoleSelectlist()
  },
  methods: {
    openaddrole(val){
      console.log(11111111);
      
this.addroletype = 1
this.dialogFormVisible = true
    },
    editrole(row){
      console.log(row);
      
      this.addroletype = 2
      this.form.id = row.id
      this.dialogFormVisible = true
      this.form.name= row.name
      this.form.desc= row.description

    },
    searchuser(){
this.getuserlist()
    },
    getgetuserRoleSelectlist() {
      console.log(1111111111);
      
      // console.log(this.companyvaluecode[0]);
      
      getuserRoleSelect(this.companyvaluecode[0].orgCode).then(res => {
        console.log(res);
        
        if(res.data.success){
          this.getuserRoleSelectlist = res.data.result
        }else{
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
        
      })
    },
    getgetavailablePlatformlist() {
      getavailablePlatform().then(res => {
        if(res.data.success){
          this.getavailablePlatformlist = res.data.result
        }else{
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
      })
    },
    changediauser(row, val) {
      console.log(row, val);
      
      this.addusertype = val
      getviewUserDetail({queryId: row.userId}).then(res => {
        console.log(res);
        
        if (res.data.success) {
         
          const data = res.data.result;

  this.saveover.id=data.userId,
      this.saveover.name=data.userName,
      this.saveover.mobile= data.mobile,
      this.saveover.password= '***************',
      this.saveover.type= data.userType,
      this.saveover.orgCode= data.orgCode,
      this.saveover.roleIds= data.roleList.map(item => item.roleId),
     
          this.dialogFormVisible1 = true
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
      })
    },
    adduserdia(val) {
      console.log(val);
      
      this.dialogFormVisible1 = true
      this.addusertype = val
    },
    getuserlist() {
      getuserList({
        // mobile: 15700050003,
        mobile: this.searchuserphone,
        pageNum: this.userlistpages.current,
        pageSize: this.userlistpages.size,
        orgCode: this.companyvaluecode[0].orgCode,
        // pages:this.userlistpages.pages
      }).then(res => {
        if (res.data.success) {
          this.userlist = res.data.result.records
          console.log(this.userlist);
          
          this.userlistpages.current = res.data.result.current
          this.userlistpages.size = res.data.result.size
          this.userlistpages.total = res.data.result.total
          this.userlistpages.pages = res.data.result.pages

        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },

    changedialogVisible2(val) {


      this.roleid = val.id
      this.dialogVisible2 = true
    //  回调的接口 
    getRolePermission({roleId:val.id}).then(res => {
            console.log(res.data.result);
          this.roletree = res.data.result
           
          })
  
          getmenuPermission({
        orgCode: this.companyvaluecode[0].orgCode,
      }).then(res => {
        console.log(res.data.result,'roledata');
        if (res.data.success) {
          this.datarole = res.data.result
        }
      })

    },
    dialogVisible2false(val) {
      console.log(val);

      this.dialogVisible2 = val
    },
    adduser() {
      console.log(111111111111);
      
      this.dialogFormVisible1 = false
      getaddUser({...this.saveover,orgCode:this.companyvaluecode[0].orgCode}).then(res => {
        console.log(res);
        
if(res.data.success){
      if (res.data.success) {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.getuserlist()
        this.saveover = {
        name: "",
        mobile: '',
        password: '',
        type: '',
        orgCode: "",
        roleIds: []
      }
      }
}else{
  // this.$message({
  //   type: 'error',
  //   message: res.data.msg
  // })
}


      })
    },
    closeaddrole() {
      this.dialogFormVisible = false
      this.form = {
        name: '',
        desc: ''
      }
    },
    closeaddroleuser(){
      this.dialogFormVisible1 = false
      this.saveover = {
        name: "",
        mobile: '',
        password: '',
        type: '',
        orgCode: "",
        roleIds: []
      }
    },
    submitForm(formName) {
      if(this.addroletype == 1){
        // add
        this.dialogFormVisible = false
      getroleadd({
        orgCode: this.companyvaluecode[0].orgCode,
        name: this.form.name,
        description: this.form.desc
      }).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '添加成功!'
          })
          this.getrolelistlist()
        } else {
          // this.$message({
          //   type: 'error',
          //   message: res.data.msg
          // })
        }

      })

      }else{
        // edit
        getEditUserRole({
          id: this.form.id,
          name: this.form.name,
        description: this.form.desc
        }).then((res)=>{
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getrolelistlist()
        })
      }
    

    },

    deleterole(row) {
      console.log(1111111);

      console.log(row);
      getDeleteRole({roleId: row.id}).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getrolelistlist()
        } 
      })
    },
    deleterolerule(row) {
      console.log(row);

      getdeleteUser(row.userId).then(res => {
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getuserlist()
        } else {
          // this.$message({
          //   type: 'error',
          //   message: res.data.msg
          // })
        }
      })
    },
    getrolelistlist() {
      getrolelist(
        {
          orgCode: this.companyvaluecode[0].orgCode,
          pageSize: this.size,
          pageNum: this.current
        }
      ).then(res => {
        if (res.data.success) {
          this.rolelist = res.data.result.records
          this.total = res.data.result.total
          this.size = res.data.result.size
          this.current = res.data.result.current
          this.pages = res.data.result.pages
        } else {
          // this.$message.error(res.data.message)
        }
      })
    },


    addpermission() {
      getpermissonadd({
        orgCode: this.companyvaluecode[0].orgCode,
        permissionIds: [11, 12, 13]
      }).then(res => {
        console.log(res);

      }
      )
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getrolelistlist()
    },
    handleSizeChangeuser(val) {
      console.log(`每页 ${val} 条`);
      this.userlistpages.size = val;
      console.log(this.userlistpages.size);
      this.getuserlist()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getrolelistlist()
    },
    handleCurrentChangeuser(val) {
      console.log(`当前页: ${val}`);
      this.userlistpages.current = val;
      console.log(this.userlistpages.current);

      this.getuserlist()
    },
    changecompany(val) {
      console.log(val.label);
  
      this.companyvalue = val.label
      this.companyvaluecode = this.sasscompany.filter(item => {
        if (item.orgName == this.companyvalue) {
          return this.companyvaluecode = item.orgCode
        }
      })
      console.log(this.companyvaluecode);

      this.getrolelistlist()
      this.getuserlist()
      this.getgetavailablePlatformlist()
      this.getgetuserRoleSelectlist()
      
    },
    getgetcompanytreelist() {
      getcompanytree().then(res => {
        console.log(res);

        if (res.data.success) {
          this.sasscompany = res.data.result
          console.log(this.sasscompany);


        } else {
          // this.$message.error(res.data.message)
        }

      })
    },
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([{
        id: 5,
        label: '二级 2-1'
      }, {
        id: 9,
        label: '三级 1-1-1'
      }]);
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    }
  },

  mounted() {
  },
  watch:{
//     companyvaluecode(oldval,newval){
//       if(oldval!=newval){
//         this.getgetcompanytreelist()
//     this.getrolelistlist()
//     this.getuserlist()
// this.getgetavailablePlatformlist()
// this.getgetuserRoleSelectlist()
//       }
   
    // }
  }
}
</script>
<style>
.v-modal {
  z-index: 200 !important;
}
</style>