var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "business", staticClass: "section" }, [
    _c("h2", { staticClass: "topall" }, [_vm._v("传经办人信息")]),
    _vm._m(0),
    _c(
      "div",
      {
        staticStyle: {
          width: "70vw",
          display: "flex",
          "justify-content": "space-around",
        },
      },
      [
        _c(
          "div",
          { staticStyle: { width: "40%", height: "100%" } },
          [
            _c("p", { staticClass: "subTitle mt12" }, [_vm._v("头像面")]),
            _c("Uploadfile", {
              ref: "upload1",
              attrs: {
                className: "uploadIdCard",
                typeCode: "SQRSFZZM",
                defaultImg:
                  "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idBackEnd.png",
                tips: "点击上传营业执照",
                height: "144px",
                index: "0",
              },
              on: { uploadSuccess: _vm.uploadSuccess },
            }),
            _c("p", { staticClass: "subTitle mt16" }, [_vm._v("国徽面")]),
            _c("Uploadfile", {
              ref: "upload2",
              attrs: {
                className: "uploadIdCard",
                typeCode: "SQRSFZFM",
                defaultImg:
                  "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idFront.png",
                tips: "点击上传营业执照",
                height: "144px",
                index: "1",
              },
              on: { uploadSuccess: _vm.uploadSuccess },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "60%",
              "background-color": "rgb(246, 249, 255)",
              padding: "1rem",
            },
          },
          [
            _c("Stefrom", {
              ref: "form2",
              attrs: {
                rulesList: _vm.rulesList,
                rules: _vm.rules,
                labelWidth: "140px",
              },
              on: { verifyFinish: _vm.verifyFinish },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "margin-left": "1.5rem", "margin-bottom": "1rem" } },
      [
        _c("div", [_vm._v("请上传经办人身份证照片")]),
        _c("p", { staticStyle: { color: "gray" } }, [
          _vm._v("仅支持JPG/JPREG/PNG格式，不超过2M"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }