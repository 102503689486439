var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("basic-container", [
        _c("h1", [_vm._v("技术服务费明细")]),
        _c("div", [_vm._v("\n            input\n        ")]),
        _c("div"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }