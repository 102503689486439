import request, { doPostMulti, doUrl } from '@/router/axios';

export function upload(url, data) {
  const param = new FormData();
  Object.keys(data).forEach(key => {
    param.append(key, data[key]);
  });
  return doPostMulti(url, param);
}

export function getImageUrl(fileId) {
  return doUrl(`/common/viewImg?fileId=${fileId}`);
}

export function deleteFile(fileId) {
  return request({
    url: 'common/delete/' + fileId,
    method: 'get'
  });
}
export function attachViewById  (data)  {
  return request({
    method: 'post',
    url: '/bium-portal/controller/fin-ops/attach/viewById',
    responseType: 'blob',
    data,
  })
}
export function attachUpload  (data)  {
  return request({
    method: 'post',
    url: '/bium-portal/controller/fin-ops/attach/upload',
    data,
  })
}

// 运营商3要素核身
export const individualTelecom3Factors = (data) => {
  return request({
    method: 'post',
    url: '/bium-portal/controller/fin-ops/factors/individualTelecom3Factors',
    data,
  })
}
export const uploadimg = (data) => {
  return request({
    method: 'post',
    url: '/bium-portal/controller/fin-ops/attach/upload',
    data,
  })
}
