<template>
  <div>
    <basic-container>
      <div style="display: flex; justify-content: space-between; width: 50%;">

        <div>
          Sass用户企业名称
          <el-input v-model="searchname" placeholder="请输入企业名称"
            style="width: 200px;margin-left: 10px;margin-bottom: 4rem; margin-right: 1rem;"
            @keyup.enter.native="handleFilter" clearable>
          </el-input>
        </div>
        <div>
          <el-button type="primary" @click="handleFilter">搜索</el-button>
          <el-button type="primary" @click="addSassuser">新增Sass用户</el-button>
        </div>
      </div>
      <el-table :data="sasslist" style="width: 100%" stripe>
        <el-table-column prop="enterpriseName" label="企业名称" width="180">
        </el-table-column>
        <el-table-column prop="enterpriseStatus" label="企业认证状态" width="180">
          <template slot-scope="scope">
            <!-- 7:待认证 3:认证失败 31:企业四要素认证失败 32:法人三要素认证失败 2:认证成功 -->
            <el-tag v-if="scope.row.enterpriseStatus == 7" type="danger">待认证</el-tag>
            <el-tag v-if="scope.row.enterpriseStatus == 2" type="success">已认证</el-tag>
            <el-tag v-if="scope.row.enterpriseStatus == 3" type="warning">认证失败</el-tag>
            <el-tag v-if="scope.row.enterpriseStatus == 31" type="warning">企业四要素认证失败</el-tag>
            <el-tag v-if="scope.row.enterpriseStatus == 32" type="warning">法人三要素认证失败</el-tag>
          </template>

        </el-table-column>

        <el-table-column prop="accountStatus" label="Sass平台账户">
          <!-- /**
     * 账户状态 1:可用 2:待签约 3:待开户 4:Pingpp 待短信验证 5:Pingpp 待打款验证
     * 6:签约失败 7:开户失败 8:销户 8:处理中 10:等待开场景账户
     */ -->
          <template slot-scope="scope">
            <span v-if="scope.row.accountId === 1">可用</span>
            <span v-if="scope.row.accountId === 2">待签约</span>
            <span v-if="scope.row.accountId === 3">待开户</span>
            <span v-if="scope.row.accountId === 4">Pingpp 待短信验证</span>
            <span v-if="scope.row.accountId === 5">Pingpp 待打款验证</span>
            <span v-if="scope.row.accountId === 6">签约失败</span>
            <span v-if="scope.row.accountId === 7">开户失败</span>
            <span v-if="scope.row.accountId === 8">销户</span>
            <span v-if="scope.row.accountId === 8">处理中</span>
            <span v-if="scope.row.accountId === 10">等待开场景账户</span>
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="渠道状态">
          <!-- /**
     * saas状态
     * 1 启用
     * 0 禁用
     */ -->
          <template slot-scope="scope">
            <el-select v-model="scope.row.status" placeholder="请选择"
              @change="handleChangeslelect(scope.$index, scope.row)">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="created" label="创建时间">
        </el-table-column>
        <el-table-column width="300px" prop="set" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="settingoption(scope.$index, scope.row)">基础配置</el-button>
            <el-button type="primary" @click="sitadministration(scope.$index, scope.row)">站点管理</el-button>
            <el-button type="primary" @click="sitrule(scope.$index, scope.row)">权限管理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page=currentpage
        :page-sizes="[10, 20, 30, 40]" :page-size=pgesize layout="total, sizes, prev, pager, next, jumper" :total=total>
      </el-pagination>
      <Setrole :orgCode="setorgcode" :dialogVisible="dialogVisible" @dialogVisible2false="dialogVisible2false" :data="data"  :roletree="roletree"></Setrole>
    </basic-container>
    
  </div>
</template>

<script>
import { getsasslist, } from '../../api/sass/sasslist';
import { getisDisable ,getconfigOrgPermission,getviewSiteManage,getPermissionData} from '@/api/sass/sassmanage';

import Setrole from './components/setrole.vue';


export default {
  components: {
    Setrole
  },
  data() {
    return {
      setorgcode: '91330109MABU52HJ0G',
      dialogVisible: false,
      sasslist: [],
      roletree:[],
      options: [{
        value: 1,
        label: '启用'
      }, {
        value: 0,
        label: '禁用'
      }],
      searchname: '',
      total: 0,
      pagesize: 10,
      currentpage: 1,
      data:[]
     
    };
  },
  computed: {

  },
  methods: {
    async getlist() {
      try {
        const res = await getsasslist({ name: this.searchname, });
        console.log(res);
        this.sasslist = res.data.result.records || [];
        this.total = res.data.result.total;
        this.pagesize = res.data.result.pages;
        this.currentpage = res.data.result.current;

      } catch (error) {
        console.error('获取数据失败:', error);
      }
    },
    handleFilter() {
      this.getlist();
    },
    dialogVisible2false(val) {
      this.dialogVisible = val;
      getPermissionData({orgCode:this.setorgcode}).then(res=>{
            console.log(res.data.result);
          this.roletree = res.data.result
           
          })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = val;
      this.getlist();
    },
    addSassuser() {
      this.$router.push({ path: '/sass_management/addsassuser' });
    },
    settingoption(index, row) {
      console.log(row);
      this.$router.push({
        path: '/sass_management/sasssetoption',
        query: { id: row.orgId, name: row.enterpriseName, orgCode: row.creditCode }
      });
    },
    sitadministration(index, row) {
      console.log(row);
      
      this.$router.push({ path: '/sass_management/sasssiteadministration' ,
      query: { id: row.orgId, name: row.enterpriseName, orgCode: row.creditCode,}
      });
    },
    sitrule(index, row) {
      this.dialogVisible = true;
      // console.log(index, row);
      this.setorgcode = row.creditCode;
      console.log(this.setorgcode);
      getPermissionData({orgCode:this.setorgcode}).then(res=>{
            console.log(res.data.result);
          this.roletree = res.data.result
           
          })
      getconfigOrgPermission({
            orgCode:row.creditCode
        }).then(res=>{
            console.log(res)
            if(res.data.success){
                this.data=res.data.result
                // this.$message({
                //     message: '操作成功',
                //     type: 'success'
                // })
            }else{
                this.$message({
                    message: res.data.msg,
                    type: 'error'
                })
            }
        })
      

    },
    handleChangeslelect(a, b) {
      console.log(b);

      console.log(b.accountStatus == 1 ? true : false);
      getisDisable({
        id: b.orgId,
        enable: b.status == 1 ? true : false
      }).then(res => {
        console.log(res);
        if (res.data.success) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getlist();
        } else {
          this.$message({
            message: '操作失败',
            type: 'error'
          });
        }

      })

    }

  },
  mounted() {
    this.getlist();
  }
};
</script>

<style scoped>
.bold-header {
  font-weight: bold;
  color: black;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
}

.re-auth {
  color: #f56c6c;
  cursor: pointer;
  margin-left: 10px;
}

.status-failed {
  color: red;
}

.status-success {
  color: green;
}

.upload-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.upload-box {
  text-align: center;
  width: 48%;
  height: 200px;
}

.uploaded-image {
  margin-top: 10px;
  max-width: 50%;
  height: 100px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
