var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("basic-container", [
        _c(
          "div",
          { staticStyle: { width: "100%", height: "auto" } },
          [
            _c(
              "el-tabs",
              {
                attrs: { "tab-position": _vm.tabPosition },
                on: { "tab-click": _vm.changecompany },
              },
              [
                _vm._l(_vm.sasscompany, function (i) {
                  return [
                    _c(
                      "el-tab-pane",
                      { key: i.orgCode, attrs: { label: i.orgName } },
                      [
                        _c(
                          "el-tabs",
                          { attrs: { "tab-position": _vm.tabPosition } },
                          [
                            _c("el-tab-pane", { attrs: { label: "角色" } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openaddrole(1)
                                        },
                                      },
                                    },
                                    [_vm._v("创建角色")]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title:
                                          _vm.addroletype == 1
                                            ? "创建角色"
                                            : "编辑角色",
                                        visible: _vm.dialogFormVisible,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.dialogFormVisible = $event
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "ruleForm",
                                          refInFor: true,
                                          staticClass: "demo-ruleForm",
                                          attrs: {
                                            model: _vm.form,
                                            rules: _vm.rules,
                                            "label-width": "100px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "角色名称",
                                                prop: "name",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.form.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "角色描述",
                                                prop: "desc",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.form.desc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "desc",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.desc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            { on: { click: _vm.closeaddrole } },
                                            [_vm._v("取 消")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitForm(
                                                    "ruleForm"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("确 定")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.rolelist,
                                            stripe: "",
                                          },
                                        },
                                        _vm._l(_vm.rolelistrules, function (i) {
                                          return _c("el-table-column", {
                                            key: i.prop,
                                            attrs: {
                                              prop: i.prop,
                                              label: i.label,
                                              width: i.width,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return i.slotName ==
                                                      "setting"
                                                      ? _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "space-between",
                                                                width: "50%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "rgb(67, 145, 250)",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editrole(
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("编辑")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "rgb(67, 145, 250)",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changedialogVisible2(
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "配置权限"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleterole(
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("删除")]
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : i.slotName == "setting1"
                                                      ? _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex gap-2",
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#ff672b",
                                                                  },
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleterole(
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("删除")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ])
                                                      : _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex gap-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row[
                                                                    i.prop
                                                                  ]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }),
                                        1
                                      ),
                                      _c("el-pagination", {
                                        attrs: {
                                          "current-page": _vm.current,
                                          "page-sizes": [10, 20, 30, 40],
                                          "page-size": _vm.size,
                                          layout:
                                            "total, sizes, prev, pager, next, jumper",
                                          total: _vm.total,
                                        },
                                        on: {
                                          "size-change": _vm.handleSizeChange,
                                          "current-change":
                                            _vm.handleCurrentChange,
                                        },
                                      }),
                                      _c("Settrole", {
                                        ref: "childRef",
                                        refInFor: true,
                                        attrs: {
                                          dialogVisible: _vm.dialogVisible2,
                                          orgCode:
                                            _vm.companyvaluecode[0].orgCode,
                                          roleId: _vm.roleid,
                                          data: _vm.datarole,
                                          roletree: _vm.roletree,
                                        },
                                        on: {
                                          dialogVisible2false:
                                            _vm.dialogVisible2false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-tab-pane", { attrs: { label: "用户" } }, [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "30%",
                                      "margin-right": "1rem",
                                    },
                                    attrs: { placeholder: "请输入用户手机号" },
                                    model: {
                                      value: _vm.searchuserphone,
                                      callback: function ($$v) {
                                        _vm.searchuserphone = $$v
                                      },
                                      expression: "searchuserphone",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.searchuser },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.adduserdia(1)
                                        },
                                      },
                                    },
                                    [_vm._v("新增用户")]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title:
                                          _vm.addusertype == 1
                                            ? "新增用户"
                                            : "编辑用户",
                                        visible: _vm.dialogFormVisible1,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.dialogFormVisible1 = $event
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "saveover",
                                          refInFor: true,
                                          staticClass: "demo-ruleForm",
                                          attrs: {
                                            model: _vm.saveover,
                                            rules: _vm.saveoverrules,
                                            "label-width": "150px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "用户名字：",
                                                prop: "name",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入用户名字",
                                                },
                                                model: {
                                                  value: _vm.saveover.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saveover,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "saveover.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "用户类型：",
                                                prop: "type",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择用户类型",
                                                  },
                                                  model: {
                                                    value: _vm.saveover.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.saveover,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "saveover.type",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.addusertypelist,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "所属平台：",
                                                prop: "orgCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择所属平台",
                                                  },
                                                  model: {
                                                    value: _vm.saveover.orgCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.saveover,
                                                        "orgCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "saveover.orgCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.getavailablePlatformlist,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "登录手机号：",
                                                prop: "mobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入登录手机号",
                                                },
                                                model: {
                                                  value: _vm.saveover.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saveover,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "saveover.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "登录密码：",
                                                prop: "password",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入登录密码",
                                                },
                                                model: {
                                                  value: _vm.saveover.password,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saveover,
                                                      "password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "saveover.password",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "用户角色(可多选)：",
                                                prop: "roleIds",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    multiple: "",
                                                    placeholder:
                                                      "请选择用户角色",
                                                  },
                                                  model: {
                                                    value: _vm.saveover.roleIds,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.saveover,
                                                        "roleIds",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "saveover.roleIds",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.getuserRoleSelectlist,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: _vm.closeaddroleuser,
                                              },
                                            },
                                            [_vm._v("取 消")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.adduser("saveover")
                                                },
                                              },
                                            },
                                            [_vm._v("确 定")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.userlist,
                                            stripe: "",
                                          },
                                        },
                                        _vm._l(_vm.userlistrules, function (i) {
                                          return _c("el-table-column", {
                                            key: i.prop,
                                            attrs: {
                                              prop: i.prop,
                                              label: i.label,
                                              width: i.width,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                i.slotName === "setting"
                                                  ? {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "rgb(67, 145, 250)",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changediauser(
                                                                          scope.row,
                                                                          2
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "用户管理"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleterolerule(
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("删除")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  : i.slotName === "roleList"
                                                  ? {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.roleList,
                                                          function (i) {
                                                            return _c(
                                                              "span",
                                                              {
                                                                key: i,
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "rgb(67, 145, 250)",
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "2px 5px",
                                                                  "border-radius":
                                                                    "3px",
                                                                  "margin-right":
                                                                    "5px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(i.name)
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    }
                                                  : i.slotName === "aa"
                                                  ? {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return undefined
                                                      },
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }),
                                        1
                                      ),
                                      _c("el-pagination", {
                                        attrs: {
                                          "current-page":
                                            _vm.userlistpages.current,
                                          "page-sizes": [10, 20, 30, 40],
                                          "page-size": _vm.userlistpages.size,
                                          layout:
                                            "total, sizes, prev, pager, next, jumper",
                                          total: _vm.userlistpages.total,
                                        },
                                        on: {
                                          "size-change":
                                            _vm.handleSizeChangeuser,
                                          "current-change":
                                            _vm.handleCurrentChangeuser,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }