var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "boxone" }, [
      _c("h3", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("label", { staticClass: "label" }, [_vm._v("域名地址：")]),
          _c(
            "div",
            { staticClass: "domain-buttons" },
            [
              _c(
                "el-button",
                {
                  class: !_vm.isshowdomain ? "active" : "",
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeyuming(false)
                    },
                  },
                },
                [_vm._v("二级域名")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeyuming(true)
                    },
                  },
                },
                [_vm._v("自有域名")]
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "ruleForm1",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "域名名称：", prop: "domain" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: "请输入域名" },
                    model: {
                      value: _vm.form.domain,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "domain", $$v)
                      },
                      expression: "form.domain",
                    },
                  }),
                ],
                1
              ),
              _vm.isshowdomain
                ? _c(
                    "el-form-item",
                    { attrs: { label: "自有域名：", prop: "icpNum" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: "请输入ICP备案号" },
                        model: {
                          value: _vm.form.icpNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "icpNum", $$v)
                          },
                          expression: "form.icpNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "平台名称：", prop: "platformName" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: "请输入平台名称" },
                    model: {
                      value: _vm.form.platformName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "platformName", $$v)
                      },
                      expression: "form.platformName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首页logo：", prop: "homeLogoId" } },
                [
                  _c("upload", {
                    attrs: { typeCode: "HOMELOGO", type: _vm.type, logoid: 1 },
                    on: { getid: _vm.gethomelogo },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "导航栏logo：", prop: "naviLogoId" } },
                [
                  _c("upload", {
                    attrs: { typeCode: "NAVILOGO", type: _vm.type, logoid: 2 },
                    on: { getid: _vm.getnavlogo },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm1")
                        },
                      },
                    },
                    [_vm._v("保存站点")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }