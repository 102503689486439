<template>
  <div >
    <el-form
      ref="cForm"
      :model="cForm"
      :rules="vRules"
      :label-position="'left'"
      style="padding-right: 20px; padding-left: 20px;"
      label-width="120px"
      size="small"
    >
      <el-alert title="管理员仅能添加一级渠道" :closable="false" type="info" class="mt10" 
        style="background: transparent;
         text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        margin-left: -4%;
        color: gainsboro;
      "/>
      
      <div class="mt10 inner-box">
        <el-form-item label="渠道名称" prop="name">
          <el-input v-model="cForm.name" placeholder="请输入渠道名称" />
        </el-form-item>
        <el-form-item label="登录手机号码" prop="mobile">
          <el-input v-model="cForm.mobile" placeholder="请输入登录手机号码" />
        </el-form-item>
        <el-form-item class="verif-code" label="手机验证码" prop="smsCode">
          <el-input v-model="cForm.smsCode" maxlength="6" placeholder="请输入验证码">
            <template slot="append">
              <span v-show="isSentCode" class="append-text">
                <i>{{ sendTimerCount }}s</i>
                后重新发送
              </span>
              <span v-show="!isSentCode" class="append-text send-btn" @click="toSendCode">发送验证码</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">

          <el-input
            v-model="cForm.password"
            class="qc-log-input-text"
            :type="passwordType"
            auto-complete="off"
            maxlength="20"
            placeholder="请输入登录密码"
          >
            <i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="confirmPassword">
          <el-input
            v-model="cForm.confirmPassword"
            class="qc-log-input-text"
            :type="passwordType"
            auto-complete="off"
            maxlength="20"
            placeholder="请再次输入密码"
          >
            <i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
          </el-input>
        </el-form-item>
        <el-form-item label="渠道等级">
          {{ cForm.level == 1 ? '二级' : '一级' }}
        </el-form-item>
      </div>
      <div class="tc">
        <!-- 关闭弹窗 -->
        <el-button size="medium" @click="closeCard">取消</el-button>
        <!-- 确认新增渠道 -->
        <el-button type="primary" size="medium" :loading="loading" @click="saveCard()">确认</el-button>

      </div>
    </el-form>

    <el-dialog width="30%" :visible.sync="outerVisible" append-to-body center :before-close="outerVisibleChange">
      <div>
        <div class="dialogTitle">恭喜您！</div>
        <div class="dialogContent">
          您的渠道已开通，请通知渠道自行登录账户
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisibleChange">好的</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  getEncryCode
} from '@/utils/jsencrypt.js';
export default {
  name: 'ChannelConfigRate',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.cForm.confirmPassword !== '') {
          this.$refs.cForm.validateField('confirmPassword');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.cForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      outerVisible: false,
      passwordType: 'password',
      cForm: {
        name: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        smsCode: '',
       
      },
      isSending: false,
      isSentCode: false,
      sendTimerCount: 60,
      vRules: {
        name: [{ required: true, trigger: 'blur', message: '请填写渠道名称' }],
        mobile: [{ required: true, trigger: 'blur', message: '请填写登录手机号码' }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePass
        }],
        confirmPassword: [{
          required: true,
          trigger: 'blur',
          validator: validatePass2
        }],
        smsCode: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      }

    };
  },
  watch: {},
  mounted() { },
  methods: {
    /** 发送手机验证码 */
    toSendCode() {
      if (!this.isSending) {
        const user = {
          mobile: this.cForm.mobile,
          scene: 2
        };
        if (user.mobile == null || user.mobile == '') {
          this.$notify.error('请填下手机号码');
          return false;
        }
        this.doPost('bium-portal/controller/fin-ops/sys/user/send_sms_code', user)
          .then((res) => {
            if (res.data.errCode == 1000) {
              this.$notify.success('短信发送成功');
              this.calcSendCount();
            } else {
              this.isSending = false;
            }
          })
          .catch((e) => {
            this.isSending = false;
          });
      } else {
        this.$notify.error('正在发送...');
      }
    },
    /** 发送验证码计时 */
    calcSendCount() {
      if (this.sendTimerCount > 0) {
        this.sendTimerCount--;
        this.isSentCode = true;
        setTimeout(this.calcSendCount, 1000);
      } else {
        this.isSentCode = false;
        this.sendTimerCount = 60;
      }
    },
    outerVisibleChange() {
      this.$emit('refreshTable', 1);
      this.$forceUpdate();
      this.outerVisible = false;
    },
    saveCard() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const form = JSON.parse(JSON.stringify(this.cForm));
          form.password = getEncryCode(this.cForm.password.trim());
          form.confirmPassword = getEncryCode(this.cForm.confirmPassword.trim());
          this.doPost('/bium-portal/controller/fin-ops/channel/save', form).then(res => {
            this.loading = false;
            if (res.data.errCode == 1000) {
              this.outerVisible = true;
            } else {
              this.$notify({
                title: res.data.errMsg,
                type: 'error',
                duration: 2500
              });
            }
          }
          ).catch(e => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    showPassword() {
      this.passwordType === '' ? (this.passwordType = 'password') : (this.passwordType = '');
    },
    closeCard() {
      this.$emit('refreshTable', 1);
      this.$forceUpdate();
      this.outerVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogTitle {
    color: #D9001B;
    font-size: 16px;
    text-align: center;
}

.dialogContent {
    text-align: center;
    padding-top: 20px;
}
</style>
