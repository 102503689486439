<template>
  <div>
    <!-- 上传组件 -->


    <el-upload class="upload-demo" action="" :before-upload="beforeUpload" :on-success="handleSuccess"
      :on-error="handleError" :file-list="fileList" :on-preview="handlePreview" :http-request="getAttachUpload"
      :on-remove="handleRemove" :list-type="listType" accept=".jpg, .jpeg, .png, .gif">
      <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
      <el-button slot="upload" size="small" type="success">上传</el-button>

    </el-upload>

    <!-- 显示文件缩略图 -->
    <!-- <div v-if="fileList.length">
        <h3>文件列表：</h3>
        <el-row :gutter="20">
          <el-col v-for="(file, index) in fileList" :key="index" :span="6">
            <el-card>
              <img :src="file.url || file.thumbUrl" class="img-thumbnail" />
              <div class="file-name">{{ file.name }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div> -->
  </div>
</template>

<script>
import { uploadimg } from '@/api/upload';
import { getImg } from '@/api/setimgs/getimg'
import { doImg } from '@/router/axios'
export default {
  props: {
    typeCode: {
      type: String,
      default: '',
    },
    type: {
      type: Number,

    },
    logoid: {
      // 1--首页
      // 2--导航
    }

  },
  data() {
    return {
      fileList: [], // 存储上传的文件列表

      type1homeLogoId: localStorage.getItem('type1homeLogoId'),
      type1naviLogoId: localStorage.getItem('type1naviLogoId'),
      type2naviLogoId: localStorage.getItem('type2naviLogoId'),
      type2homeLogoId: localStorage.getItem('type2homeLogoId'),
      type3homeLogoId: localStorage.getItem('type3homeLogoId'),
      type3naviLogoId: localStorage.getItem('type3naviLogoId'),
      type4homeLogoId: localStorage.getItem('type4homeLogoId'),
      type4naviLogoId: localStorage.getItem('type4naviLogoId'),
      listType: 'picture', // 显示为图片格式
    };
  },


  methods: {
    // 上传前的钩子函数，限制文件大小并生成缩略图
    beforeUpload(file) {
      const isImage = file.type.startsWith('image/');


      if (!isImage) {
        this.$message.error('只能上传图片文件!');
      }


      // 生成缩略图
      if (isImage) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileList.push({
            name: file.name,
            url: e.target.result, // 缩略图地址
            file: file,
          });
        };
        reader.readAsDataURL(file); // 读取文件作为 base64 数据
      }
      return isImage;
    },

    // 上传成功后的处理
    handleSuccess(response, file, fileList) {
      this.$message.success('上传成功!');
      console.log('上传成功:', response);
      // 可以在这里处理返回的后台数据

    },
    getAttachUpload(param) {
      const file = param.file; // 获取文件对象

      const formData = new FormData();
      formData.append('file', file);
      formData.append('typeCode', this.typeCode);

      // 发送完整文件，不做分片处理
      uploadimg(formData)
        .then(res => {
          console.log('上传成功:', res);
          this.$emit('getid', res.data.result.id);
        })
        .catch(err => {
          console.error('上传失败:', err);
          this.$message.error('上传失败');
        });
    },



    // 上传失败的处理
    handleError(error, file, fileList) {
      this.$message.error('上传失败!');
      console.log('上传失败:', error);
    },

    // 删除文件的处理
    handleRemove(file, fileList) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },

    // 预览文件
    handlePreview(file) {
      console.log('预览文件:', file);
    },
    fetchData() {
      console.log(this.type);

      if (this.type == 1 && this.logoid==1) {
        console.log(1111111, this.logoid);

        getImg({ id: this.type1homeLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {
            this.homelogoimage1 = reader.result
            this.fileList.push({
              name: '商户站首页logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url 属性
            });
          }
        })
      } else if (this.type == 1 && this.logoid==2) {
        console.log(2222222,this.logoid);

        getImg({ id: this.type2naviLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {
            this.homelogoimage1 = reader.result
            this.fileList.push({
              name: '商户站导航栏logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url 属性
            });
          }
        })
      } else if (this.type == 2 && this.logoid==1) {


        getImg({ id: this.type2homeLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '运营站首页logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url 属性
            });
          }
        })
      } else if (this.type == 2 && this.logoid==2) {
        getImg({ id: this.type2naviLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '运营站导航栏logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url 属性
            });
          }
        })
      } else if (this.type == 3 && this.logoid==1) {
        getImg({ id: this.type3homeLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '渠道站首页logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url 属性
            });
          }
        })
      } else if (this.type == 3 && this.logoid==2) {
        getImg({ id: this.type3naviLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '渠道站导航栏logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url
            })
          }
        })

      } else if (this.type == 4 && this.logoid==1) {
        getImg({ id: this.type4homeLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '资方管理首页logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url
            })
          }
        })

      } else if (this.type == 4 && this.logoid==2) {
        getImg({ id: this.type4naviLogoId }).then((res) => {
          let blob = res.data
          let reader = new FileReader()
          reader.readAsDataURL(blob) // 转换为 base64
          reader.onload = () => {

            this.fileList.push({
              name: '资方管理导航栏logo图片',
              url: reader.result, // 将 Base64 图片数据放入 url
            })
          }
        })

      } else {
        console.log(3333333);
      }
    }

  },



mounted() {
  this.fetchData();

},
};
</script>

<style scoped>
.upload-demo {
  margin: 20px;
}

.img-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.file-name {
  text-align: center;
  margin-top: 10px;
}
</style>