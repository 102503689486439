<template>
  <div class="upload" :class="className">
    <el-upload
      accept=".jpeg,.png,.jpg,.bmp,.gif"
      action="#"
      :show-file-list="false"
      :on-error="uploadError"
      :http-request="getAttachUpload"
      method="post"
      :before-upload="beforeImageUpload"
      drag
      style="width: 100%; height: 100%"
    >
      <img v-if="!loadImg" class="def_img" :style="{ height: width }" :src="defaultImg" alt="" />
      <div class="img_box" v-else :style="{ height: height }">
        <img class="load_img" :src="loadImg" alt="" />
       
      </div>
    
    </el-upload>
   <div>
    
   </div>
  </div>
</template>

<script>
import { attachViewById, attachUpload } from '../api/upload'
import * as imageConversion from 'image-conversion'

export default {
  props: {
    defaultImg: String,
    width: String,
    height: String,
    tips: String,
    typeCode: String,
    index: String,
    className: String,
  },
  data() {
    return {
      loadImg: null,
    }
  },
  methods: {
    // 上传前校验
    beforeImageUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpeg', 'png', 'jpg', 'bmp', 'gif', 'JPEG', 'PNG', 'JPG']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 jpeg、png、jpg、bmp、gif 格式')
        return false
      }
    },
    // 图片压缩
    cImage(file) {
      return new Promise((resolve) => {
        imageConversion.compressAccurately(file, 400).then((res) => {
          const files = new window.File([res], file.name, {
            type: file.type,
          })
          resolve(files)
        })
      })
    },
    // 上传错误处理
    uploadError() {
      this.$message.error('上传失败，请检查上传格式或资源大小')
    },
    // 处理上传
    async getAttachUpload(param) {
      console.log('param.file: ', param.file)
      let files = ''
      try {
        files = await this.cImage(param.file)
        console.log('files: ', files)
      } catch (error) {
        files = param.file
      }
      let file = new FormData()
      file.append('file', files)
      file.append('typeCode', this.typeCode)
      console.log('file: ', file)

      attachUpload(file).then((res) => {
        if (res.data.success) {
          console.log('res.data.result: ', res.data.result)

          this.$emit('uploadSuccess', res.data.result, this.index)
          attachViewById({ id: res.data.result.id }).then((res) => {
            let blob = res.data
            let reader = new FileReader()
            reader.readAsDataURL(blob) // 转换为 base64
            reader.onload = () => {
              this.loadImg = reader.result
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.upload {
  margin-top: 8px;
}
.fw_b {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: var(--main-color);
}
/deep/ .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.img_box {
  width: 100%;
}
.load_img {
  width: 100% !important;
  height: 100%;
  object-fit: contain;
}
/deep/ .el-upload {
  border: none;
  background: transparent;
}
/deep/ .el-upload-dragger {
  padding: 0;
  border: none;
  background: transparent;
}
.def_img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
