<template>
    <div ref="business" class="section">
       
      <h2 class="topall">营业执照登记</h2>
      <div style="margin-left: 1.5rem; margin-bottom: 1rem;">
        <div>请上传营业执照照片</div>
      <p style="color: gray;">仅支持JPG/JPREG/PNG格式，不超过2M</p>
      </div>
      <div style="width: 70vw; display: flex; justify-content: space-around;">
        <div style="width: 40%; height: 100%;">
          <Uploadfile
            className="uploadFile"
            typeCode="QYYYZZ"
            :defaultImg="defaultImg"
            tips="点击上传营业执照"
            ref="Upload"
            height="15rem"
            width="15rem"
            @uploadSuccess="uploadSuccess"
          />
          <p style="display: inline-block; text-align: center; margin-left: 10%;">
            请上传营业执照原件或加盖公章的复印件
          </p>
        </div>
        <div style="width: 60%; background-color: rgb(246, 249, 255); padding: 1rem;">
          <formshow
            @verifyFinish="verifyFinish"
            :rulesList="rulesList"
            :rules="rules"
            labelWidth="140px"
            ref="form1"
          ></formshow>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Uploadfile from '../../../components/Uploadfile.vue';
  import formshow from '../../../components/form/formshow.vue';
  
  export default {
    components: { formshow, Uploadfile },
  
    data() {
      return {
        datacon:{},
        uploadList: [],
        Upload: null,
        form: null,
        rules: {
          companyType: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
          companyName: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
          address: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
          person: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
          companyCode: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
          validDate: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
        },
        rulesList: [
          {
            itemType: 'select',
            prop: 'companyType',
            list: [
              { label: '一般企业', value: 1 },
              { label: '个体工商户', value: 2 },
            ],
            placeholder: '识别后自动填入可修改',
            label: '企业类型',
          },
          {
            itemType: 'input',
            prop: 'companyName',
            placeholder: '识别后自动填入可修改',
            label: '营业执照名称',
          },
          {
            itemType: 'input',
            prop: 'address',
            placeholder: '识别后自动填入可修改',
            label: '营业执照地址',
          },
          {
            itemType: 'input',
            prop: 'person',
            placeholder: '识别后自动填入可修改',
            label: '企业法人名称',
          },
          {
            itemType: 'input',
            prop: 'companyCode',
            placeholder: '识别后自动填入可修改',
            label: '统一社会信用代码',
          },
          {
            itemType: 'date',
            dateType: 'daterange',
            prop: 'validDate',
            placeholder: '识别后自动填入可修改',
            label: '营业执照有效期',
            check: 'ischeck',
          },
        ],
        defaultImg: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/Upload.png',
      };
    },
  
    methods: {
      submitform(){
this.$refs.form1.verify()
      },
      verifyFinish(params) {
        console.log(params,11111111111111111111);
        
        let id = localStorage.getItem('id');
  
        if (!this.uploadList.length && params.type !== 2) {
          // console.log(1);
          return this.$message.error('请完成附件上传');
        }
  
        if (params.ischeck && params.validDate) {
          this.$set(params.validDate[1],  '2999/12/31'); // Vue 2 需要用 $set 更新数组中的值
      
        }
  
        this.$emit('nextStep', 
        {...this.datacon},
        // {
        //   step1: true,
        //   legalName: params.person,
        //   name: params.companyName,
        //   type: params.companyType,
        //   creditCode: params.companyCode,
        //   establishmentDate: params.validDate ? params.validDate[0] : '',
        //   address: params.address,
        //   registeredCapital: params.capital,
        //   companyAttachId: this.uploadList[0] ? this.uploadList[0].id : '',
        //   validDate: params.validDate
        //     ? `${params.validDate[1] == '2999/12/31' ? '长期' : params.validDate[1]}`
        //     : '',
        //   step: 'businessLicense',
        //   id,
        // }
        );
      },
  
      uploadSuccess(file) {
        // console.log(file);
  this.datacon=file.ocrResult;
  console.log(this.datacon);
  
        file.ocrResult.ischeck = false;
        if (file && file.ocrResult && file.ocrResult.validDate === '长期') {
          file.ocrResult.validDate = '2999-12-31';
          file.ocrResult.ischeck = true;
        }
        if (file.ocrResult.companyType === '个体工商户') {
          file.ocrResult.companyType = 2;
        } else {
          file.ocrResult.companyType = 1;
        }
        file.ocrResult.validDate = [file.ocrResult.establishDate, file.ocrResult.validDate];
  
        this.$set(this.uploadList, 0, file);
        console.log({
            stepID: file.id,
            ...file.ocrResult,
          },222222222222);
       

        if (this.$refs.form1) {
          this.$set(this.$refs.form1.ruleForm, 'data', {
            stepID: file.id,
            ...file.ocrResult,
          });
        }
        // console.log(this.$refs.form1.ruleForm.data,11111111111);
      },
    },
  };
  </script>
  
  <style>
  /* 右侧各个部分 */
  .section {
    border-bottom: 1px solid #ddd;
    min-height: 300px;
    padding-bottom: 1rem;
  }
  
  .top {
    width: 90%;
    height: 5rem;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: rgb(247, 249, 255);
    margin: 0 auto;
    text-align: left;
    padding-left: 5rem;
    padding-top: 0.5rem;
  }
  
  .a {
    background: linear-gradient(to bottom, #ffffff, rgb(211, 225, 253));
  }
  
  .topall {
    border-left: 0.5rem solid rgb(73, 112, 227);
    padding-left: 1rem;
  }
  
  .uploaded-image {
    width: 90%;
    height: 13rem;
    object-fit: cover;
    border-radius: 1rem;
    margin-top: 1rem;
  }
  
  .uploadFile {
    width: 24rem;
    height: 24rem;
  }
  </style>
  