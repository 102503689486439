var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: "",
            "before-upload": _vm.beforeUpload,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "file-list": _vm.fileList,
            "on-preview": _vm.handlePreview,
            "http-request": _vm.getAttachUpload,
            "on-remove": _vm.handleRemove,
            "list-type": _vm.listType,
            accept: ".jpg, .jpeg, .png, .gif",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "small", type: "primary" },
              slot: "trigger",
            },
            [_vm._v("选择文件")]
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "upload", size: "small", type: "success" },
              slot: "upload",
            },
            [_vm._v("上传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }