<template>
    <div ref="business" class="section">

        <h2 class="topall">法定代表人信息</h2>
        <div style="margin-left: 1.5rem; margin-bottom: 1rem;">
            <div>请上传法定代表人身份证照片</div>
            <p style="color: gray;">仅支持JPG/JPREG/PNG格式，不超过2M</p>
        </div>
        <div style="width: 70vw; display: flex; justify-content: space-around;">
            <div style="width: 40%; height: 100%;">
                <p class="subTitle mt12">头像面</p>
                <Uploadfile className="uploadIdCard" typeCode="FRSFZZM"
                    defaultImg="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idBackEnd.png"
                    tips="点击上传营业执照" ref="upload1" height="144px" @uploadSuccess="uploadSuccess" index="0" />
                <p class="subTitle mt16">国徽面</p>
                <Uploadfile className="uploadIdCard" typeCode="FRSFZFM"
                    defaultImg="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/idFront.png"
                    tips="点击上传营业执照" ref="upload2" height="144px" @uploadSuccess="uploadSuccess" index="1" />
                <p class="subTitle mt16">法人免冠正面照</p>
                <Uploadfile className="uploadFile" typeCode="FRSCSFZ"
                    defaultImg="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/Upload.png"
                    ref="upload3" height="300px" @uploadSuccess="uploadSuccess" index="2" />
                <p style="display: inline-block; text-align: center; margin-left: 10%;">

                </p>
            </div>
            <div style="width: 60%; background-color: rgb(246, 249, 255); padding: 1rem;">
                
              <Stefrom 
               @verifyFinish="verifyFinish"
                :rulesList="rulesList"
                :rules="rules"
                labelWidth="140px"
                ref="form" ></Stefrom>
            </div>
        </div>
    </div>
</template>

<script>
import Uploadfile from '../../../components/Uploadfile.vue';
import formshow from '../../../components/form/formshow.vue';
import {individualTelecom3Factors,attachViewById} from '../../../api/upload'
import Stefrom from '../../../components/form/stefrom.vue';
import { checkIsPhone, checkIsEmail } from '../../../utils/cmmon'
export default {
    components: { formshow, Uploadfile, Stefrom },
    props: {
        detail: {
            type: Object,
            default: () => ({}), // Vue 2 里 `default` 必须返回对象
        },
    },
    data() {
        return {
            uploadList: [],
            Upload: null,
            rules: {
                name: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
                num: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
                staEndTime: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
                nationality: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
                issue: [{ required: true, message: '请上传证件并完善信息确认', trigger: 'blur' }],
                mobile: [
                    {
                        validator: checkIsPhone,
                        required: true,
                        message: '请正确输入手机号',
                        trigger: 'blur',
                    },
                ],
                mail: [
                    {
                        validator: checkIsEmail,
                        required: true,
                        message: '请正确输入邮箱',
                        trigger: 'blur',
                    },
                ],
            },
            rulesList: [
                {
                    itemType: 'input',
                    prop: 'name',
                    placeholder: '识别后自动填入可修改',
                    label: '姓名',
                },
                {
                    itemType: 'input',
                    prop: 'num',
                    placeholder: '识别后自动填入可修改',
                    label: '身份证号',
                },
                {
                    itemType: 'date',
                    dateType: 'daterange',
                    prop: 'staEndTime',
                    placeholder: '识别后自动填入可修改',
                    label: '身份证有效期',
                    check: 'ischeck',
                },
                {
                    itemType: 'input',
                    prop: 'nationality',
                    placeholder: '识别后自动填入可修改',
                    label: '民族',
                },
                {
                    itemType: 'input',
                    prop: 'issue',
                    placeholder: '识别后自动填入可修改',
                    label: '发证机关',
                },
                {
                    itemType: 'input',
                    prop: 'mobile',
                    placeholder: '请输入手机号码',
                    label: '手机号码',
                },
                {
                    itemType: 'input',
                    prop: 'mail',
                    placeholder: '请输入电子邮箱',
                    label: '电子邮箱',
                },
                // { itemType: "button", buttonType: "primary", buttonTitle: "下一步" },
            ],
            // 假设 `filmStore` 是 Vuex 或 Pinia，需要确保正确引入
            upload1: null,
            upload2: null,
            upload3: null,
            uploadList: [],
            form: null,
            ruleForm: {}, // Vue 2 里 `reactive({})` 直接用普通对象
            result: {
                loading: false,
                data: {},
                formData: '',
            },
            datacon:{},

        };
    },

    methods: {
        submitform(type) {
            this.$refs.form.verify(type);
        },
        nextStep() {
            this.$emit("nextStep");
        },
        verifyFinish(params) {
            if (this.uploadList.length !== 3 && params.type !== 2) {
                console.log(2);
                return this.$message.error('请完成附件上传');
            }
            this.result.formData = params;

            if (this.result.formData.ischeck && this.result.formData.staEndTime) {
                this.result.formData.staEndTime[1] = '2999/12/31';
            }

            this.$emit('nextStep', {
                step2: true,
                legalName: this.result.formData.name,
                legalEthnic: this.result.formData.nationality,
                legalGender: this.result.formData.sex === '男' ? 1 : 2,
                legalCard: this.result.formData.num,
                legalIssuing: this.result.formData.issue,
                legalDuration: this.result.formData.staEndTime
                    ? `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]
                    }`
                    : '',
                legalAddress: this.result.formData.address,
                legalMobile: this.result.formData.mobile,
                legalMail: this.result.formData.mail,
                legalDurationStart: this.result.formData.staEndTime ? this.result.formData.staEndTime[0] : '',
                legalDurationEnd: this.result.formData.staEndTime
                    ? `${this.result.formData.staEndTime[1] === '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`
                    : '',
                legalFrontAttachId: this.uploadList[0] ? this.uploadList[0] : '',
                legalBackAttachId: this.uploadList[1] ? this.uploadList[1] : '',
                legalHandAttachId: this.uploadList[2] ? this.uploadList[2] : '',
            });

            // this.getFactors();
        },

        uploadSuccess(file, index) {
            console.log('🚀 ~ file: StepTwo.vue:194 ~ uploadSuccess ~ file:', file)
            if (file && file.ocrResult && file.ocrResult.endDate === '长期') {
                file.ocrResult.endDate = '2999/12/31';
                file.ocrResult.ischeck = true;
            }

            this.uploadList[index] = file.id;
          
            console.log('uploadList', this.uploadList);


            // 合并 OCR 识别的数据
            // this.form.ruleForm.data = { ...this.form.ruleForm.data, ...file.ocrResult };
            
            this.$set(this.$refs.form.ruleForm, 'data', {...this.$refs.form.ruleForm.data,...file.ocrResult });
this.datacon= {...this.$refs.form.ruleForm.data,...file.ocrResult };

            if (this.$refs.form.ruleForm.data.startDate && this.$refs.form.ruleForm.data.endDate) {
                this.$refs.form.ruleForm.data.staEndTime = [
                this.$refs.form.ruleForm.data.startDate,
                this.$refs.form.ruleForm.data.endDate,
                ];
            }
           
        },
        getauthEnterpriseSave(flowId) {
            console.log('🚀 ~ getauthEnterpriseSave ~ flowId:', flowId);

            // 发送请求 (这里的 `authEnterpriseSave` 需要自行定义 API 请求)
            // authEnterpriseSave({
            //   legal: {
            //     name: this.result.formData.name,
            //     ethnic: this.result.formData.nationality,
            //     gender: this.result.formData.sex == '男' ? 1 : 2,
            //     card: this.result.formData.num,
            //     issuing: this.result.formData.issue,
            //     duration: `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] == '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`,
            //     address: this.result.formData.address,
            //     mobile: this.result.formData.mobile,
            //     mail: this.result.formData.mail,
            //   },
            //   attachmentIdList: this.uploadList,
            //   step: "legalInfo",
            //   flowId: flowId,
            //   id: localStorage.getItem('id')
            // }).then(res => {
            //   this.$emit("nextStep", { id: 2 });
            // });

            // 直接触发事件
            this.$emit('nextStep', {
                step2: true,
                legal: {
                    name: this.result.formData.name,
                    ethnic: this.result.formData.nationality,
                    gender: this.result.formData.sex == '男' ? 1 : 2,
                    card: this.result.formData.num,
                    issuing: this.result.formData.issue,
                    duration: `${this.result.formData.staEndTime[0]},${this.result.formData.staEndTime[1] == '2999/12/31' ? '长期' : this.result.formData.staEndTime[1]}`,
                    address: this.result.formData.address,
                    mobile: this.result.formData.mobile,
                    mail: this.result.formData.mail,
                },
                attachmentIdList: this.uploadList,
                step: 'legalInfo',
                flowId: flowId,
                id: localStorage.getItem('id'),
            });
        },
        getFactors() {
            this.result.loading = true;
            individualTelecom3Factors({
                name: this.result.formData.name,
                idNo: this.result.formData.num,
                mobileNo: this.result.formData.mobile,
            })
                .then((res) => {
                    this.result.loading = false;

                    this.getauthEnterpriseSave(res.data.result.flowId);
                    console.log('🚀 ~ getFactors ~ res.data.result.flowId:', res.data.result.flowId);
                })
                .catch(() => {
                    this.result.loading = false;
                });
        },
        getauthEnterpriseSave(flowId) {
            console.log('🚀 ~ getauthEnterpriseSave ~ flowId:', flowId);
            // 这里需要添加实际的 API 请求逻辑
        },
        getbyEnterpriseId() {
            const data = this.detail || {};
            if (Object.keys(data).length > 0) {
                let legalDuration = data.legalDuration;
                this.form.ruleForm.data = {
                    name: data.legalName,
                    nationality: data.legalEthnic,
                    gender: data.legalGender == 1 ? "男" : "女",
                    num: data.legalCard,
                    issue: data.legalIssuing,
                    staEndTime: legalDuration
                        ? [
                            data.legalDuration.split(",")[0],
                            data.legalDuration.split(",")[1] == "长期"
                                ? "2999/12/31"
                                : data.legalDuration.split(",")[1],
                        ]
                        : [],
                    address: data.legalAddress,
                    mobile: data.legalMobile,
                    mail: data.legalMail,
                    ischeck: legalDuration
                        ? data.legalDuration.split(",")[1] == "2999/12/31" ||
                        data.legalDuration.split(",")[1] == "长期"
                        : false,
                };

                if (data.attachmentList && data.attachmentList.length > 2) {
                    const img = data.attachmentList.filter((item) => item.typeCode == "FRSFZZM");
                    const img1 = data.attachmentList.filter((item) => item.typeCode == "FRSFZFM");
                    const img2 = data.attachmentList.filter((item) => item.typeCode == "FRSCSFZ");

                    let list = [img[0], img1[0], img2[0]];
                    this.getattachViewById(list);
                }
            }
        },
        async getattachViewById(list) {
            let newList = [];

            for (let index = 0; index < list.length; index++) {
                newList.push(list[index].id);
                try {
                    const res = await attachViewById({ id: list[index].id });
                    const imageUrl = window.URL.createObjectURL(res.data);

                    if (index === 0) {
                        this.upload1.loadImg = imageUrl;
                    } else if (index === 1) {
                        this.upload2.loadImg = imageUrl;
                    } else {
                        this.upload3.loadImg = imageUrl;
                    }
                } catch (error) {
                    console.error(`获取附件失败 (id: ${list[index].id})`, error);
                }
            }

            this.uploadList = newList;
        },
    },
    watch: {
  detail(val, oldVal) {
    this.getbyEnterpriseId();
  }
}

};
</script>

<style>
/* 右侧各个部分 */
.section {
    border-bottom: 1px solid #ddd;
    min-height: 300px;
    padding-bottom: 1rem;
}

.top {
    width: 90%;
    height: 5rem;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: rgb(247, 249, 255);
    margin: 0 auto;
    text-align: left;
    padding-left: 5rem;
    padding-top: 0.5rem;
}

.a {
    background: linear-gradient(to bottom, #ffffff, rgb(211, 225, 253));
}

.topall {
    border-left: 0.5rem solid rgb(73, 112, 227);
    padding-left: 1rem;
}

.uploaded-image {
    width: 90%;
    height: 13rem;
    object-fit: cover;
    border-radius: 1rem;
    margin-top: 1rem;
}

.uploadFile {
    width: 24rem;
    height: 24rem;
}
</style>