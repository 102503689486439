<template>

    <div class="product-financing-plan">

        <basic-container style="display: flex; justify-content: space-between; width: 100%">
            <!-- 分页 -->
            <avue-crud :option="this.option" :data="this.dataList" :page.sync="page" @size-change="sizeChange"
                @current-change="currentChange" @filter-change="handleFilter"
              
       
                >
                <template #menu="{ row, index, size }">

                    <div style="cursor: pointer; color: rgb(67, 145, 250);" @click="download(row)" v-if="row.status === 1">
                        {{ row.type === 2 || row.type === 4 ? '下载回单' : '下载流水' }}
                    </div>
                </template>

            </avue-crud>

        </basic-container>
    </div>

</template>

<script>
import { doPost, doImg } from '@/router/axios';
import { PAGE } from '@/const/global.js';
import dayjs from 'dayjs';
import { exportExcel } from '@/const/exportExcel';
import axios from 'axios';
export default {
  name: 'TransactionExport',
  data() {
    return {
      dataList: [],
      page: PAGE,
      option: {
        editBtn: false,
        delBtn: false,
        addBtn: false,
        page: 1,
        limit: 10,

        // pageSize: 10,
        // currentPage: 1,
        column: [
          {
            label: '文件名称', prop: 'fileName',

          },
          { label: '创建时间', prop: 'created' },
          {
            label: '文件类型', prop: 'type',
            // 1、交易流水，2、批量回单，3、交易流水，4、批量回单，5、回款账单，6、账户明细，7、融资单结清明细，8、融资申请单，9、还款明细，10、已关闭订单
            formatter(row) {
              if (row.type === 1) {
                return '交易流水';
              } else if (row.type === 2) {
                return '批量回单';
              } else if (row.type === 3) {
                return '交易流水';
              } else if (row.type === 4) {
                return '批量回单';
              } else if (row.type === 5) {
                return '回款账单';
              } else if (row.type === 6) {
                return '账户明细';
              }else if (row.type === 7) {
                return '融资单结清明细';
              }else if (row.type === 8) {
                return '融资申请单';
              }else if (row.type === 9) {
                return '还款明细';
              }else if (row.type === 10) {
                return '已关闭订单';
              }else{
                return '未知';
              }
            }
          },
          {
            label: '状态', prop: 'status',
            formatter(row) {
              if (row.status === 1) {
                // 标签 带边框 背景颜色
                return `<span style="color: green; border: 1px solid green; padding: 2px; border-radius: 4px; background-color: #f0f9eb;">成功</span>`;
              } else if (row.status === 2) {
                return `<span style="color: #f56c6c; border: 1px solid #f56c6c; padding: 2px; border-radius: 4px; background-color: #fef0f0;">失败</span>`;
              } else if (row.status === 3) {
                return `<span style="color: #909399; border: 1px solid #909399; padding: 2px; border-radius: 4px; background-color: #f2f4f7;">初始化</span>`;
              } else if (row.status === 4) {
                return `<span style="color: #409eff; border: 1px solid #409eff; padding: 2px; border-radius: 4px; background-color: #f0f2f5;">进行中</span>`;
              } else {
                return `<span style="color: #909399; border: 1px solid #909399; padding: 2px; border-radius: 4px; background-color: #f2f4f7;">未知</span>`;
              }
            }
          },


        ]
      }
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    async getlist() {
      const api = 'bium-portal/controller/fin-ops/attachRecord/listAttachDownloadRecordPage';
      const params = {
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize,
      };
      const { data } = await doPost(api, params);
      console.log(data, 'data');
      this.dataList = data.result.records;
      console.log(this.dataList, 'this.dataList');
      this.page.total = data.result.total;
      this.page.size = data.result.size;
      this.page.currentPage = data.result.current;
      this.page.pages = data.result.pages;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      console.log(current, 'current');
      
      this.page.currentPage = current;
      console.log(this.page, 'this.page');
      this.getlist();
    },
   
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      
      
      this.page.currentPage = 1
      ; (this.searchItmes = param ? param : {}), this.getList(this.page, param);
      done();
    },

    async download(row) {
      console.log(row, 'row');
      let api = 'bium-portal/controller/fin-ops/attachRecord/getAttachDownloadRecordDto';
      let { data } = await doImg(api, { id: row.id });
      console.log(data, 'data');
      
      // let aoi2='http://192.168.1.7:9501/bium-portal/controller/fin-ops/attachRecord/getAttachDownloadRecordDto';
      // axios.post(aoi2, { id: row.id }, { responseType: 'blob' }).then((res) => {
      //   console.log(res, 'res');
        
      // });
      // 下载zip
      if (row.type === 2 || row.type === 4) {
        let a = document.createElement('a');
        const blob = new Blob([data], { type: 'application/zip' });
        a.href = URL.createObjectURL(blob);
        a.download = `${row.fileName}`;
        document.body.appendChild(a);
        a.click();
      } else {
        console.log('下载流水');
        
        // 下载流水 xslx
    //     this.dataList.forEach((item) => {
    //       item.paymentType = item.paymentType == 'Ping++' ? 'Ping++' : '富友支付';
    //       // 账户类型1:商户监管账户 2:平台监管户 3:资金方监管账户 4:通道费账户 5:渠道账户 11:商户提现账户 12:平台提现账户 13:资金方提现账户 14:通道提现账户 15:渠道提现账户
    //       item.accountName = item.accountName == '1' ? 
    //         '商户监管账户' : item.accountName == '2' ? 
    //           '平台监管户' : item.accountName == '3' ? 
    //             '资金方监管账户' : item.accountName == '4' ? 
    //               '通道费账户' : item.accountName == '5' ? 
    //                 '渠道账户' : item.accountName == '11' ?
    //                   '商户提现账户' : item.accountName == '12' ?
    //                     '平台提现账户' :item.accountName == '13' ? 
    //                       '资金方提现账户' : item.accountName == '14' ? 
    //                         '通道提现账户' : item.accountName == '15' ? 
    //                           '渠道提现账户' : '未知';


    //       /**
    //  * 资金类型
    //  * ACTIVE_REPAY-主动还款
    //  * PAYMENT-支付
    //  * PAYMENT_FEE-支付手续费
    //  * PAYMENT_REFUND-支付退款
    //  * DEPOSIT-充值
    //  * WITHDRAW-提现
    //  * RESERVE_DEPOSIT-冻结入账金额
    //  * RESERVE_BALANCE-冻结余额
    //  * RESERVE_RELEASE-解冻
    //  */

    //       item.sourceType = item.sourceType == 'ACTIVE_REPAY' ? 
    //         '主动还款' : item.sourceType == 'PAYMENT' ?
    //           '支付' : item.sourceType == 'PAYMENT_FEE' ? 
    //             '支付手续费' : item.sourceType == 'PAYMENT_REFUND' ?
    //               '支付退款' : item.sourceType == 'DEPOSIT' ? 
    //                 '充值' : item.sourceType == 'WITHDRAW' ? 
    //                   '提现' : item.sourceType == 'RESERVE_DEPOSIT' ? 
    //                     '冻结入账金额' : item.sourceType == 'RESERVE_BALANCE' ?
    //                       '冻结余额' : item.sourceType == 'RESERVE_RELEASE' ?
    //                         '解冻' : '未知';
    //       /**
    //  * 交易对手账户类型1:商户监管账户 2:平台监管户 3:资金方监管账户 4:通道费账户 5:渠道账户 11:商户提现账户 12:平台提现账户 13:资金方提现账户 14:通道提现账户 15:渠道提现账户
    //  */
    //       item.counterpartyAccountName =item.counterpartyAccountName == '1' ?
    //         '商户监管账户' : item.counterpartyAccountName == '2' ?
    //           '平台监管户' : item.counterpartyAccountName == '3' ?
    //             '资金方监管账户' : item.counterpartyAccountName == '4' ?
    //               '通道费账户' : item.counterpartyAccountName == '5' ?
    //                 '渠道账户' : item.counterpartyAccountName == '11' ?
    //                   '商户提现账户' : item.counterpartyAccountName == '12' ?
    //                     '平台提现账户' : item.counterpartyAccountName == '13' ?
    //                       '资金方提现账户' : item.counterpartyAccountName == '14' ?
    //                         '通道提现账户' : item.counterpartyAccountName == '15' ?
    //                           '渠道提现账户' : '未知';






    //       item.procStatus = item.procStatus == 'Pending' ? 
    //         '待处理' : item.procStatus == 'Processing' ? 
    //           '处理中' : item.procStatus == 'Succeeded' ?
    //             '处理成功' : item.procStatus == 'Failed' ? 
    //               '处理失败' : item.procStatus == 'Cancelled' ?
    //                 '已取消' : '未知';


    //       item.amountType = item.amountType == 'PRINCIPAL' ?
    //         '本金' : item.amountType == 'INTEREST' ? 
    //           '利息' : item.amountType == 'FUNDING_ROUTING_FEE' ?
    //             '资方通道费' : item.amountType == 'OVERDUE_INTEREST' ? 
    //               '逾期利息' : item.amountType == 'COSTS_TOTAL' ? 
    //                 '对客总费用' : item.amountType == 'SERVICE_FEE' ? 
    //                   '服务费' : item.amountType == 'CHANNEL_FEE' ? 
    //                     '渠道费' : item.amountType == 'BALANCE_RELEASE' ? 
    //                       '尾款' : '未知';


    //       item.postedAt = item.postedAt ? dayjs(item.postedAt).format('YYYY-MM-DD HH:mm:ss') : '';
    //       item.createdAt = item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '';
    //     });

    //     const fields = ['id', 'paymentType', 'accountName', 'accountType', 'accountId', 'requestId', 'source', 'amount', 'availableBalance', 'reservedBalance', 'sourceType', 'counterpartyAccountName', 'counterpartyAcctId', 'createdAt', 'postedAt', 'procStatus', 'remark', 'amountType'];

    //     const headers = ['交易流水ID', '账户平台', '交易帐户户名', '账户类型', '交易账号', '平台流水编号', '机构流水编号', '交易金额', '交易后余额', '冻结金额', '资金类型', '交易对手户名', '交易对手账号', '交易发起时间', '交易完成时间', '交易状态', '备注', '交易类型'];

    //     exportExcel(this.dataList, fields, headers, `台账流水-${dayjs(new Date()).format('YYYY-MM-DD')}.xlsx`);

    // 接受excel表格并且下载
    let a = document.createElement('a');
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    a.href = URL.createObjectURL(blob);
    a.download = `${row.fileName}`;
    document.body.appendChild(a);
    a.click();
      }
    }
  }
}

</script>
<style lang="scss" scoped></style>