var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.Loading,
          expression: "Loading",
        },
      ],
      staticClass: "selection-box",
    },
    [
      _c(
        "section",
        { staticClass: "acc-container" },
        [
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("基本信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c(
              "div",
              { staticClass: "card-box" },
              _vm._l(_vm.customerInfo, function (item, index) {
                return _c("div", { key: index, staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v(_vm._s(item.name) + "："),
                  ]),
                  _c("div", [
                    item.name == "审核类型"
                      ? _c("span", [
                          _vm.dataDetails[item.value] == 0
                            ? _c("span", [_vm._v("企业审核")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 1
                            ? _c("span", [_vm._v("店铺审核")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 2
                            ? _c("span", [_vm._v("支用审核")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 3
                            ? _c("span", [_vm._v("提现审核")])
                            : _vm._e(),
                        ])
                      : item.value == "状态"
                      ? _c("span", [
                          _vm.dataDetails[item.value] == 0
                            ? _c("span", [_vm._v("待审核")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 1
                            ? _c("span", [_vm._v("审核通过")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 2
                            ? _c("span", [_vm._v("审核失败")])
                            : _vm._e(),
                        ])
                      : item.value == "productType"
                      ? _c("span", [
                          _vm.dataDetails[item.value] == 0
                            ? _c("span", [_vm._v("1A-极速收款")])
                            : _vm._e(),
                          _vm.dataDetails[item.value] == 1
                            ? _c("span", [_vm._v("2A-极速付款")])
                            : _vm._e(),
                        ])
                      : item.value == "status"
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.statusText[_vm.dataDetails.status]) +
                              "\n            "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.dataDetails[item.value])),
                        ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm.dataDetails.content.auditWithdrawEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("提现信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.Withdraw_info, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.dataDetails.content
                                        .auditWithdrawEntity[item.value] ||
                                        _vm.dataDetails.content
                                          .auditWithdrawEntity[item.value] == 0
                                        ? _vm.dataDetails.content
                                            .auditWithdrawEntity[item.value]
                                        : "--"
                                    ) +
                                    "\n                "
                                ),
                                _vm.dataDetails.content.auditWithdrawEntity[
                                  item.value
                                ] ||
                                _vm.dataDetails.content.auditWithdrawEntity[
                                  item.value
                                ] == 0
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.unit) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.content.auditApplyOrderEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("订单信息")]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb10" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.goPath("/wfTaskInfo/orderDetails", {
                                orderNo:
                                  _vm.dataDetails.content.auditApplyOrderEntity
                                    .orderNo,
                              })
                            },
                          },
                        },
                        [_vm._v("\n          查看订单详情\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.order_info, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              item.value == "creditRatio"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditApplyOrderEntity[item.value]
                                            ? _vm.dataDetails.content
                                                .auditApplyOrderEntity[
                                                item.value
                                              ]
                                            : "--"
                                        ) +
                                        "\n                "
                                    ),
                                    _vm.dataDetails.content
                                      .auditApplyOrderEntity[item.value]
                                      ? _c("span", [_vm._v("%")])
                                      : _vm._e(),
                                  ])
                                : item.value == "orderPaidRatio"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          (
                                            Number(
                                              _vm.dataDetails.content
                                                .auditApplyOrderEntity[
                                                item.value
                                              ]
                                            ) * 100
                                          ).toFixed(0)
                                        ) +
                                        "%\n              "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditApplyOrderEntity[item.value]
                                            ? _vm.dataDetails.content
                                                .auditApplyOrderEntity[
                                                item.value
                                              ]
                                            : "--"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.content.auditCreditAmountEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("额度信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(
                        _vm.AuditCreditAmountEntity,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v(_vm._s(item.name) + ":"),
                              ]),
                              _c("div", [
                                _vm.dataDetails.content.auditCreditAmountEntity[
                                  item.value
                                ]
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.dataDetails.content
                                              .auditCreditAmountEntity[
                                              item.value
                                            ]
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("--")]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.storeInfoDTO
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("店铺信息")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mb10",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openPath("/creditReport/index", {
                                id: _vm.dataDetails.content.auditStoreEntity.id,
                                livePlatform:
                                  _vm.dataDetails.content.auditStoreEntity
                                    .livePlatform,
                                storeCode:
                                  _vm.dataDetails.content.auditStoreEntity
                                    .storeCode,
                                storename:
                                  _vm.dataDetails.content.auditStoreEntity.name,
                                entName:
                                  _vm.dataDetails.content.auditEnterpriseEntity
                                    .name,
                              })
                            },
                          },
                        },
                        [_vm._v("\n          查看风控报告\n        ")]
                      ),
                      _c("div", [
                        _vm.isEdit
                          ? _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSave()
                                      },
                                    },
                                  },
                                  [_vm._v("\n              保存\n            ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCancel()
                                      },
                                    },
                                  },
                                  [_vm._v("\n              取消\n            ")]
                                ),
                              ],
                              1
                            )
                          : _c("i", {
                              staticClass: "el-icon-edit-outline",
                              on: {
                                click: function ($event) {
                                  return _vm.onEdit()
                                },
                              },
                            }),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _vm.isEdit
                      ? _c("div", { staticClass: "card-box" }, [
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺名称:"),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入店铺名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.shopInfo.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.shopInfo, "name", $$v)
                                  },
                                  expression: "shopInfo.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺所属平台:"),
                              ]),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择店铺所属平台",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.shopInfo.livePlatform,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shopInfo,
                                        "livePlatform",
                                        $$v
                                      )
                                    },
                                    expression: "shopInfo.livePlatform",
                                  },
                                },
                                _vm._l(_vm.livePlatformList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺ID:"),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入店铺ID",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.shopInfo.storeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.shopInfo, "storeCode", $$v)
                                  },
                                  expression: "shopInfo.storeCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺账号状态："),
                              ]),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.shopInfo.storeStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.shopInfo, "storeStatus", $$v)
                                    },
                                    expression: "shopInfo.storeStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "controlled",
                                    attrs: {
                                      label: "已管控",
                                      value: "controlled",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "un_controlled",
                                    attrs: {
                                      label: "未管控",
                                      value: "un_controlled",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺账号状态："),
                              ]),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.shopInfo.virtualAcctStatus,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.shopInfo.virtualAcctStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shopInfo,
                                        "virtualAcctStatus",
                                        $$v
                                      )
                                    },
                                    expression: "shopInfo.virtualAcctStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "controlled",
                                    attrs: {
                                      label: "已管控",
                                      value: "controlled",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "un_controlled",
                                    attrs: {
                                      label: "未管控",
                                      value: "un_controlled",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺经营类目:"),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入店铺经营类目",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.operateType,
                                  callback: function ($$v) {
                                    _vm.operateType = $$v
                                  },
                                  expression: "operateType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v("店铺经营开始日期:"),
                              ]),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "请选择店铺经营开始日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.operateTime,
                                  callback: function ($$v) {
                                    _vm.operateTime = $$v
                                  },
                                  expression: "operateTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c(
                          "div",
                          { staticClass: "card-box" },
                          _vm._l(_vm.shopformation, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "card-item" },
                              [
                                _c("div", { staticClass: "item_label" }, [
                                  _vm._v(_vm._s(item.name) + ":"),
                                ]),
                                _c("div", [
                                  item.value == "livePlatform"
                                    ? _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.livePlatformToText[
                                                _vm.dataDetails.storeInfoDTO
                                                  .livePlatform
                                              ]
                                            )
                                          ),
                                        ]),
                                      ])
                                    : item.value == "storeStatusDesc"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.storeStatusDesc) +
                                            "\n              "
                                        ),
                                      ])
                                    : item.value == "virtualAcctStatusDesc"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.virtualAcctStatusDesc) +
                                            "\n              "
                                        ),
                                      ])
                                    : item.value == "operateTime" &&
                                      _vm.dataDetails.storeInfoDTO.extJson
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              JSON.parse(
                                                _vm.dataDetails.storeInfoDTO
                                                  .extJson
                                              ).operateTime
                                            ) +
                                            "\n              "
                                        ),
                                      ])
                                    : item.value == "operateType" &&
                                      _vm.dataDetails.storeInfoDTO.extJson
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              JSON.parse(
                                                _vm.dataDetails.storeInfoDTO
                                                  .extJson
                                              ).operateType
                                            ) +
                                            "\n              "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataDetails.storeInfoDTO[
                                              item.value
                                            ]
                                          )
                                        ),
                                      ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.productType == 0 &&
          _vm.dataDetails.content.auditRatePlanEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("费率信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.direct, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c(
                              "div",
                              [
                                item.value == "firstTraceDays" ||
                                item.value == "multipleTraceDays"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                T - " +
                                          _vm._s(
                                            _vm.dataDetails.content
                                              .auditRatePlanEntity[item.value]
                                          ) +
                                          "天\n              "
                                      ),
                                    ])
                                  : item.value == "channelName"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.dataDetails.content
                                              .auditRatePlanEntity.channelName
                                              ? _vm.dataDetails.content
                                                  .auditRatePlanEntity
                                                  .channelName
                                              : "直客"
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : item.value == "btn"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toConfigStandardPlan(
                                              _vm.dataDetails
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                查看费率\n              "
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditRatePlanEntity[item.value]
                                        )
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.productType == 1 &&
          _vm.dataDetails.content.auditRatePlanEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("费率信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.directsType, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              item.value == "paymentDays"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditRatePlanEntity[item.value]
                                        ) +
                                        "天\n              "
                                    ),
                                  ])
                                : item.value == "customerRate"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          (
                                            Number(
                                              _vm.dataDetails.content
                                                .auditRatePlanEntity
                                                .custRateDetailList[0]
                                                .customerRate
                                            ) * 100
                                          ).toFixed(4)
                                        ) +
                                        "%\n              "
                                    ),
                                  ])
                                : item.value == "channelName"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditRatePlanEntity.channelName
                                            ? _vm.dataDetails.content
                                                .auditRatePlanEntity.channelName
                                            : "直客"
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataDetails.content
                                          .auditRatePlanEntity[item.value]
                                      )
                                    ),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.content.auditApplyEntity
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("支用信息")]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb10" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.goPath("/wfTaskInfo/disbursement", {
                                id: _vm.dataDetails.content.auditApplyEntity
                                  .storeQuotaId,
                              })
                            },
                          },
                        },
                        [_vm._v("\n          查看订单详情\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.Disbursementformation, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              item.value == "riskDiscountRate" ||
                              item.value == "realityDiscountRate" ||
                              item.value == "customerRate"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          (
                                            Number(
                                              _vm.dataDetails.content
                                                .auditApplyEntity[item.value]
                                            ) * 100
                                          ).toFixed(4)
                                        ) +
                                        _vm._s(item.unit) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm.dataDetails.content.auditApplyEntity[
                                    item.value
                                  ]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dataDetails.content
                                            .auditApplyEntity[item.value]
                                        ) +
                                        _vm._s(item.unit) +
                                        "\n              "
                                    ),
                                  ])
                                : _c("span", [_vm._v("--")]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("企业营业信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c(
              "div",
              { staticClass: "card-box" },
              _vm._l(_vm.companyInfo, function (item, index) {
                return _c("div", { key: index, staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v(_vm._s(item.name) + ":"),
                  ]),
                  _c("div", [
                    item.name == "营业执照有效期"
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.dataDetails.content.auditEnterpriseEntity
                                  .establishmentDate
                              ) +
                              "至" +
                              _vm._s(
                                _vm.dataDetails.content.auditEnterpriseEntity
                                  .validDate
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dataDetails.content.auditEnterpriseEntity[
                                item.value
                              ]
                            )
                          ),
                        ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm.dataDetails.type != 3
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("企业法人信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.personInformation, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.dataDetails.content
                                      .auditEnterpriseEntity[item.value]
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.content.auditEnterpriseEntity.authorizationName &&
          _vm.dataDetails.content.auditEnterpriseEntity.authorizationName !=
            null &&
          _vm.dataDetails.type != 3
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("授权人信息")]
                  ),
                  _c("div", { staticClass: "jg-acc" }, [
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      _vm._l(_vm.AuthorizedInformation, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "card-item" },
                          [
                            _c("div", { staticClass: "item_label" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.dataDetails.content
                                      .auditEnterpriseEntity[item.value]
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataDetails.content.auditAttachmentEntityList &&
          _vm.dataDetails.content.auditAttachmentEntityList.length
            ? _c(
                "div",
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "title-divider",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("文件信息")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flexBox" },
                    _vm._l(
                      _vm.dataDetails.content.auditAttachmentEntityList,
                      function (item) {
                        return _c(
                          "div",
                          { staticClass: "flexBox-item" },
                          [
                            _c("imgItem", {
                              attrs: {
                                code: _vm.dataDetails.content
                                  .auditEnterpriseEntity.id,
                                file: item,
                              },
                              on: {
                                onChange: function ($event) {
                                  return _vm.onInit()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c("filedialog", {
                    attrs: {
                      code: _vm.dataDetails.content.auditEnterpriseEntity.id,
                      "file-arr":
                        _vm.dataDetails.content.auditAttachmentEntityList,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }