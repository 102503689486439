import request from '@/router/axios';
export function getsasslist (obj) {
    return request({
      url: '/bium-portal/controller/fin-ops/org/list/page',
      method: 'post',
      data: obj
    })
  }

  // 基础配置查看
  export function getbaseconfig (key) {
    return request({
      url: '/bium-portal/controller/fin-ops/org/detail',
      method: 'get',
      params: key
    })
  }
  export const tempSaveEnterprise = (data) => {
    return service({
      method: 'post',
      url: '/bium-portal/controller/fin-ops/enterprise/tempSaveEnterprise',
      data,
    })
  }