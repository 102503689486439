<template>
  <div>
<!-- {{ ruleForm.data }} -->
    <el-form
      ref="ruleFormRef"
      :model="ruleForm.data"
      label-position="left"
      :rules="rules"
      class="demo-ruleForm"
    >
      <el-form-item v-for="item in rulesList" :label="item.label" :prop="item.prop" :key="item.prop">
        <!-- 日期选择 -->
        <div v-if="item.itemType === 'date'" class="flex">
          <el-date-picker
            v-model="ruleForm.data[item.prop]"
            :type="item.dateType"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 295px; height: 40px"
            :placeholder="item.placeholder"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
          <div v-if="item.check" class="checkbox">
            <el-checkbox v-model="ruleForm.data[item.check]" label="长期" size="large" />
          </div>
        </div>

        <!-- 输入框 -->
        <el-input
          v-else-if="item.itemType === 'input'"
          clearable
          :maxlength="item.maxlength"
          :show-password="item.isPwd"
          v-model.trim="ruleForm.data[item.prop]"
          :placeholder="item.placeholder"
        >
          <!-- 前置icon -->
          <template v-if="item.prefix" #prefix>
            <img :src="item.prefix" alt="" />
          </template>
          <!-- 后置icon -->
          <template v-if="item.suffix" #suffix>
            <img :src="item.suffix" alt="" />
          </template>
        </el-input>

        <!-- 按钮 -->
        <el-button v-else-if="item.itemType === 'button'" :type="item.buttonType" @click="verify">
          {{ item.buttonTitle }}
        </el-button>

        <!-- 选择框 -->
        <el-select v-else-if="item.itemType === 'select'" v-model="ruleForm.data[item.prop]" :placeholder="item.placeholder">
          <el-option v-for="sItem in item.list" :key="sItem.value" :label="sItem.label" :value="sItem.value" />
        </el-select>

        <!-- 插槽 -->
        <slot v-else-if="item.itemType === 'slot'" :name="item.slot"></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    rules: Object,
    rulesList: Array,
  },
  data() {
    return {
      ruleForm: {
        data: {},
      },
    };
  },
  methods: {
    verify(type) {
      if (type === 2) {
        this.$emit("verifyFinish", {
          ...this.ruleForm.data,
          type,
        });
        return;
      }
      if (this.$refs.ruleFormRef) {
        this.$refs.ruleFormRef.validate((valid) => {
          if (valid) {
            this.$emit("verifyFinish", this.ruleForm.data);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-input {
  img {
    width: 20px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-left: 15px;
}

// /deep/ .el-form-item__label {
//   font-family:
//     PingFangSC,
//     PingFang SC;
//   font-weight: 400;
//   font-size: 14px;
//   color: #303133;
// }
</style>
