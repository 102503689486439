var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              "before-open": _vm.handleOpenBefore,
              data: _vm.list,
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "menuLeft",
                fn: function () {
                  return [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      _vm._l(_vm.tabList, function (item) {
                        return _c("el-tab-pane", {
                          key: item.value,
                          attrs: { label: item.label, name: item.value },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "type",
                fn: function (scope) {
                  return [
                    scope.row.type == 0
                      ? _c("span", [_vm._v("企业审核")])
                      : _vm._e(),
                    scope.row.type == 1
                      ? _c("span", [_vm._v("店铺审核")])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c("span", [_vm._v("支用审核")])
                      : _vm._e(),
                    scope.row.type == 3
                      ? _c("span", [_vm._v("提现审核")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "status",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("span", [_vm._v("待审核")])
                      : _vm._e(),
                    scope.row.status == 1
                      ? _c("span", [_vm._v("审核通过")])
                      : _vm._e(),
                    scope.row.status == 2
                      ? _c("span", [_vm._v("审核拒绝")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "currNode",
                fn: function (scope) {
                  return [
                    scope.row.currNode == "ops"
                      ? _c("span", [_vm._v("运营")])
                      : _vm._e(),
                    scope.row.currNode == "risk"
                      ? _c("span", [_vm._v("风控")])
                      : _vm._e(),
                    scope.row.currNode == "financial"
                      ? _c("span", [_vm._v("财务")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "productType",
                fn: function (scope) {
                  return [
                    scope.row.productType == 0
                      ? _c("span", [_vm._v("1A-极速收款")])
                      : _vm._e(),
                    scope.row.productType == 1
                      ? _c("span", [_vm._v("2A-极速付款")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApproval(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openDIalog(
                                  "examinedialog",
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          审批\n        ")]
                        )
                      : _vm._e(),
                    scope.row.isBind
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openDIalog(
                                  "Customerdialog",
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          客户绑定\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openDIalog("Caseisvisible", scope.row)
                          },
                        },
                      },
                      [_vm._v("新增案例")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openDIalog("casedialog", scope.row)
                          },
                        },
                      },
                      [_vm._v("案例记录")]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.Caseisvisible,
            title: "新增案例",
            top: "100px",
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Caseisvisible = $event
            },
          },
        },
        [
          _vm.Caseisvisible
            ? _c("addCase", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.casedialog,
            title: "案例记录",
            top: "100px",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.casedialog = $event
            },
          },
        },
        [
          _vm.casedialog
            ? _c("CaseRecord", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.examinedialog,
            title: "审核操作",
            top: "100px",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.examinedialog = $event
            },
          },
        },
        [
          _vm.examinedialog
            ? _c("examine", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.Customerdialog,
            title: "客户归属绑定",
            top: "100px",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Customerdialog = $event
            },
          },
        },
        [
          _vm.Customerdialog
            ? _c("customer", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.Ratesvisible,
            title: "费率方案",
            top: "100px",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Ratesvisible = $event
            },
          },
        },
        [
          _vm.Ratesvisible
            ? _c("RatePlan", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.creditvisible,
            title: "征信报告",
            top: "100px",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.creditvisible = $event
            },
          },
        },
        [
          _vm.creditvisible
            ? _c("creditReport", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }