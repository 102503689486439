var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
                "refresh-change": _vm.handleRefreshChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      scope.row.isEnterpriseAuth
                        ? _c(
                            "el-select",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.submitStatus($event, scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            },
                            _vm._l(_vm.statusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          )
                        : _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                type:
                                  scope.row.status == 1 ? "success" : "info",
                              },
                            },
                            [
                              scope.row.status == 1
                                ? _c("span", [_vm._v("启用")])
                                : scope.row.status == 0
                                ? _c("span", [_vm._v("停用")])
                                : _vm._e(),
                            ]
                          ),
                    ]
                  },
                },
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      scope.row.isEnterpriseAuth
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.chanIncome(scope.row, scope.index)
                                },
                              },
                            },
                            [_vm._v("\n          详情\n        ")]
                          )
                        : _vm._e(),
                      scope.row.isEnterpriseAuth
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showModal(scope.row, "edit")
                                },
                              },
                            },
                            [_vm._v("\n          渠道费率\n        ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave()
                        },
                      },
                    },
                    [_vm._v("添加新渠道")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "收益流水明细",
            width: "98%",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _vm.chanId
            ? _c("channel-income", { attrs: { "chan-id": _vm.chanId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible2,
            title: "资金流水明细",
            width: "98%",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _vm.accountId
            ? _c("account-flow", { attrs: { "account-id": _vm.accountId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "新增渠道",
            width: "500px",
            top: "100px",
            visible: _vm.isvisible,
            "before-close": _vm.refreshTable,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [
          _vm.isvisible
            ? _c("channelAdd", { on: { refreshTable: _vm.refreshTable } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }