import { render, staticRenderFns } from "./sass_user_management.vue?vue&type=template&id=5206fdfb&scoped=true"
import script from "./sass_user_management.vue?vue&type=script&lang=js"
export * from "./sass_user_management.vue?vue&type=script&lang=js"
import style0 from "./sass_user_management.vue?vue&type=style&index=0&id=5206fdfb&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5206fdfb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5206fdfb')) {
      api.createRecord('5206fdfb', component.options)
    } else {
      api.reload('5206fdfb', component.options)
    }
    module.hot.accept("./sass_user_management.vue?vue&type=template&id=5206fdfb&scoped=true", function () {
      api.rerender('5206fdfb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sass_management/sass_user_management.vue"
export default component.exports