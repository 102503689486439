<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :cell-class-name="handleCellClassName"
        :data="dataList"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <!-- <template slot="menuLeft">
            <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="handleWidthdrawal"
              >提现
            </el-button>
            <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="checkFlow"
              >流水核查
            </el-button>
          </template> -->

        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="detailsChange(scope.row, scope.index)">详情</el-button>
          <!--   <el-button
              type="text"
              @click="Caseisvisible = true"
              >新增案例</el-button
            >
            <el-button
              type="text"
              @click="casedialog = true"
              >查看案例</el-button
            > -->
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="pub_dialog"
      title="账户明细"
      top="50px"
      width="98%"
      @close="handleCloseDialog"
    >
      <account-flow v-if="accountId" :account-id="accountId" :account-name="accountName" @change="handleCloseDialog" />
    </el-dialog>
    <el-dialog
      :visible.sync="isvisible"
      class="dialog-standard channel-config-rate"
      title="新增渠道"
      top="100px"
      width="1000px"
    >
      <channelAdd />
    </el-dialog>
    <el-dialog
      :visible.sync="Caseisvisible"
      class="dialog-standard channel-config-rate"
      title="新增案例"
      top="100px"
      width="550px"
    >
      <el-form
        ref="cForm"
        :label-position="'right'"
        :model="cForm"
        :rules="vRules"
        label-width="120px"
        size="small"
        style="padding-right: 20px;"
      >
        <div class="mt10 inner-box">
          <el-form-item label="案例详情" prop="calue">
            <el-input v-model="cForm.calue" :rows="4" placeholder="请输入案例详情" type="textarea" />
          </el-form-item>
        </div>
        <div class="tc">
          <el-button :loading="loading" size="medium" type="primary" @click="Caseisvisible = false">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="casedialog"
      class="dialog-standard channel-config-rate"
      title="案例记录"
      top="100px"
      width="800px"
    >
      <CaseRecord />
    </el-dialog>
  </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd';
import CaseRecord from './components/CaseRecord';
import { mapGetters } from 'vuex';
import { doGet } from '@/router/axios';

const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 120,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'center',
  addBtn: false,
  column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    label: '企业名称',
    prop: 'enterpriseName',
    width: 280,
    hide: true,
    align: 'left',
    editDisplay: false,
    addDisplay: false,
    search: true,
    searchSpan: 6,
    span: 24
  },
  {
    label: '企业',
    prop: 'enterpriseName'
  },
  {
    label: '企业类型',
    prop: 'enterpriseTypeDesc'
  }, {
    label: '店铺数量',
    prop: 'storeCount'
  }, {
    label: '手机号码',
    prop: 'mobile'
  }, {
    label: '在贷余额',
    prop: 'loanAmount'
  }, {
    label: '认证状态',
    prop: 'enterpriseStatus'
  }, {
    label: '开户状态',
    prop: 'accountStatus'
  }
  ]
};

export default {
  name: 'Client',
  components: {
    channelAdd,
    CaseRecord
  },
  data() {
    return {
      enterpriseStatus: {
        7: '待认证',
        3: '认证失败',
        31: '企业四要素认证失败',
        32: '法人三要素认证失败',
        2: '认证成功'
      },
      accountStatus: {
        1: '可用',
        2: '待签约',
        3: '待开户',
        4: 'Pingpp 待短信验证',
        5: 'Pingpp 待打款验证',
        6: '签约失败',
        7: '开户失败',
        8: '销户',
        9: '处理中',
        10: '等待开场景账户'
      },
      casedialog: false,
      option: tableOption,
      cForm: {
        calue: ''
      },
      vRules: {
        calue: [{
          required: true,
          trigger: 'blur',
          message: '请输入案例详情'
        }]
      },
      Caseisvisible: false,
      searchItmes: {},
      loading: false,
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      isvisible: false,
      dataList: [],
      listLoading: true,
      accountId: '',
      accountName: ''
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync'];
    }
  },
  watch: {},

  created() {
  },
  methods: {
    detailsChange(row) {
      console.log(row.productType,row.enterpriseId, 111111);
      
      if (row.productType == 1) {
        this.$router.push(`/operate/clientDetail2A?id=${row.enterpriseId}`);
      } else {
        this.$router.push(`/operate/clientDetail?id=${row.enterpriseId}`);
      }
    },
    handleSave() {
      this.isvisible = true;
    },
    getList(page, params) {
      params = params || {
        ...this.searchItmes
      };
      if (params.productName == 1) {
        params.productType = 1;
      }
      if (params.productName == 0) {
        params.productType = 0;
      }
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      this.listLoading = true;
      this.doPost('bium-portal/controller/fin-ops/enterprise/merchantPage', params).then(({
        data
      }) => {

        if (data.result.records) {

          this.page.total = data.result.total || 0;
          const enterpriseStatusArr = ['认证中', '认证成功', '认证失败', '待审核', '审核通过', '审核拒绝'];
          if (data.result.records) {
            data.result.records = data.result.records.map(item=>{
              item.customerRate = `${(Number(item.customerRate) * 100).toFixed(2)}%`;
              item.enterpriseStatusName = enterpriseStatusArr[Number(item.enterpriseStatus) - 1];
              item.productType == 1 ? item.productName = '2A-极速付款' : item.productName = '1A-极速收款';
              item.channelId ? item.channelName = item.channelName : item.channelName = '直客';
              item.enterpriseStatus = this.enterpriseStatus[item.enterpriseStatus];
              item.accountStatus = this.accountStatus[item.accountStatus];
              return item;
            });
            this.dataList = data.result.records || [];
          }
          // this.dataList.forEach(item => {
          //   item.customerRate = `${(Number(item.customerRate) * 100).toFixed(2)}%`;
          //   item.enterpriseStatusName = enterpriseStatusArr[Number(item.enterpriseStatus) - 1];
          //   item.productType == 1 ? item.productName = '2A-极速付款' : item.productName = '1A-极速收款';
          //   item.channelId ? item.channelName = item.channelName : item.channelName = '直客';
          //   item.enterpriseStatus = this.enterpriseStatus[item.enterpriseStatus];
          //   item.accountStatus = this.accountStatus[item.accountStatus];
          // });
        }
        this.listLoading = false;
      });
    },

    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    handleCloseDialog() {
      this.dialogVisible = false;
      this.widthdrawDialogVisible = false;
      this.accountId = '';
      this.accountName = '';
      this.handleRefreshChange();
    },
    handleCellClassName({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell';
      }
      return '';
    },

    accountFlow(row, index) {
      // this.$router.push({ path: '/cap/flowAll?accountId=' + row.id });
      this.accountId = row.id;
      this.accountName = row.accName;
      this.dialogVisible = true;
    },
    queryBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange();
      });
    },
    frozenBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw'
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
