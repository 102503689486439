<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :before-open="handleOpenBefore"
        :data="list"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <template #menuLeft>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabList" :key="item.value" :label="item.label" :name="item.value" />
          </el-tabs>
        </template>
        <template slot="type" slot-scope="scope">
          <span v-if="scope.row.type == 0">企业审核</span>
          <span v-if="scope.row.type == 1">店铺审核</span>
          <span v-if="scope.row.type == 2">支用审核</span>
          <span v-if="scope.row.type == 3">提现审核</span>
        </template>
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == 0">待审核</span>
          <span v-if="scope.row.status == 1">审核通过</span>
          <span v-if="scope.row.status == 2">审核拒绝</span>
        </template>
        <template slot="currNode" slot-scope="scope">
          <span v-if="scope.row.currNode == 'ops'">运营</span>
          <span v-if="scope.row.currNode == 'risk'">风控</span>
          <span v-if="scope.row.currNode == 'financial'">财务</span>
        </template>
        <template slot="productType" slot-scope="scope">
          <span v-if="scope.row.productType == 0">1A-极速收款</span>
          <span v-if="scope.row.productType == 1">2A-极速付款</span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="handleApproval(scope.row)">详情</el-button>
          <el-button v-if="scope.row.status == 0" type="text" @click="openDIalog('examinedialog', scope.row)">
            审批
          </el-button>
          <el-button v-if="scope.row.isBind" type="text" @click="openDIalog('Customerdialog', scope.row)">
            客户绑定
          </el-button>
          <!--          <el-button v-if="scope.row.isRatePlan" type="text" @click="openDIalog('Ratesvisible', scope.row)">费率方案-->
          <!--          </el-button>-->
          <el-button type="text" @click="openDIalog('Caseisvisible', scope.row)">新增案例</el-button>
          <el-button type="text" @click="openDIalog('casedialog', scope.row)">案例记录</el-button>
          <!--          <el-button v-if="scope.row.isCreditInfo" type="text" @click="openDIalog('creditvisible', scope.row)">-->
          <!--            征信信息-->
          <!--          </el-button>-->
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Caseisvisible"
      class="dialog-standard channel-config-rate"
      title="新增案例"
      top="100px"
      width="550px"
    >
      <addCase v-if="Caseisvisible" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="casedialog"
      class="dialog-standard channel-config-rate"
      title="案例记录"
      top="100px"
      width="800px"
    >
      <CaseRecord v-if="casedialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="examinedialog"
      class="dialog-standard channel-config-rate"
      title="审核操作"
      top="100px"
      width="800px"
    >
      <examine v-if="examinedialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Customerdialog"
      class="dialog-standard channel-config-rate"
      title="客户归属绑定"
      top="100px"
      width="700px"
    >
      <customer v-if="Customerdialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Ratesvisible"
      class="dialog-standard channel-config-rate"
      title="费率方案"
      top="100px"
      width="600px"
    >
      <RatePlan v-if="Ratesvisible" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="creditvisible"
      class="dialog-standard channel-config-rate"
      title="征信报告"
      top="100px"
      width="1000px"
    >
      <creditReport v-if="creditvisible" :row-form="rowItem" />
    </el-dialog>
  </div>
</template>

<script>
import CaseRecord from '@/views/operate/components/CaseRecord.vue';
import addCase from '@/views/operate/components/addCase';
import examine from '../components/examine';
import customer from '../components/customer';
import RatePlan from '../components/RatePlan';
import creditReport from '../components/creditReport';

export default {
  name: 'TableUser',
  components: {
    CaseRecord,
    addCase,
    examine,
    customer,
    RatePlan,
    creditReport
  },
  data() {
    return {
      activeName: '0',
      tabList: [
        { label: '待审核', value: '0' },
        { label: '已审核', value: '1' }
      ],
      Ratesvisible: false,
      creditvisible: false,
      casedialog: false,
      Caseisvisible: false,
      examinedialog: false,
      Customerdialog: false,
      searchItmes: {},
      option: {
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        searchShow: true,
        header: true,
        menualign: 'left',
        menuWidth: '300',
        editBtn: false,
        delBtn: false,
        align: 'left',
        addBtn: false,
        searchShowBtn: false,
        column: [

          {
            label: '企业名称',
            prop: 'enterpriseName',
            align: 'left',
            width: 300,
            hide: true,
            search: true,
            searchSpan: 4
          },
          {
            label: '提交时间',
            prop: 'created',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            search: true,
            searchSpan: 4,
            span: 12
          },
          // {
          //   label: '产品类型',
          //   prop: 'productType',
          //   type: 'select',
          //   hide: true,
          //   dicData: [
          //     { label: ' 1A-极速收款', value: '0' },
          //     { label: '2A-极速付款', value: '1' }
          //   ],
          //   search: true,
          //   searchSpan: 4,
          //   span: 12
          // },
          {
            label: '企业名称',
            prop: 'enterpriseName'
          },
          // {
          //   label: '产品号',
          //   prop: 'productType'
          // },
          {
            label: '审核订单号',
            prop: 'auditNo'
          },
          {
            label: '审核类型',
            prop: 'type'
          },
          {
            label: '状态',
            prop: 'status'
          },
          {
            label: '提交时间',
            prop: 'created'
          },
          {
            label: '金额',
            prop: 'amount'
          },
          {
            label: '审核角色',
            prop: 'currNode'
          },
          {
            label: '操作时间',
            prop: 'updated'
          }
        ]
      },
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 50, 100],
        isAsc: false // 是否倒序
      },
      list: [],
      loading: false,
      listLoading: true,
      form: {},
      rowItem: {}
    };
  },
  created() {
  },
  methods: {
    handleClick(item, index) {
      console.log(item, index);

      this.activeName = item.index;
      this.getList(this.page, this.searchItmes);
    },
    /** 关闭弹窗 */
    handleClose() {
      this.Caseisvisible = false;
      this.Ratesvisible = false;
      this.creditvisible = false;
      this.casedialog = false;
      this.examinedialog = false;
      this.Customerdialog = false;
      this.getList(this.page, this.searchItmes);
    },
    openDIalog(title, row) {
      console.log(title);

      if (title === 'examinedialog' && row.type == 2 && row.currNode == 'financial') {
        this.doPost('bium-portal/controller/fin-ops/audit/getApplyAuditAmount', {
          id: row.id
        }).then(({ data }) => {
          this.rowItem = row;
          this[title] = true;
          this.rowItem.amount = data.result;
        });
      } else if (title === 'Ratesvisible') {
        this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
          storeId: row.storeId
        }).then(({ data }) => {
          if (data.result == null) {
            this.$message.error('请先绑定客户');
          } else {
            this[title] = true;
            this.rowItem = row;
          }
        });
      } else {
        this[title] = true;
        this.rowItem = row;
      }
    },

    getList(page, params) {
      console.log(this.activeName);

      params = params || {
        ...this.searchItmes
      };
      this.listLoading = true;
      if (params.created) {
        params.startTime = params.created[0] + ' 00:00:00';
        params.endTime = params.created[1] + ' 23:59:59';
      }
      params.isAudit = this.activeName == '0';
      params.type = 3;
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      this.doPost('bium-portal/controller/fin-ops/audit/listAuditPage', params).then(({ data }) => {
        this.list = data.result.records || [];
        this.page.total = data.result.total || 0;
      });
      this.listLoading = false;
    },
    handleApproval(row, index) {
      this.$router.push({ path: '/wfTaskInfo/Taskdetails?id=' + row.id });
    },
    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {
      } else if (type === 'add') {
      }
      show();
    }
  }
};
</script>

<style scoped>
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
</style>
