
export const checkIsPhone = (rule, value, callback) => {
    const reg = /^(?:(?:\+|00)86)?1\d{10}$/
    return reg.test(value)
  }
  // 校验邮箱
  export function checkIsEmail(rule, value, callback) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    return reg.test(value)
  }
  