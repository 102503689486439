var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-financing-plan" },
    [
      _c(
        "basic-container",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            width: "100%",
          },
        },
        [
          _c("avue-crud", {
            attrs: { option: this.option, data: this.dataList, page: _vm.page },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "filter-change": _vm.handleFilter,
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function ({ row, index, size }) {
                  return [
                    row.status === 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "rgb(67, 145, 250)",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.download(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  row.type === 2 || row.type === 4
                                    ? "下载回单"
                                    : "下载流水"
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }