<template>
    <div>
        <basic-container>
            <h1>基础配置</h1>
            <h2>Sass企业名称：{{ name }}</h2>
            <div
                style="width: 50%; height: auto;  border-radius: 1rem; border: 0.1rem solid black; margin-top: 2rem; padding: 2rem;">
                <h2>Sass基础配置</h2>
                <el-form :model="form" label-width="10rem" :rules="rules" ref="form" style="margin-top: 2rem;">

                    <el-form-item label="技术服务费率:" prop="techServiceRate">
                        <el-input v-model="form.techServiceRate" placeholder="请输入服务费率"></el-input>
                    </el-form-item>
                    <el-form-item label="平台服务协议id:" prop="paasAttachId">
                        <el-input v-model="form.paasAttachId" placeholder="请输入平台服务协议id"></el-input>
                    </el-form-item>
                    <el-form-item label="可选资金方案:" prop="fundingIds">

                        <el-select v-model="form.fundingIds" style="width: 100%;" multiple placeholder="请选择资金方案">
                            <el-option v-for="item in fundplanlist" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="margin-top: 3rem;">
                        <el-button type="primary" @click="submitForm('form')">确定</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </basic-container>
    </div>
</template>
<script>
import { getfundplan, getsaveBasicInfo ,getviewBasicInfo} from '@/api/sass/sassmanage'
export default {


    name: 'sassSetoption',
    data() {
        return {
            id: this.$route.query.id,
            name: this.$route.query.name,
            orgCode: this.$route.query.orgCode,
            form: {
                techServiceRate: '', //服务费率
                paasAttachId: '',//平台服务协议
                fundingIds: []//资金方案
            },
            rules: {
                techServiceRate: [
                    { required: true, message: '请输入技术服务费率', trigger: 'blur' },
                ],
                paasAttachId: [
                    { required: true, message: '请输入平台服务协议id', trigger: 'blur' },
                ],
                fundingIds: [
                    { required: true, message: '请选择资金方案', trigger: 'blur' },
                ]
            },
            fundplanlist: []
        }
    },
    created() {
      
    },
    mounted() {
        this.getfundplanlist()
       
            getviewBasicInfo(this.id).then(res => {
            console.log(res);
            if (res.data.success) {
                this.form = res.data.result
                console.log(this.form);

            }
        })
       
    },
    methods: {
        getfundplanlist() {
            getfundplan({ productType: 0, orgCode: this.orgCode }).then(res => {
                console.log(res);
                if (res.data.success) {
                    this.fundplanlist = res.data.result
                    console.log(this.fundplanlist);

                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    getsaveBasicInfo({
                        techServiceRate:this.form.techServiceRate, //服务费率
                        paasAttachId:this.form.paasAttachId,//平台服务协议
                        fundingIds:this.form.fundingIds,//资金方案
                        id:Number(this.id)
                    }).then(res => {
                        console.log(res);
                        if (res.data.success) {
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });


                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            })
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            })
        },
        resetForm(formName) {
            this.form = {
                techServiceRate: '', //服务费率
                paasAttachId: '',//平台服务协议
                fundingIds: []//资金方案
            }
        },
    }
}
</script>
<style></style>