<template>
	<div>
		<div class="login_tit">
			<img :src="this.$store.state.user.domain.homeLogoId "  :alt="alttext">
			<p>{{ sassname }}运营站</p>
		</div>
		<el-form ref="loginForm" class="login-form" status-icon :rules="loginRules" :model="loginForm">
			<el-form-item prop="username">
				<el-input v-model="loginForm.username" size="medium" auto-complete="off" placeholder="请输入手机号"
					maxlength="20" @keyup.enter.native="getCode">
					<i slot="prefix" class="icon-yonghu" />
				</el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input v-model="loginForm.password" :type="passwordType" size="medium" auto-complete="off"
					placeholder="请输入密码" maxlength="20" @keyup.enter.native="getCode">
					<i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
					<i slot="prefix" class="icon-mima" />
				</el-input>
			</el-form-item>
			<!--<el-form-item prop="code">-->
			<!--  <el-row :span="24">-->
			<!--    <el-col :span="16">-->
			<!--      <el-input-->
			<!--        v-model="loginForm.code"-->
			<!--        :maxlength="code.len"-->
			<!--        size="medium"-->
			<!--        auto-complete="off"-->
			<!--        placeholder="请输入验证码"-->
			<!--        @keyup.enter.native="handleLogin"-->
			<!--      >-->
			<!--        <i-->
			<!--          slot="prefix"-->
			<!--          class="icon-yanzhengma"-->
			<!--        />-->
			<!--      </el-input>-->
			<!--    </el-col>-->
			<!--    <el-col :span="8">-->
			<!--      <div class="login-code">-->
			<!--        <span-->
			<!--          v-if="code.type === 'text'"-->
			<!--          class="login-code-img"-->
			<!--          size="medium"-->
			<!--          @click="refreshCode"-->
			<!--        >{{ code.value }}</span>-->
			<!--        <img-->
			<!--          v-else-->
			<!--          :src="code.src"-->
			<!--          class="login-code-img"-->
			<!--          @click="refreshCode"-->
			<!--        >-->
			<!--      </div>-->
			<!--    </el-col>-->
			<!--  </el-row>-->
			<!--</el-form-item>-->
			<el-form-item class="forget-pwd-line">
				<el-checkbox v-model="loginForm.rememberMe">
					记住密码
				</el-checkbox>
			</el-form-item>
			<el-form-item class="signup">
				<el-button :loading="loading" size="medium" class="login-submit" @click.native.prevent="getCode">
					<span v-if="!loading">登 录</span>
					<span v-else>登 录 中...</span>
				</el-button>
			</el-form-item>
			<!-- <el-form-item class="func-box">
				<div class="regedit-item">
					<router-link to="/channel/regedit" class="channel-reg ml10"> 立即注册 </router-link>
				</div>
				<router-link to="/forget-pwd" class="forget-pwd">忘记密码?</router-link>
			</el-form-item> -->

			<div class="footer mt50">
				<p class="title">{{ website.infoTitle }}</p>
				<p class="">©2023 v1.0.0</p>
			</div>
		</el-form>
	</div>
</template>

<script>
import { getImg } from '@/api/setimgs/getimg'
	import {
		getCodeImg,getlogin
	} from '@/api/login';
	import {
		mapGetters
	} from 'vuex';
	import {
		getEncryCode
	} from '@/utils/jsencrypt.js'
	import store from '@/store'
	export default {
		name: 'Userlogin',
		data() {
			return {
				alttext: this.sassname+'供应链平台',
				sassname:this.$store.state.user.domain.platformName,
				img:'',
				loading: false,
				// 验证码开关
				captchaEnabled: false,
				redirect: undefined,
				loginForm: {
					code: '',
					description: true,
					password: '',
					redomStr: '',
					rememberMe: false,
					username: ''
				},
				checked: false,
				code: {
					src: '/code',
					value: '',
					len: 4,
					type: 'image'
				},
				loginRules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				passwordType: 'password',
				isquanxian:this.$store.state.user.isquanxian
			};
		},
		computed: {
			...mapGetters(['tagWel']),
			...mapGetters(['website']),
			...mapGetters(['user_type'])
		},
		watch: {
			$route: {
				handler: function(route) {
					this.redirect = route.query && route.query.redirect;
				},
				immediate: true
			}
		},
		created() {
			// console.log(this.$store.state.user.domain.platformName,1111111111);
		// 	getImg({ id: this.$store.state.user.domain.homeLogoId }).then((res) => {
        //   let blob = res.data
        //   let reader = new FileReader()
        //   reader.readAsDataURL(blob) // 转换为 base64
        //   reader.onload = () => {

        //  this.img= reader.result
        //   }
        // })
			this.captchaEnable = getCaptchaEnable();
			this.redirect = this.$route.query.redirect;
			this.getCookie();
		},
		methods: {
			getCode() {
				const userParams = {
					mobile: this.loginForm.username.trim(),
					password: getEncryCode(this.loginForm.password.trim()),
				};
				console.log(userParams);
				
				getlogin(userParams).then((res) => {
					console.log('res', res);
					if(res.data.success){
						store.commit('user/SET_TOKEN', res.data.result.token)
					localStorage.setItem('userPhone',res.data.result.mobile)
					localStorage.setItem('useName',res.data.result.name)
					console.log(this.isquanxian)
					
						this.$router.push({
							path: '/wel/channelIndex'
						});
					
					}
					
				}).catch(() => {
					this.loading = false;
				}).finally(() => {
					this.loading = false;
				});
			},
			refreshCode() {
				getCodeImg().then(res => {
					this.code.src = 'data:image/gif;base64,' + res.data.data.img;
					this.loginForm.redomStr = res.data.data.uuid;
				});
			},
			showPassword() {
				this.passwordType === '' ?
					(this.passwordType = 'password') :
					(this.passwordType = '');
			}
		}
	};
</script>

<style scoped lang="scss">
	.login-container .login-form {
		i {
			color: #333;
		}
	}

	.func-box {
		margin-top: 15px;
		margin-bottom: 0;

		/deep/ .el-form-item__content {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.forget-pwd {
				color: #3c63f9;
			}

			.channel-reg {
				color: #f68d22;
			}

			.regedit-item {
				flex: 1;
			}
		}
	}

	.signup {
		margin-bottom: 0 !important;
	}

	.forget-pwd-line {
		margin: 0 0 10px !important;
	}
	::v-deep .el-input__inner{
		background: #1c1f230d !important;
		border-radius: 22px;
	}
</style>
