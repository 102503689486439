<template>
  <div class="Role_management">
    <button @click="goaddasaauser">
addsass
        </button>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goaddasaauser(){
            console.log(111111111);
            
            this.$router.push('/sass_management/addsassuser')
        },
  },
};
</script>
<style lang="scss" scoped>
.Role_management {
  width: 100%;
  height: 100%;
  background: #040404;
}
</style>