<template>
  <div class="a">
    {{ step1,step2,step3 }}
    <div class="top" style="margin-top: 20px;">
      <h2 style="color: rgb(73, 112, 227); padding-bottom: 1rem;">请准备以下材料完成企业登记</h2>
      <div style="display: flex;">
        <div style="width: 15rem;">
          01.企业营业执照照片
        </div>
        <div style="width: 15rem;">
          02.法人身份证照片正反面
        </div>
        <div style="width: 15rem;">
          03.经办人身份证照片正反面
        </div>
      </div>
      
    </div>


    <div class="container">
      <!-- 左侧菜单 -->
      <div class="sidebar">
        <el-menu @select="handleMenuClick">
          <el-menu-item index="business">营业执照登记</el-menu-item>
          <el-menu-item index="legal">法人信息登记</el-menu-item>
          <el-menu-item index="operator">经办人信息登记</el-menu-item>
        </el-menu>
      </div>
      <basic-container>
        <!-- 右侧内容 -->
        <div class="content">
          <Stepone ref="step1" 
          
          @nextStep="nextStep"></Stepone>

          <div ref="legal" class="section">
            <Steptwo ref="step2" @nextStep="nextStep"></Steptwo>
          </div>

          <div ref="operator" class="section">
            经办人信息
            <el-switch v-model="isSbu" />
            <Stepthree v-show="isSbu" style="margin-top: 1rem;" ref="step3"  @nextStep="nextStep">
            </Stepthree>

          </div>

        </div>

      </basic-container>

    </div>
 <footer>
          <div style="width: 100%;height: 2rem; text-align: center; margin-top: 5rem; ">
          <el-button type="primary" @click="submit()">立即认证</el-button>
         </div>
        </footer>
  </div>

  

</template>

<script>
import Uploadfile from '../../components/Uploadfile.vue';
import formshow from '../../components/form/formshow.vue';
import Stepone from './components/stepone.vue';
import Stepthree from './components/stepthree.vue';
import Steptwo from './components/steptwo.vue';
import { saveEnterprise, tempSaveEnterprise } from '@/api/sass/sasslist';
export default {
  components: {
    Uploadfile,
    formshow,
    Stepone,
    Steptwo,
    Stepthree
  },
  data() {
    return {
      isSbu: true,
      form: null,
step1: null,
      step2:null,
      step3:null
    }

  },
  methods: {
    handleMenuClick(index) {
      const target = this.$refs[index]; // 获取目标元素
      if (target) {
        target.scrollIntoView({ behavior: "smooth", block: "start" }); // 平滑滚动
      }
    },

    dataURLtoFile(dataUrl, fileName) {
      const res = fetch(dataUrl);
      const blob = res.blob();
      return new File([blob], fileName, { type: blob.type });
    },
    nextStep(step) {
      console.log(1111111111111);
      
      console.log(step);
      
      this.form = { ...this.form, ...step }

      if (this.form.step1 && this.form.step2 && (this.form.step3 || !this.isSbu)) {
        if (this.stepType === 2) {
          let params = {
            ...this.form,
            id: this.$route.query.id,
          }
          tempSaveEnterprise(params).then((res) => {
            // this.$store.state.step1 = this.form.ruleForm.data
            this.$router.go(-1)
          })
        } else {
          let params = {
            ...this.form,
            id: this.id,
          }
          saveEnterprise(params)
            .then((res) => {
              console.log('res', res)
              localStorage.setItem('id', res.data.result)
              this.id = res.data.result
              this.dialog.id = res.data.result

              if ([4001, 4002, 1004].includes(res.data.errCode)) {
                if (res.data.errCode === 4002) {
                  this.dialog.dialogTitle = '对不起，您的法人认证失败了'
                } else {
                  this.dialog.dialogTitle = '对不起，您的企业认证失败了'
                }
                this.dialog.dialogVisible = true
                this.dialog.dialogTitle = res.data.errMsg
                this.dialog.dialogsubTitle = res.data.errMsg
              } else {
                this.dialog.dialogTrue = true
              }
            })
            .catch((err) => {
              console.log('err', err)
            })
        }
      }
    },
    submit(step) {
      console.log(1111);
      
      this.step1 = this.$refs.step1
      this.step2 = this.$refs.step2
      this.step3 = this.$refs.step3
      console.log(this.step3);
      
     
    this.form = {}

    

    this.step1.submitform(step)
    console.log(this.step1.datacon);
    
    this.step2.submitform(step)
    console.log(this.step2.datacon);
    
    if(this.isSbu){
      this.step2.submitform(step)
    }

    if (this.isSbu) {
      this.$refs.step3.submitform(step)
    }
    
  }



  }
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
  margin-top: 20px;
}

/* 左侧菜单栏 */
.sidebar {
  width: 200px;
  border-right: 1px solid #ddd;
  padding: 10px;
  /* position: sticky;  关键：让菜单栏固定 */
  top: 0;
  /* 距离页面顶部 0 */
  height: 100vh;
  /* 让菜单高度占满 */

  overflow-y: auto;
  /* 如果菜单项较多，允许滚动 */
}

/* 右侧内容区 */
.content {
  flex: 1;
  overflow-y: auto;
  /* 右侧区域可滚动 */
  height: 100vh;
  /* 右侧高度同样占满屏幕 */
  padding: 20px;
}

/* 右侧各个部分 */
.section {
  /* width: 100%; */
  /* padding: 20px; */
  border-bottom: 1px solid #ddd;
  min-height: 300px;
}

.top {
  width: 90%;
  height: 5rem;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: rgb(247, 249, 255);
  margin: 0 auto;
  text-align: left;
  padding-left: 5rem;
  padding-top: 0.5rem;
}

.a {
  background: linear-gradient(to bottom, #ffffff, rgb(211, 225, 253));

}

.topall {
  border-left: 0.5rem solid rgb(73, 112, 227);
  padding-left: 1rem;
}

.uploaded-image {
  width: 90%;
  height: 13rem;
  object-fit: cover;
  border-radius: 1rem;
  margin-top: 1rem;
}
</style>
