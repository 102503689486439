
    
    
    <template>
        <div>
        
        
        <el-dialog title="权限配置" :visible.sync="dialogVisible">
            <!-- {{ roletree }} -->
            <el-tree :data="data" show-checkbox  node-key="id" ref="tree" highlight-current 
                            :props="defaultProps">
                          </el-tree>
                        
                         
            <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose1clse">取 消</el-button>
            <el-button type="primary" @click="handleClose1">确 定</el-button>
          </div>
        </el-dialog>
        </div>
        </template>
        <script>
        import {getmenuPermission,getaddPermission,getconfigOrgPermissionAdd,getPermissionData} from '@/api/sass/sassmanage'
        export default {
            props: {
                dialogVisible: {
                    default: true,
                    type: Boolean
                },
                orgCode:{
                    default: '',
                    type: String
                },
                roleId:{
                    default: 0,
                    type: Number
                },
                data:{
                    default: [],
                },
                roletree:{
                    default: [],
                }
               
            },
            data() {
              return {
                data: [],
              defaultProps: {
                children: 'children',
                label: 'cname'
              },
              message:'',
              };
            },
            created() {
            //   this.getmenuPermissionlist()
            },
            mounted() {
            this.changeroletree()
            },
            watch: {
                roletree: {
                    handler(newVal, oldVal) {
                        if (newVal) {
                            this.changeroletree()
                        }
                    },
                    deep: true
                }
            },
            methods: {
               changeroletree(){
            
                
                this.$nextTick(() => {
                    console.log(this.roletree);
                    
          this.$refs.tree.setCheckedKeys(this.roletree);
        });
               
               },
                handleClose1clse(){
                    this.$emit('dialogVisible2false', false)
                },
              handleClose1() {
                console.log(1111111111);
                this.$emit('dialogVisible2false', false)
                console.log(...this.$refs.tree.getCheckedKeys(true),...this.$refs.tree.getHalfCheckedKeys());
                getconfigOrgPermissionAdd({
                    orgCode:this.orgCode,
                    // roleId:this.roleId,
                    permissionIds:[...this.$refs.tree.getCheckedKeys(true),...this.$refs.tree.getHalfCheckedKeys()]||[]
                }).then(res=>{
                    console.log(res);
                    if(res.data.success){
                        this.$message({
                            type: 'success',
                            message: '配置成功!'
                        });
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                    
                })
              },
        getmenuPermissionlist(){
            console.log(1111111111);
            
            getmenuPermission({
                orgCode:this.orgCode
            }).then(res=>{
                console.log(res)
                if(res.data.success){
                    this.data=res.data.result
                }
            })
        },
        
        
        
            }
          };
        </script>
        <style>
        </style>