<template>
  <div>
    <!-- {{rowForm}} -->
    <el-form ref="cForm" :model="cForm" :rules="cRules" :label-position="'right'" style="padding-right: 20px"
      label-width="120px" size="small">
      <div class="mt10 inner-box">
        <el-form-item label="客户归属：">
          <el-select v-model="cForm.channelId" placeholder="请选择" style="width: 500px">
            <el-option v-for="(item, index) in boxList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="billingMethod" v-if="cForm.channelId == null" label="计息方式：">
          <el-select v-model="cForm.billingMethod" placeholder="请选择" style="width: 500px">
            <el-option v-for="item in calculateList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="tc" slot="footer">
      <el-button type="primary" size="medium" @click="submit('cForm')">确认绑定</el-button>
    </div>
  </div>
</template>
<script>
// done 我的任务-待办任务-客户绑定
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CaseRecord',
  components: {},
  props: {
    rowForm: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      cForm: {
        channelId: '',
        billingMethod: '',
      },
      boxList: [],
      calculateList: [
        { value: 'Charge_Per_transaction', label: '单笔' },
        { value: 'Annualized', label: '年化' },
      ],
      cRules: {
        channelId: [{ required: true, trigger: 'change', message: '请选择客户归属' }],
        billingMethod: [{ required: true, trigger: 'change', message: '请选择计息方式' }],
      },
    }
  },
  created() {
    this.getData()
    this.getRatePlan()
  },
  methods: {
    getRatePlan() {
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
        storeId: this.rowForm.storeId,
      }).then(({ data }) => {
        if (data.result) {
          this.cForm = data.result
        }
      })
    },
    getData() {
      this.doPost('bium-portal/controller/fin-ops/fundingPartner/boxList',{orgCOde:this.rowForm.orgCode}).then(({ data }) => {
        if (data.result) {
          if (this.rowForm.productType == 1) {
            this.boxList = []
          } else {
            this.boxList = data.result
          }
          this.boxList.unshift({
            name: '直客',
            id: null,
          })
        }
      })
    },
    submit(formName) {
      console.log(this.rowForm);
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.cForm.channelId && this.cForm.channelId != null) {
            return this.$message.error('请选择客户归属')
          }
          this.doPost('bium-portal/controller/fin-ops/ratePlan/bind', {
            companyId: this.rowForm.enterpriseId,
            storeId: this.rowForm.storeId,
            channelId: this.cForm.channelId,
            billingMethod: !this.cForm.channelId ? this.cForm.billingMethod : null,
          }).then(({ data }) => {
            if (data && data.result) {
              this.$parent.handleClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
      return
    },
  },
})
</script>

<style scoped lang="scss"></style>
