var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "regedit-container", attrs: { id: "registWrapper" } },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "t-title",
          attrs: { "aegis-first-screen-timing": "true" },
        },
        [
          _c("h1", [
            _vm._v(
              "注册" +
                _vm._s(this.$store.state.user.domain.platformName) +
                "运营账号"
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "r-wrap" }, [
        _c("div", { staticClass: "r-content" }, [
          _c(
            "div",
            { staticClass: "regist-form-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "regeditForm",
                  staticClass: "regist-form",
                  attrs: {
                    "status-icon": "",
                    rules: _vm.regeditRules,
                    model: _vm.regeditForm,
                    "label-width": "0",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "qc-log-input-text",
                        attrs: {
                          "auto-complete": "off",
                          maxlength: "30",
                          placeholder: "请输入用户名称",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRegedit.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.regeditForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.regeditForm, "name", $$v)
                          },
                          expression: "regeditForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "loginName" } },
                    [
                      _c("el-input", {
                        staticClass: "qc-log-input-text",
                        attrs: {
                          "auto-complete": "off",
                          placeholder: "请输入手机号",
                          maxlength: "11",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRegedit.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.regeditForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.regeditForm, "mobile", $$v)
                          },
                          expression: "regeditForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "captchaCode" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text none-error-icon",
                          attrs: {
                            maxlength: "4",
                            "auto-complete": "off",
                            placeholder: "请输入图形验证码",
                          },
                          model: {
                            value: _vm.regeditForm.captchaCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "captchaCode", $$v)
                            },
                            expression: "regeditForm.captchaCode",
                          },
                        },
                        [
                          _vm.codeUrl
                            ? _c(
                                "div",
                                {
                                  staticClass: "input-suffix img-vcode",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.codeUrl,
                                      width: "100%",
                                      height: "100%",
                                      alt: "图形验证码",
                                    },
                                    on: { click: _vm.getCode },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "pcodeItem", attrs: { prop: "smsCode" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text none-error-icon",
                          attrs: {
                            maxlength: "6",
                            "auto-complete": "off",
                            placeholder: "手机验证码",
                          },
                          model: {
                            value: _vm.regeditForm.smsCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "smsCode", $$v)
                            },
                            expression: "regeditForm.smsCode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "input-suffix phone-code",
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "1px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.pCode.disabled,
                                    size: "medium",
                                  },
                                  on: { click: _vm.onPhoneCode },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.pCode.text) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "pass" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            type: _vm.passwordType,
                            "auto-complete": "off",
                            maxlength: "20",
                            placeholder: "请输入密码",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.regeditForm.pass,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "pass", $$v)
                            },
                            expression: "regeditForm.pass",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-view el-input__icon",
                            attrs: { slot: "suffix" },
                            on: { click: _vm.showPassword },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "reppass" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            type: _vm.passwordType,
                            "auto-complete": "off",
                            maxlength: "20",
                            placeholder: "请再次输入密码",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.regeditForm.reppass,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "reppass", $$v)
                            },
                            expression: "regeditForm.reppass",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-view el-input__icon",
                            attrs: { slot: "suffix" },
                            on: { click: _vm.showPassword },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-ctrl-label-wrap" }, [
                    _c(
                      "label",
                      { staticClass: "form-ctrl-label" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.checked_protocol,
                            callback: function ($$v) {
                              _vm.checked_protocol = $$v
                            },
                            expression: "checked_protocol",
                          },
                        }),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t我已阅读并同意\n\t\t\t\t\t\t\t\t"
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/document/1220",
                                  tag: "a",
                                  target: "_blank",
                                  append: true,
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t《平台用户注册协议》\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v("\n\t\t\t\t\t\t\t\t、\n\t\t\t\t\t\t\t\t"),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/document/1221",
                                  tag: "a",
                                  target: "_blank",
                                  append: true,
                                },
                              },
                              [_vm._v("《隐私政策》")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.valid_checked_flag,
                            expression: "valid_checked_flag",
                          },
                        ],
                        staticClass: "J-agreement-error",
                      },
                      [_vm._v("请勾选同意协议内容")]
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "signup" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "regedit-submit",
                          attrs: { type: "primary", loading: _vm.loading },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\t立即注册\n\t\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "r-wrap-after mb10",
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.goPage },
        },
        [
          _c("span", { staticStyle: { color: "#409EFF" } }, [
            _vm._v(
              "登录已有" +
                _vm._s(this.$store.state.user.domain.platformName) +
                "账号，去登录 >>"
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "lg-footer" }, [
        _c("div", { staticClass: "copyright" }, [
          _c("p", { staticClass: "tag-line" }, [
            _vm._v(
              "\n\t\t\t\tCopyright © 2023-2023 Hexin Dig. All Rights Reserved.\n\t\t\t\t"
            ),
            _c("span", [
              _vm._v(
                _vm._s(this.$store.state.user.domain.platformName) + " 版权所有"
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "r-header" }, [
      _c("a", { staticClass: "r-logo", attrs: { href: "/public" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }