import XLSX from 'xlsx';
export function exportExcel(dataList, fields, headers = [], fileName = 'Excel.xlsx', sheetName = 'Sheet') {
  let data = new Array(); // 将 const 改为 let
  console.log(data,'看看');
  
  if (!Array.isArray(dataList)) return console.warn('dataList is not an array type');
      
  data = dataList.map((obj) => {
    return fields.map((field) => {
      return obj[field];
    });
  });
    
  if (headers.length > 0) {
    data.splice(0, 0, headers);
  } else {
    data.splice(0, 0, fields);
  }
    
  const ws = XLSX.utils.aoa_to_sheet(data); // 创建工作表
  const wb = XLSX.utils.book_new(); // 创建工作簿
  console.log(ws,wb,'看看');
  
  XLSX.utils.book_append_sheet(wb, ws, sheetName); // 将工作表添加到工作簿中
  XLSX.writeFile(wb, fileName); // 导出文件
}