<template>
  <div class="product-financing-plan">
    <basic-container style="display: flex; justify-content: space-between; width: 100%">
      <avue-crud ref="crud" v-model:search="form" :page.sync="page" :option="option" :data="dataList"
        @date-change="dateChange" 
        @on-load="getList"

         @size-change="sizeChange"
          @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange">
        <template #searchMenu style="text-align: center">
          <!-- <el-button type="primary" @click="handleSearch">搜索</el-button> -->
          <el-button type="primary" @click="handleExportCurrent">导出当前流水</el-button>
          <el-button type="primary" @click="handleBatchDownload">批量下载回单</el-button>
          <el-button type="primary" @click="handleExportRecords">导出记录</el-button>
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="downChange(scope)" v-if="
            scope.row.sourceType != 'ACTIVE_REPAY' &&
            scope.row.sourceType != 'RESERVE_DEPOSIT' &&
            scope.row.sourceType != 'RESERVE_BALANCE' &&
            scope.row.sourceType != 'RESERVE_RELEASE'
          ">
            下载回单
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js';
import { doExportPostExcel, downExcel, doImg, doPost } from '@/router/axios';
import dayjs from 'dayjs';
export default {
  name: 'ProductFinancingPlan',
  props: {},
  data() {
    return {
      form: {},
      isLoading: true,
      searchItmes: {},
      dataList: [],
      downLoading: false,
      option: {
        editBtn: false,
        addBtn: false,
        delBtn: false,
        searchMenuSpan: 24,
        searchLabelWidth: 80,
        searchSpan: 5,
        searchMenuPosition: 'right',
        column: [
        
          {
            label: '交易账户',
            prop: 'accountName',
            search: true,
            type: 'input',
            
            dicData: [],
            placeholder: '请选择所属产品',
            dicData: [],
            searchSpan: 5,
            placeholder: '请输入交易账户',
            width: 180,

          },
          {
            label: '平台账户',
            prop: 'paymentType',
            width: 100,
            align: 'left',
            dicData: [{
              label: 'PING++',
              value: 1
            },
            {
              label: '富友支付',
              value: 2
            }
            ],
          },
          {
            label: '账户类型',
            prop: 'accountType',
            width: 120,
            align: 'left',
            dicData: [{
              label: '商户监管账户',
              value: 1
            },
            {
              label: '平台监管户',
              value: 2
            },
            {
              label: '资金方监管账户',
              value: 3
            },
            {
              label: '通道费账户',
              value: 4
            },
            {
              label: '渠道账户',
              value: 5
            },
            {
              label: '商户提现账户',
              value: 11
            },
            {
              label: '平台提现账户',
              value: 12
            },
            {
              label: '资金方提现账户',
              value: 13
            },
            {
              label: '通道提现账户',
              value: 14
            },
            {
              label: '渠道提现账户',
              value: 15
            },
            ],
          },
          {
            label: '交易账号',
            prop: 'accountId',
            align: 'left',
            search: true,
            searchSpan: 8,
            width: 220,
            placeholder: '请输入交易账号',
            dicData: [],
            searchSpan: 5,
          },
          {
            label: '交易流水号',
            prop: 'requestId',
            align: 'left',
            width: 240,
          },
          {
            label: '银行交易流水号',
            prop: 'source',
            align: 'left',
            width: 180,

          }, {
            label: '交易金额',
            prop: 'amount',
            align: 'left',
            width: 150,
          }, {
            label: '交易后余额',
            prop: 'availableBalance',
            align: 'left',
            width: 150,
          }, {
            label: '冻结金额',
            prop: 'reservedBalance',
            align: 'left',
          },
          {
            label: '交易类型',
            prop: 'sourceType',
            align: 'left',
            dicData: [{
              label: '主动还款',
              value: 'ACTIVE_REPAY'
            },
            {
              label: '支付',
              value: 'PAYMENT'
            },
            {
              label: '支付手续费',
              value: 'PAYMENT_FEE'
            },
            {
              label: '支付退款',
              value: 'PAYMENT_REFUND'
            },
            {
              label: '充值',
              value: 'DEPOSIT'
            },
            {
              label: '提现',
              value: 'WITHDRAW'
            },
            {
              label: '冻结入账金额',
              value: 'RESERVE_DEPOSIT'
            },
            {
              label: '冻结余额',
              value: 'RESERVE_BALANCE'
            },
            {
              label: '解冻',
              value: 'RESERVE_RELEASE'
            },
            ],
          },{
            label: '交易对手账户名称',
            prop: 'counterpartyAccountName',
            align: 'left',
            width: 250,
          },
          {
            label: '交易对手账户类型',
            prop: 'counterpartyAccountType',
            align: 'left',
            width: 220,
            dicData: [{
              label: '商户监管账户',
              value: 1
            },
            {
              label: '平台监管户',
              value: 2
            },
            {
              label: '资金方监管账户',
              value: 3
            },
            {
              label: '通道费账户',
              value: 4
            },
            {
              label: '渠道账户',
              value: 5
            },
            {
              label: '商户提现账户',
              value: 11
            },
            {
              label: '平台提现账户',
              value: 12
            },
            {
              label: '资金方提现账户 ',
              value: 13
            },
            {
              label: '通道提现账户',
              value: 14
            },
            {
              label: '渠道提现账户',
              value: 15
            },
            ],
          },
          {
            label: '交易对手账号',
            prop: 'counterpartyAcctId',
            width: 180,
            align: 'left',
          },
          {
            label: '交易发起时间',
            prop: 'createdAt',
            align: 'left',
            width: 150,
          },
          {
            label: '交易完成时间',
            prop: 'postedAt',
            align: 'left',
            width: 180,
          },
          {
            label: '交易状态',
            prop: 'procStatus',
            align: 'left',
            dicData: [{
              label: '待处理',
              value: 'Pending'
            },
            {
              label: '处理中',
              value: 'Processing'
            },
            {
              label: '处理成功',
              value: 'Succeeded'
            },
            {
              label: '处理失败',
              value: 'Failed'
            },
            {
              label: '已取消',
              value: 'Cancelled'
            }
            ],
          },
          {
            label: '备注',
            prop: 'remark',
            align: 'left',
            width: 200,
          },	

          {
            // 开始日期与结束日期
            // dateBtn: true,
            label: '交易日期',
            prop: 'createdAt',
            search: true,
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            searchRange: true,
            searchSpan: 8,
            width: 160,
           
            startPlaceholder: dayjs().subtract(31, 'day').format('YYYY-MM-DD'), // 开始日期
            endPlaceholder: dayjs().format('YYYY-MM-DD'),
            // searchValue: [this.getLastMonth(), this.getNow()],

          },
        ],
      },
      page: PAGE,
      downLoad: {},
      starttimenow: '',
      endtime31: ''
    };

  },
  watch: {},
  created() {
 
    // this.getCurrentDate();
    // this.getDate31DaysAgo();
  },
  components: {
    //

  },
  mounted() { 
    this.getList();
  },
  methods: {
    // 获取当前日期
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      this.starttimenow = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log(this.starttimenow, 'this.starttimenow');

    },

    // 获取当前日期前31天的日期
    getDate31DaysAgo() {
      const now = new Date();
      now.setDate(now.getDate() - 31);

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
      const day = String(now.getDate()).padStart(2, '0');
      const hours = '00';
      const minutes = '00';
      const seconds = '00';
      this.endtime31 = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log(this.endtime31, 'this.endtime31');

      // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    dateChange(date) {
      console.log(date, 'date`');
    },
    /** 获取列表数据 */
    getList(page, params) {
      // console.log(this.searchItmes.createdAt, '111111111111111111');
      console.log(page, params, this.searchItmes, 'page, params');

      const api = 'bium-portal/controller/fin-ops/va/virAcctTxnPage';

      this.searchItmes = {
        accountName: this.searchItmes.accountName || '',
        amountType: this.searchItmes.amountType || '',
        sourceType: this.searchItmes.sourceType || '',
        accountId: this.searchItmes.accountId|| '',
        //  startDate 当前的日期 endDate往前推31天
        // 现在日期减去31天
        endTime: this.searchItmes.createdAt !== undefined ? this.searchItmes.createdAt[1]+ ' 23:59:59' : dayjs().format('YYYY-MM-DD')+ ' 23:59:59',
        startTime: this.searchItmes.createdAt !== undefined ? this.searchItmes.createdAt[0]+ ' 00:00:00' : dayjs().subtract(31, 'day').format('YYYY-MM-DD')+ ' 00:00:00',


      }
      console.log(this.searchItmes, 'this.searchItmes');
      
      params = this.searchItmes;
      console.log(params, 'params');
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      this.isLoading = true;
      this.downLoad = params;

      // console.log(params, doPost(api, params));

      this.doPost(api, params).then(({ data }) => {
        console.log(data, 'data');
        if (data.result.records) {
          this.dataList = data.result.records || [];
          this.page.total = data.result.total || 0;
          if (this.dataList.length) {
            this.dataList.forEach((item) => {
              if (!item.counterpartyAccountName) {
                item.counterpartyAccountName = '--';
              }
              if (!item.counterpartyAccountType) {
                item.counterpartyAccountType = '--';
              }
            });
          }
        }
        this.isLoading = false;
      });
    },
    downChange(row) {
      const api = 'bium-portal/controller/fin-ops/va/electronicByReceiptId';
      this.listLoading = this.downLoading = true;
      let newForm = this.removeEmptyValues({
        source: row.row.source,
      });
      doImg(api, newForm)
        .then((res) => {
          let a = document.createElement('a');
          const blob = new Blob([res.data], { type: 'application/pdf' });
          a.href = URL.createObjectURL(blob);
          a.download = `${row.row.source}.pdf`;
          document.body.appendChild(a);
          a.click();
          // const blob = new Blob([res.data], { type: "application/pdf" });
          // window.open(window.URL.createObjectURL(blob), "_blank");
        })
        .catch(() => {
          this.listLoading = this.downLoading = false;
        });
    },
    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current;
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      console.log(param, 'param1111111111');
      
      this.page.currentPage = 1
      ; (this.searchItmes = param ? param : {}), this.getList(this.page, param);
      done();
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    },

    handleSearch() {
      this.page.currentPage = 1;
      this.searchItmes = this.form;
      // this.getList(this.page, this.searchItmes);
    },

    // 导出当前流水
    handleExportCurrent() {

      const api = 'bium-portal/controller/fin-ops/va/exportVirAcctTxn'
      const params = {
        ...this.searchItmes
      }
      doPost(api, params).then(({ data }) => {


        if (data.errCode == 1000) {
          this.$message.success('请在导出记录列表进行下载')
        }
      })

    },

    // 批量下载回单
    handleBatchDownload() {
      // Implement batch download receipt logic
      const api = 'bium-portal/controller/fin-ops/va/electronicBatch'
      const params = {
        ...this.searchItmes
      }
      doPost(api, params).then(({ data }) => {


        if (data.errCode == 1000) {
          this.$message.success('请在导出记录列表进行下载')
        }
      })

    },

    handleExportRecords() {
      // 跳转到导出记录列表
      this.$router.push('/operate/transactionExport')
      // Implement export records logic
    },
  },
};
</script>

<style lang="scss" scoped>
.product-financing-plan {
  :deep(.avue-crud) {
    .el-form {
      background: #fff;
      padding: 16px;

      .el-form-item {
        margin-bottom: 0;

        .el-form-item__label {
          color: #333;
        }

        .el-input__inner,
        .el-select,
        .el-date-editor {
          width: 180px;
        }
      }
    }

    .el-table {
      th {
        background-color: #f5f7fa;
        color: #606266;
        padding: 8px;
        height: 40px;
      }

      td {
        padding: 8px;
      }
    }

    .search-menu {
      .el-button {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .operation-column {
    .el-button--text {
      color: #409eff;
      padding: 0;
    }
  }
}
</style>
